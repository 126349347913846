// DocumentsContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from "./AuthContext";

const DocumentsContext = createContext();

const DocumentsProvider = ({ children }) => {
    const [documents, setDocuments] = useState(null);
    const [quota, setQuota] = useState([]); // État pour le quota
    const [updateKey, setUpdateKey] = useState(0); // Nouvelle clé d'état
    const [error, setError] = useState(null)

    const { token } = useAuth();

    useEffect(() => {
        const fetchDocuments  = async () => {
            try {
                const response = await fetch('https://api.uama.fr/v1.0/explorer/index', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if(response.status === 200) {
                    setDocuments(data)
                    setError(null);
                } else {
                    setDocuments([])
                    setError(response.status + ' : Une erreur est survenue lors de la récupération des documents.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Une erreur est survenue lors de la récupération des documents.');
            }
        };

        const fetchQuota = async () => {
            try {
                const response = await fetch('https://api.uama.fr/v1.0/explorer/quota', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setQuota(data);
            } catch (error) {
                console.error('Error fetching quota:', error);
            }
        };

        fetchDocuments();
        fetchQuota();
    }, [token, updateKey]); // Ajoutez updateKey comme une dépendance

    // Fonction pour forcer la mise à jour
    const forceUpdate = () => setUpdateKey((prevKey) => prevKey + 1);

    return (
        <DocumentsContext.Provider value={{ documents, setDocuments, quota, setQuota, error, forceUpdate }}>
            {children}
        </DocumentsContext.Provider>
    );
};

const useDocuments = () => {
    const context = useContext(DocumentsContext);
    if (!context) {
        throw new Error('useDocuments must be used within a DocumentsProvider');
    }
    return context;
};

export { DocumentsProvider, useDocuments };
