// SideBarContext.js
import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [siderOpen, setSiderOpen] = useState(true);
  const [headerOpen, setHeaderOpen] = useState(false);

  const toggleSider = () => {
    setSiderOpen(!siderOpen);
  };
  const toggleHeader = () => {
    setHeaderOpen(!headerOpen);
  };

  return (
    <SidebarContext.Provider value={{ siderOpen, setSiderOpen , toggleSider,setSiderOpen, headerOpen, toggleHeader, setHeaderOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext); 

  return context;
};
