import React from 'react';
import {Row, Col, Divider, Tag} from 'antd';
import { UserOutlined, ProfileOutlined, EnvironmentOutlined, MailOutlined, CalendarOutlined, InfoCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { GiPalmTree, GiCalendar } from "react-icons/gi";
import './ContratInfo.css';
import {useAuth} from "../../../context/AuthContext";

export const ContratInfo = ({ contrat }) => {
    const {user, token} = useAuth();
    const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");

    const separatorStyle = {
        borderTop: `2px solid ${contrat.Couleur}`,
    };

    const iconStyle = {
        color: contrat.Couleur,
    };

    return (
        <div>
            <Row gutter={[16, 16]} justify="start" align="middle">
                <Col>
                    <img src={contrat.Photo} className="enfant-photo" alt={contrat.Enfant['Nom complet']} />
                </Col>
                <Col>
                    <h5 style={{ marginTop: '15px' }}>{contrat.Enfant['Nom complet']}</h5>
                </Col>
            </Row>

            <hr style={separatorStyle} />

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Enfant :</h5>
                        <p><UserOutlined style={iconStyle} /> <strong>Date de naissance :</strong> {contrat.Enfant["Date de naissance"]}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Age :</strong> {contrat.Enfant.Age}</p>
                        <p><ProfileOutlined style={iconStyle} /> <strong>Parent :</strong> {contrat.Enfant.Parent}</p>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Parent :</h5>
                        <p><MailOutlined style={iconStyle} /> <strong>Email :</strong> {contrat.Enfant.Email || 'Non renseigné'}</p>
                        <p><EnvironmentOutlined style={iconStyle} /> <strong>Adresse :</strong> {contrat.Enfant.Adresse}</p>
                        { isAssistantMaternel  && (
                            <p>
                                <ProfileOutlined style={iconStyle}/>{" "}
                                <strong>Statut :</strong>{" "}
                                <Tag color={contrat.Enfant["Statut compte du parent"] !== 'Actif' ? 'red' : 'green'}>{contrat.Enfant["Statut compte du parent"]}</Tag>
                            </p>
                        )}
                        <p><PhoneOutlined style={iconStyle}/> <strong>Téléphone :</strong> {contrat.Enfant.Téléphone}
                        </p>
                    </div>
                </Col>
            </Row>

            <hr style={separatorStyle} />

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Assistante Maternelle :</h5>
                        <p><ProfileOutlined style={iconStyle}/> <strong>Nom
                            :</strong> {contrat["Assistante maternelle"]['Nom complet']}</p>
                        <p><MailOutlined style={iconStyle}/> <strong>Email
                            :</strong> {contrat["Assistante maternelle"].Email}</p>
                        <p><EnvironmentOutlined style={iconStyle}/> <strong>Adresse
                            :</strong> {contrat["Assistante maternelle"].Adresse}</p>
                        <p><PhoneOutlined style={iconStyle}/> <strong>Téléphone
                            :</strong> {contrat["Assistante maternelle"].Téléphone}</p>
                        <p>
                            <GiCalendar style={iconStyle}/>
                            {" "}
                            <strong>Jours travaillés :</strong>{" "}
                            {contrat["Assistante maternelle"]["Jours travaillés"].length > 0
                                ? contrat["Assistante maternelle"]["Jours travaillés"].join(', ')
                                : 'Non renseignés'}
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Détails du Contrat :</h5>
                        <p><InfoCircleOutlined style={iconStyle}/> <strong>Statut :</strong> {contrat.Statut}</p>
                        <p><CalendarOutlined style={iconStyle}/> <strong>Date de création
                            :</strong> {contrat["Date de création"]}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Date de début :</strong> {contrat["Date de début"]}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Date de fin :</strong> {contrat["Date de fin"]}</p>
                    </div>
                </Col>
            </Row>

            <hr style={separatorStyle} />

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24}>
                    <div className="enfant-details">
                        <h5>Absences prévues au contrat :</h5>
                        {contrat["Absences prévues au contrat"].length > 0 ? (
                            <ul className='listeAbsences'>
                                {contrat["Absences prévues au contrat"].map((absence, index) => (
                                    <li key={index}><p><GiPalmTree style={iconStyle} /> {absence}</p></li>
                                ))}
                            </ul>
                        ) : (
                            <p><GiPalmTree style={iconStyle} /> Aucune absence prévue</p>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
