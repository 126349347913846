import React, { useState, useEffect } from "react";
import {
    Tabs,
    Form,
    Input,
    Button,
    Col,
    Row,
    DatePicker,
    Divider,
    message,
    Card,
    Typography,
    Space,
    Checkbox
} from 'antd';
import { PlusOutlined, CalendarOutlined, EditOutlined, BgColorsOutlined } from '@ant-design/icons';
import { ColorPicker } from 'antd'; // Assurez-vous que votre bibliothèque de ColorPicker est importée
import './ContratEdit.css';
import dayjs from 'dayjs';
import { useAuth } from "../../../context/AuthContext.js";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const ContratEdit = ({ contrat, onClose, refreshContracts }) => {
    const [form] = Form.useForm();
    const [conges, setConges] = useState([]);
    const { token } = useAuth();
    const [dateFin, setDateFin] = useState(null);
    const [selectedColor, setSelectedColor] = useState(contrat?.Couleur || "#1890ff");

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    useEffect(() => {
        if (contrat && contrat["Absences prévues au contrat"]) {
            const absences = contrat["Absences prévues au contrat"];
            const newConges = absences.map(absence => {
                const dates = absence.split(" au ");
                const dateDebut = dayjs(dates[0], 'DD/MM/YYYY');
                const dateFin = dayjs(dates[1], 'DD/MM/YYYY');
                return { dateDebut, dateFin };
            });
            setConges(newConges);
        }
    }, [contrat]);

    const onFinish = async (values) => {
        try {
            if (values.couleur) {
                // Conversion de la couleur en format hexadécimal
                const hexColor = values.couleur.toHexString();
                values.couleur = hexColor;
            }

            const response = await fetch(`https://api.uama.fr/v1.0/contrat/assistante_maternelle/${contrat.UUID}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nomEnfant: values.nomEnfant,
                    prenomEnfant: values.prenomEnfant,
                    naissanceEnfant: values.naissanceEnfant,
                    numeroTelephoneParent : values.numeroTelephoneParent,
                    couleur: values.couleur,
                    conges: conges.map(conge => ({
                        dateDebut: conge.dateDebut.format('YYYY-MM-DD'),
                        dateFin: conge.dateFin.format('YYYY-MM-DD')
                    }))
                })
            });

            if (response.ok) {
                message.success('Contrat mis à jour avec succès.');
                if (onClose) onClose();
                if (refreshContracts) refreshContracts();
            } else {
                const errorData = await response.json();
                message.error(errorData.message);
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du contrat :', error);
        }
    };

    const endPrematuredContrac = async (dateFin) => {
        try {
            const response = await fetch(`https://api.uama.fr/v1.0/contrat/assistante_maternelle/${contrat.UUID}/terminate`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    dateFin: dateFin.format('YYYY-MM-DD')
                })
            });

            if (response.ok) {
                message.success('Fin prématurée enregistrée.');
                if (onClose) onClose();
                if (refreshContracts) refreshContracts();
            } else {
                const errorData = await response.json();
                message.error(errorData.message);
            }
        } catch (error) {
            console.error('Erreur lors de la fin prématurée :', error);
        }
    };

    const handleAddConge = () => {
        setConges([...conges, { dateDebut: null, dateFin: null }]);
    };

    const handleRemoveConge = (index) => {
        const updatedConges = [...conges];
        updatedConges.splice(index, 1);
        setConges(updatedConges);
    };

    const handleCongeChange = (index, field, value) => {
        const updatedConges = [...conges];
        updatedConges[index][field] = value;
        setConges(updatedConges);
    };

    return (
        <Card className="contrat-edit-card" bordered={false} style={{ borderRadius: '12px', boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)' }}>
            <Tabs defaultActiveKey="1" centered>
                <TabPane tab={<span><EditOutlined /> Édition</span>} key="1">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            statutContrat: contrat.Statut,
                            nomEnfant: contrat.Enfant.Nom,
                            prenomEnfant: contrat.Enfant.Prenom,
                            naissanceEnfant: dayjs(contrat.Enfant["Date de naissance"], 'DD-MM-YYYY'),
                            numeroTelephoneParent: contrat.Enfant.Téléphone,
                        }}
                    >
                        <Title level={4}>Modifier le contrat</Title>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="nomEnfant"
                                    label="Nom de l'enfant"
                                    rules={[{ required: true, message: "Veuillez saisir le nom de l'enfant" }]}
                                >
                                    <Input placeholder="Nom" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="prenomEnfant"
                                    label="Prénom de l'enfant"
                                    rules={[{ required: true, message: "Veuillez saisir le prénom de l'enfant" }]}
                                >
                                    <Input placeholder="Prénom" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="naissanceEnfant"
                                    label="Date de naissance de l'enfant"
                                    rules={[{ required: true, message: "Veuillez sélectionner la date de naissance de l'enfant" }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format={dateFormats}
                                        placeholder="Date de naissance"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="numeroTelephoneParent"
                                    label="Numéro de téléphone du parent"
                                    rules={[{ required: true, message: "Veuillez saisir le numéro de téléphone du parent" }]}
                                >
                                    <Input placeholder="Téléphone" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Couleur" name="couleur">
                            <ColorPicker defaultValue={contrat.Couleur}/>
                        </Form.Item>
                        <Divider />
                        <Text>Absences prévues</Text>
                        {conges.map((conge, index) => (
                            <Row key={index} gutter={16} style={{ marginBottom: '8px' }}>
                                <Col span={10}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        onChange={(date) => handleCongeChange(index, 'dateDebut', date)}
                                        value={conge.dateDebut}
                                        placeholder="Début"
                                    />
                                </Col>
                                <Col span={10}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        onChange={(date) => handleCongeChange(index, 'dateFin', date)}
                                        value={conge.dateFin}
                                        placeholder="Fin"
                                    />
                                </Col>
                                <Col span={4}>
                                    <Button danger onClick={() => handleRemoveConge(index)}>Supprimer</Button>
                                </Col>
                            </Row>
                        ))}
                        <Button type="dashed" onClick={handleAddConge} block icon={<PlusOutlined />}>
                            Ajouter une absence
                        </Button>
                        <Divider />
                        <Button type="primary" htmlType="submit" block>
                            Sauvegarder
                        </Button>
                    </Form>
                </TabPane>
                <TabPane tab={<span><CalendarOutlined /> Fin prématurée</span>} key="2">
                    <Form layout="vertical" onFinish={() => endPrematuredContrac(dateFin)}>
                        <Title level={4}>Fin prématurée</Title>
                        <Form.Item
                            label="Date de fin prématurée"
                            rules={[{ required: true, message: "Veuillez sélectionner une date" }]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                onChange={(date) => setDateFin(date)}
                                placeholder="Sélectionnez une date"
                                format={dateFormats}
                            />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" block disabled={!dateFin}>
                            Valider
                        </Button>
                    </Form>
                </TabPane>
                <TabPane tab={<span><EditOutlined /> Consentement Parent</span>} key="3">
                    <Form
                        layout="vertical"
                        initialValues={{
                            consentGiven: false,
                            email: contrat.Enfant?.Email || '',
                        }}
                        onFinish={async (values) => {
                            try {
                                const response = await fetch(`https://api.uama.fr/v1.0/contrat/parent/${contrat.Enfant['UUID Parent']}/consent`, {
                                    method: 'PUT',
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        consentGiven: values.consentGiven,
                                        email: values.email,
                                    }),
                                });

                                const data = await response.json();

                                if (response.ok) {
                                    message.success(data.message);
                                    if (refreshContracts) refreshContracts();
                                    if (onClose) onClose();
                                } else {
                                    message.error(data.message);
                                }
                            } catch (error) {
                                console.error('Erreur lors de la mise à jour du consentement :', error);
                                message.error("Une erreur est survenue. Veuillez réessayer.");
                            }
                        }}
                    >
                        <Title level={4}>Consentement du Parent</Title>

                        {/* Case à cocher pour le consentement */}
                        <Form.Item
                            name="consentGiven"
                            valuePropName="checked"
                        >
                            <Checkbox>Le parent accepte d'utiliser la plateforme</Checkbox>
                        </Form.Item>

                        {/* Champ pour l'email du parent */}
                        <Form.Item
                            name="email"
                            label="Email du parent"
                            dependencies={['consentGiven']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    required: getFieldValue('consentGiven'),
                                    message: "L'email est obligatoire lorsque le parent accepte d'utiliser la plateforme.",
                                }),
                                { type: 'email', message: "Veuillez entrer une adresse email valide." },
                            ]}
                        >
                            <Input placeholder="Adresse email du parent" />
                        </Form.Item>

                        {/* Bouton de sauvegarde */}
                        <Button type="primary" htmlType="submit" block>
                            Sauvegarder
                        </Button>
                    </Form>
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default ContratEdit;
