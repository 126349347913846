import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import LoginPageForm from "../../../components/Auth/LoginPageForm/LoginPageForm";
import ForgotPasswordEmail from "../../../components/Auth/ForgotPassword/ForgotPasswordEmailForm/ForgotPasswordEmailForm";
import "./LoginPage.css";
import logo from "../../../assets/logo.png";
import {Divider} from "antd";
import useAddClassOnPath from "../../../hooks/useAddClassOnPath";
import {ROUTES} from "../../../Routes/Routes";
import {Link} from "react-router-dom"; // Assure-toi que le logo est ajouté dans le projet

const LoginPage = () => {
  const [displayForgot, setDisplayForgot] = useState(false);

  useAddClassOnPath("bodyContent", "file-explorer-active", ROUTES.LOGIN);

  const handleDisplayForgot = () => {
    setDisplayForgot(!displayForgot);
  };

  return (
      <div className="login-container">
        <div className="login-wrapper">
          <motion.div className="login-header">
            <img src={logo} alt="Logo UAMA" className="login-logo"/>
            <h1>UAMA</h1>
            <p className="login-fullname" style={{fontSize: "0.9rem", color: "#888"}}>
              Univers des Assistantes Maternelles Agrées
            </p>
            <p className="login-description">
              Simplifiez la gestion quotidienne des tâches administratives pour les assistantes maternelles et les
              parents avec UAMA
            </p>

            <Link to={ROUTES.TERMS}>Conditions générales d'utilisation</Link>
          </motion.div>

          <Divider/>

          <AnimatePresence mode="wait">
            {displayForgot ? (
                <motion.div
                    key="passwordReset"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="passwordReset"
                >
                  <ForgotPasswordEmail handleDisplayForgot={handleDisplayForgot} />
                </motion.div>
            ) : (
                <motion.div
                    key="loginForm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="login"
                >
                  <LoginPageForm handleDisplayForgot={handleDisplayForgot} />
                </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
  );
};

export default LoginPage;
