import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Spin, Card, Row, Col, Alert, Modal, Empty } from "antd";
import { ContratInfo } from '../Contrat/ContratInfo/ContratInfo';

import "./ContratsActif.css";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { FaBabyCarriage } from "react-icons/fa6";
import { MdFamilyRestroom } from "react-icons/md";

const ContratsActif = () => {
    const { token, user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [contrats, setContrats] = useState([]);
    const [error, setError] = useState(null);

    const [selectedContrat, setSelectedContrat] = useState(null); // Contrat sélectionné pour le modal
    const [isModalVisible, setIsModalVisible] = useState(false);  // Contrôle de la visibilité du modal

    const renderIcon = (IconComponent, color) => {
        return <IconComponent style={{ color, marginRight: 5 }} />;
    };

    // Définir l'icône de rôle par défaut
    let roleIcon;
    let isAssistanteMaternelle = false;
    if (user.Roles.includes("ROLE_PARENT")) {
        roleIcon = FaBabyCarriage;
    } else if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
        roleIcon = MdFamilyRestroom;
        isAssistanteMaternelle = true;
    } else {
        roleIcon = UserOutlined;
    }

    useEffect(() => {
        fetchContrats();
    }, []);

    const showModal = (contrat) => {
        setSelectedContrat(contrat);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedContrat(null);
    };

    const fetchContrats = async () => {
        try {
            const response = await fetch(
                "https://api.uama.fr/v1.0/contrat/index?limit=inf&option=Actif",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setContrats(data.contrats);
                setLoading(false);
            } else {
                const errorData = await response.json();
                setLoading(false);
                setError(errorData.message);
            }
        } catch (error) {
            setError("Impossible de communiquer avec le serveur");
            console.error("Error fetching contracts:", error);
            setLoading(false);
        }
    };

    return (
        <div className="card-content-scrollable" style={{ overflowY: "auto" }}>
            {error ? (
                <Alert message={error} type="error" />
            ) : loading ? (
                <Spin />
            ) : (
                <>
                    {contrats.length > 0 ? (
                        <Row gutter={[16, 16]} style={{ margin: 0 }}>
                            {contrats.map((contrat) => (
                                <Col key={contrat.Enfant.UUID} xs={24} sm={12} md={12} lg={12} xl={8}>
                                    <Card
                                        size="small"
                                        headStyle={{ backgroundColor: "#f0f2f5", color: "#333" }}
                                        title={
                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
                                                <img
                                                    src={contrat.Photo || ""}
                                                    alt={`Initiales de l'enfant : ${contrat["Enfant"]["Nom complet"]}, avec un fond coloré qui a été choisi lors de la création du contrat`}
                                                    style={{ width: 25, height: 25, marginRight: 8 }}
                                                />
                                                <span>{contrat.Enfant["Nom complet"]}</span>
                                            </div>
                                        }
                                        onClick={() => showModal(contrat)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <p className="m-0 p-0">
                                            <CalendarOutlined style={{ color: contrat.Couleur, marginRight: 5 }} />
                                            <strong>Âge :</strong> {contrat.Enfant.Age}
                                        </p>

                                        <p className="m-0 p-0">
                                            {renderIcon(roleIcon, contrat.Couleur)}
                                            <strong>{isAssistanteMaternelle ? "Parent" : "Gardé(e) par"} : </strong>
                                            {isAssistanteMaternelle ? contrat.Enfant.Parent : contrat["Assistante maternelle"]["Nom complet"]}
                                        </p>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Empty
                            description={
                                <span>
                                    Aucun contrat actif trouvé.
                                </span>
                            }
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </>
            )}

            {/* Modal pour afficher les détails du contrat */}
            <Modal
                title={`Détails du contrat de ${selectedContrat?.Enfant["Nom complet"]}`}
                visible={isModalVisible}
                onCancel={handleModalClose}
                width={800}
                footer={null}
            >
                {selectedContrat &&
                    <ContratInfo contrat={selectedContrat} />} {/* Affiche ContratInfo si un contrat est sélectionné */}
            </Modal>
        </div>
    );
};

export default ContratsActif;
