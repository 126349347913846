import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Hook pour ajouter ou retirer une classe CSS sur un élément en fonction du chemin de la route.
 * @param {string} targetId - L'ID de l'élément DOM cible.
 * @param {string} className - Le nom de la classe CSS à ajouter/retirer.
 * @param {string} path - Le chemin de la route où la classe doit être ajoutée.
 */
const useAddClassOnPath = (targetId, className, path) => {
    const location = useLocation(); // Pour surveiller le chemin actuel.

    useEffect(() => {
        const element = document.getElementById(targetId);

        if (!element) return; // Arrêter si l'élément n'existe pas.

        if (location.pathname.includes(path)) {
            // Ajout de la classe si on est sur la route.
            element.classList.add(className);
        } else {
            // Suppression de la classe si on quitte la route.
            element.classList.remove(className);
        }

        // Nettoyage automatique si le composant est démonté.
        return () => {
            element.classList.remove(className);
        };
    }, [location.pathname, targetId, className, path]);
};

export default useAddClassOnPath;
