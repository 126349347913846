import React,{useEffect} from "react";

import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../../Routes/Routes";

const LogoutPage = () => {

    const { logout } = useAuth();
    const navigate = useNavigate();
  
    useEffect(() => {
      const handleLogout = async () => {
        // Effectuez la déconnexion
        await logout();
  
        // Naviguez vers la page de connexion ("/login") après la déconnexion
        navigate(ROUTES.LOGIN, { replace: true });
      };
  
      // Appelez la fonction de déconnexion une fois que le composant est monté
      handleLogout();
    }, [logout, navigate]);
  
  return <div>
  </div>;
};

export default LogoutPage;
