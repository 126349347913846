import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { message, Select, List, Card, Col, Row, Divider, Typography, Tooltip } from "antd";
import { motion } from "framer-motion";
import ImpotsCard from "../../components/Impots/ImpotsCard/ImpotsCard";
import Loading from "../../components/Loading/Loading";
import { TbMoodKid } from "react-icons/tb";
import { MdFamilyRestroom } from "react-icons/md";
import { EuroCircleOutlined } from "@ant-design/icons";

import "./ImpotsPage.css";

const { Option, OptGroup } = Select;
const { Title, Text } = Typography;

const ImpotsPage = () => {
  const { token } = useAuth();
  const [contrats, setContrats] = useState([]);
  const [impotsData, setImpotsData] = useState({});
  const [selectedContrat, setSelectedContrat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContrats = async () => {
      try {
        const response = await fetch("https://api.uama.fr/v1.0/contrat/index?limit=inf", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          setContrats(data.contrats);
        } else {
          const errorData = await response.json();
          message.error(errorData.message);
          throw new Error("Erreur lors de la récupération des contrats");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContrats();
  }, [token]);

  const fetchImpots = async () => {
    if (!selectedContrat) return;

    setIsLoading(true);

    try {
      const response = await fetch(`https://api.uama.fr/v1.0/gestion-fiscale/${selectedContrat}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        setImpotsData(data);
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
        throw new Error("Erreur lors de la récupération des impôts");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchImpots();
  }, [selectedContrat]);

  const renderOptions = () => {
    const groupedContrats = contrats.reduce((groups, contrat) => {
      const status = contrat.Statut || "Statut inconnu";
      if (!groups[status]) groups[status] = [];
      groups[status].push(contrat);
      return groups;
    }, {});

    const normalizeStatus = (status) => {
      return status
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
          .replace(/\s+/g, "_") // Remplace les espaces par des underscores ou des tirets
          .toLowerCase(); // Convertit en minuscule pour être cohérent
    };

    return Object.keys(groupedContrats).map((status) => (
        <OptGroup key={status} label={status} className={`custom-opt-group ${normalizeStatus(status)}`}>
          {groupedContrats[status].map((contrat) => (
              <Option key={contrat.UUID} value={contrat.UUID}>
                <TbMoodKid style={{ color: "#4e89ff", marginRight: "8px" }} />
                {contrat.Enfant["Nom complet"]}{" "}
                <Tooltip title="Parent">
                  (
                    <MdFamilyRestroom style={{ color: "#ff7f50", marginRight: "8px" }} />
                    {contrat.Enfant.Parent}
                  )
                </Tooltip>
              </Option>
          ))}
        </OptGroup>
    ));
  };

  return (
      <>
        <Row justify="center" style={{ marginBottom: "20px" }}>
          <Col xs={24} md={18}>
            <Card className="card-container">
              <Title level={3} style={{ color: "#1890ff", textAlign: "center" }}>
                <EuroCircleOutlined style={{ marginRight: "10px" }} />
                <span style={{ color: "#000", fontWeight: "bold" }}>Gestion fiscale de vos contrats</span>
              </Title>
              <Text type="secondary" style={{ textAlign: "center", display: "block" }}>
                {selectedContrat
                    ? "Consultez les détails fiscaux du contrat sélectionné."
                    : "Veuillez choisir un contrat pour afficher les informations fiscales."}
              </Text>
              <Divider />
              <Select
                  style={{ width: "100%", marginBottom: "20px" }}
                  placeholder="Sélectionnez un contrat"
                  onChange={(value) => setSelectedContrat(value)}
                  disabled={isLoading}
                  showSearch
              >
                {renderOptions()}
              </Select>
            </Card>
          </Col>
        </Row>

        {isLoading ? (
            <Loading />
        ) : (
            <motion.div
                className="list-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
            >
              <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    lg: 4,
                  }}
                  dataSource={Object.keys(impotsData).filter((key) => key !== "enfant" && key !== "adresse")}
                  renderItem={(key) => (
                      <List.Item>
                        <ImpotsCard
                            impots={impotsData[key]}
                            selectedContrat={selectedContrat}
                            refresh={fetchImpots}
                            nomEnfant={impotsData.enfant}
                        />
                      </List.Item>
                  )}
              />
            </motion.div>
        )}
      </>
  );
};

export default ImpotsPage;
