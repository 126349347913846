import React, { useState, useEffect } from "react";
import {Form, Input, DatePicker, Button, Steps, Divider, message, Alert, Checkbox, Progress, AutoComplete} from "antd";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import logo from "../../../../assets/logo.png";
import { UserOutlined, InfoCircleOutlined, HomeOutlined } from "@ant-design/icons";
import {usePasswordStrength} from "../../../../utils/passwordUtils";
import {ROUTES} from "../../../../Routes/Routes";
import PasswordStrengthIndicator from "../../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator";
import { fetchCitiesFromGeoAPI, fetchZipCodesFromGeoAPI} from "../../../../utils/cityAndZipCodeUtils";
import useAddClassOnPath from "../../../../hooks/useAddClassOnPath";

moment.locale("fr");

const { Item } = Form;
const { Step } = Steps;

const SignInParent = () => {
    const { idParent } = useParams(); // Get the "id" parameter from the URL
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const { passwordStrength, isStrongEnough, missingCriteria,  calculatePasswordStrength } = usePasswordStrength();
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    useAddClassOnPath("bodyContent", "file-explorer-active", ROUTES.SIGNIN_ASMAT);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.uama.fr/auth/information/parent/${idParent}`);
                const data = response.data;
                form.setFieldsValue({
                    email: data.email,
                    nom: data.nom,
                    prenom: data.prenom,
                });
                setFormData({
                    email: data.email,
                    nom: data.nom,
                    prenom: data.prenom,
                });
            } catch (error) {
                console.error("API Error:", error);
                let msg = "Erreur lors de la récupération des données."
                if(error.response.status !== 500) {
                    msg = error.response.data.message;
                }
                message.error(msg);
            }
        };
        fetchData();
    }, [idParent, form]);

    const handleNext = () => {
        form
            .validateFields()
            .then((values) => {
                setFormData({ ...formData, ...values }); // Merge values into formData
                setCurrentStep(currentStep + 1);
            })
            .catch((error) => {
                console.log("Validation Error:", error);
                message.error("Veuillez remplir correctement tous les champs.");
            });
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await onFinish(values);
        } catch (error) {
            message.error("Erreur lors de la soumission du formulaire.");
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `https://api.uama.fr/auth/completion/parent/${idParent}`,
                {
                    email: formData.email,
                    numeroTelephone: formData.numeroTelephone,
                    dateNaissance: formData.dateNaissance ? formData.dateNaissance.format("YYYY-MM-DD") : null,
                    password: formData.password,
                    prenom: formData.prenom,
                    nom: formData.nom,
                    adresse: values.adresse,
                    codePostal: values.codePostal,
                    ville: values.ville,
                    pays: "FRANCE",
                    receiveNotification: values.receiveNotification,
                }
            );
            console.log("API Response:", response.data);
            message.success("Inscription réussi");
            navigate("/");
        } catch (error) {
            console.error("API Error:", error.response.data.message);
            let msg = "Erreur lors de votre inscription à notre plateforme."
            if(error.response.status !== 500) {
                msg = error.response.data.message;
            }
            message.error(msg);
        } finally {
            setLoading(false);
        }
    };

    // Fonction de recherche pour les villes et les codes postaux
    const handleSearch = async (value) => {
        if (!value) {
            setOptions([]);
            return;
        }

        const isZipCode = /^\d+$/.test(value); // Vérifie si c'est un code postal
        let results = [];

        if (isZipCode) {
            results = await fetchZipCodesFromGeoAPI(value); // Recherche par code postal
        } else {
            results = await fetchCitiesFromGeoAPI(value); // Recherche par ville
        }

        setOptions(
            results.map((item) => ({
                value: `${item.ville}, ${item.codePostal}`,
                label: `${item.ville} (${item.codePostal})`,
                ville: item.ville,
                codePostal: item.codePostal,
            }))
        );
    };

    // Fonction pour remplir automatiquement les champs après sélection d'une ville
    const handleSelect = (value, option) => {
        form.setFieldsValue({
            ville: option.ville,
            codePostal: option.codePostal,
        });
    };

    return (
        <div className="signIn-container">
            <div className="signIn-wrapper">
                <div className="signIn-header">
                    <img src={logo} alt="Logo UAMA" className="signIn-logo"/>
                    <h1>UAMA</h1>
                    <p className="login-fullname" style={{fontSize: "0.9rem", color: "#888"}}>
                        Univers des Assistantes Maternelles Agrées
                    </p>
                    <p className="signIn-description">
                        Rejoignez UAMA et facilitez la gestion des informations et documents nécessaires pour la garde
                        de votre enfant
                    </p>
                    <Link to={ROUTES.TERMS}>Conditions générales d'utilisation</Link>
                </div>

                <Alert
                    description={
                        <span
                            dangerouslySetInnerHTML={{__html: "<b>Attention</b> : Cette page est destinée aux parents uniquement !" }} />
                    }
                    type="warning"
                    showIcon
                    className="alert-Asmat"
                />

                <Form
                    form={form}
                    className="signIn-form"
                    onFinish={onFinish}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 24}}
                >
                    {/* Etape 1: Identifiants */}
                    {currentStep === 0 && (
                        <>
                            <Form.Item
                                name="email"
                                label="Email"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                    {
                                        type: "email",
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                ]}
                            >
                                <Input type="email" autoComplete="email"/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Mot de passe"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            // Vérifie si le champ est vide avant de vérifier la force
                                            if (!value) {
                                                return Promise.reject(new Error("Merci de saisir un mot de passe"));
                                            }

                                            calculatePasswordStrength(value);

                                            if (isStrongEnough) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Le mot de passe n'est pas assez fort"));
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password type="password" autoComplete="new-password" onChange={(e) => calculatePasswordStrength(e.target.value)} />
                            </Form.Item>

                            {/* Indicateur de force du mot de passe */}
                            <PasswordStrengthIndicator
                                passwordStrength={passwordStrength}
                                isStrongEnough={isStrongEnough}
                                missingCriteria={missingCriteria}
                            />

                            <Form.Item
                                name="passwordConfirmation"
                                label="Confirmation du mot de passe"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de confirmer votre mot de passe",
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Les mots de passe ne correspondent pas ")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password type="password" autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item className="checkboxNotif" name="receiveNotification" valuePropName="checked">
                                <Checkbox>Recevoir des notifications</Checkbox>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 2: Informations */}
                    {currentStep === 1 && (
                        <>
                            <Form.Item
                                name="nom"
                                label="Nom"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un nom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="family-name" />
                            </Form.Item>
                            <Form.Item
                                name="prenom"
                                label="Prénom"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un prénom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="given-name" />
                            </Form.Item>
                            <Form.Item
                                name="numeroTelephone"
                                label="Numéro de téléphone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un numéro de téléphone",
                                    },
                                    {
                                        pattern: /^(0\d{9}|(\+?[1-9]\d{0,2}[-.\s]?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}))$/,
                                        message: "Merci de saisir un numéro de téléphone valide. Exemple : +33 6 12 34 56 78 ou 06 12 34 56 78.",
                                    },
                                ]}
                            >
                                <Input type="tel" autoComplete="tel" />
                            </Form.Item>

                            <Form.Item
                                name="dateNaissance"
                                label="Date de naissance"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une date de naissance",
                                    },
                                ]}
                            >
                                <DatePicker style={{width: "100%"}} format={dateFormats}/>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 3: Adresse */}
                    {currentStep === 2 && (
                        <>
                            <Form.Item
                                name="adresse"
                                label="Adresse"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="street-address" />
                            </Form.Item>
                            <Form.Item
                                name="codePostal"
                                label="Code postal"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un code postal",
                                        whitespace: true,
                                    },
                                    {
                                        pattern: /^\d{5}$/, // Une expression régulière pour valider le format du code postal (5 chiffres)
                                        message: "Le code postal doit être composé de 5 chiffres",
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez un code postal"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ codePostal: value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="ville"
                                label="Ville"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une ville",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez une ville"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ ville: value });
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Item wrapperCol={{offset: 8, span: 16}}>
                        {currentStep === 0 && (
                            <Link to={ROUTES.LOGIN} style={{ margin: "0 8px" }}>
                                Se connecter
                            </Link>
                        )}
                        {currentStep > 0 && (
                            <Button style={{margin: "0 8px"}} onClick={handlePrev}>
                                Précédent
                            </Button>
                        )}
                        {currentStep < 2 && (
                            <Button type="primary" onClick={handleNext}>
                                Suivant
                            </Button>
                        )}
                        {currentStep === 2 && (
                            <Button type="primary" onClick={handleSubmit} loading={loading}>
                                S'inscrire
                            </Button>
                        )}
                    </Item>

                    <Divider/>

                    <Steps current={currentStep}>
                        <Step title="Identifiants" icon={<UserOutlined />} />
                        <Step title="Informations" icon={<InfoCircleOutlined />} />
                        <Step title="Adresse" icon={<HomeOutlined />} />
                    </Steps>
                </Form>
            </div>
        </div>
    );
};

export default SignInParent;
