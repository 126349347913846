import React, { useEffect, useState } from "react";
import {Card, Row, Col, Select, Table, message, Typography, Spin, Button, Tooltip, Divider} from "antd";
import dayjs from "dayjs";
import { useAuth } from "../../context/AuthContext";
import "./HoursWorkedPage.css";
import axios from "axios";
import {ClockCircleOutlined, DownloadOutlined, FrownOutlined} from "@ant-design/icons";
import html2canvas from "html2canvas";

const { Option } = Select;
const { Title, Text } = Typography;

const HoursWorkedPage = () => {
    const { token } = useAuth();
    const [data, setData] = useState([]);
    const [year, setYear] = useState(dayjs().subtract(1, "month").year());
    const [month, setMonth] = useState(dayjs().subtract(1, "month").month() + 1);
    const [loading, setLoading] = useState(false);
    const [minYear, setMinYear] = useState(dayjs().year() - 5);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMinYear();
            await fetchHoursWorked();
        };
        fetchData();
    }, [year, month]);

    const fetchMinYear = async () => {
        try {
            const response = await axios.get(`https://api.uama.fr/v1.0/planning/heures-effectuees/annee-min`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200) {
                const anneeMinUrl = response.data.anneeMin;
                setMinYear(anneeMinUrl);
                if (year < anneeMinUrl) {
                    setYear(anneeMinUrl);
                }
            } else {
                console.error("Unexpected response:", response);
                message.error("Erreur lors de la récupération de l'année minimale.");
            }
        } catch (error) {
            if (!error.response) {
                console.error("Network error or CORS issue:", error);
                message.error("Erreur réseau : le serveur est inaccessible.");
            } else {
                console.error("API error:", error.response);
                message.error(
                    error.response.data?.message || "Erreur lors de la récupération de l'année minimale."
                );
            }
        }
    };

    const fetchHoursWorked = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `https://api.uama.fr/v1.0/planning/heures-effectuees?annee=${year}&mois=${month}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200) {
                setData(response.data.data);
            }
            else {
                throw new Error("Erreur lors de la récupération des heures travaillées.");
            }
        } catch (error) {
            console.error("Error fetching hours worked:", error);
            message.error(error.message || "Erreur lors du chargement des données.");
        } finally {
            setLoading(false);
        }
    };

    const handleYearChange = (value) => {
        setYear(value);
    };

    const handleMonthChange = (value) => {
        setMonth(value);
    };

    const renderYearOptions = () => {
        const currentYear = dayjs().year();
        const years = [];
        for (let i = currentYear; i >= minYear; i--) {
            years.push(i);
        }
        return years.map((year) => <Option key={year} value={year}>{year}</Option>);
    };

    const renderMonthOptions = () => {
        const months = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        return months.map((month, index) => (
            <Option key={index + 1} value={index + 1}>{month}</Option>
        ));
    };

    const columns = [
        {
            title: "Contrat",
            dataIndex: "contrat",
            key: "contrat",
            render: (text) => <Text strong>{text}</Text>,
            width: "40%",
        },
        {
            title: "Nombre d'heures effectuées",
            dataIndex: "formattedHours",
            key: "formattedHours",
            width: "30%",
        },
        {
            title: "Heures complémentaires",
            dataIndex: "formattedComplementaire",
            key: "formattedComplementaire",
            width: "30%",
            responsive: ['md'],
        },
    ];

    const handleExport = async () => {
        const tableElement = document.querySelector(".hours-worked-table");
        if (!tableElement) {
            message.error("Impossible de capturer le tableau.");
            return;
        }
        try {
            const canvas = await html2canvas(tableElement, { scale: 2 });
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = `Heures_travaillées_${year}_${month}.png`;
            link.click();
            message.success("Tableau exporté avec succès !");
        } catch (error) {
            console.error("Export error:", error);
            message.error("Erreur lors de l'exportation du tableau.");
        }
    };

    return (
        <div className="hours-worked-container">
            <Row justify="center" align="middle">
                <Col xs={24} md={20} lg={16} xl={12}>
                    <Card className="hours-worked-card">
                        <Title level={3} className="hours-worked-title">
                            <ClockCircleOutlined style={{ marginRight: "8px", color: "#1890ff"}} /> Heures travaillées
                        </Title>
                        <Row gutter={[16,16]} className="filter-container">
                            <Col xs={24} sm={10}>
                                <Select
                                    className="year-select"
                                    value={year}
                                    onChange={handleYearChange}
                                    placeholder="Sélectionner une année"
                                >
                                    {renderYearOptions()}
                                </Select>
                            </Col>
                            <Col xs={24} sm={11}>
                                <Select
                                    className="month-select"
                                    value={month}
                                    onChange={handleMonthChange}
                                    placeholder="Sélectionner un mois"
                                >
                                    {renderMonthOptions()}
                                </Select>
                            </Col>
                            <Col xs={24} sm={3}>
                                <Tooltip title="Exporter le tableau">
                                    <Button
                                        type="primary"
                                        onClick={handleExport}
                                        style={{ width: "100%", fontSize: "16px" }}
                                    >
                                        <DownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>

                        <Divider />

                        {loading ? (
                            <div className="loading-container">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="hours-worked-table">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    bordered
                                    rowKey="contrat"
                                    pagination={false}
                                    rowClassName={(record, index) =>
                                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                                    }
                                    scroll={{ y: 'calc(var(--table-max-height) - 100px)' }}
                                    locale={{
                                        emptyText: (
                                            <div style={{ textAlign: "center" }}>
                                                <FrownOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
                                                <p style={{ marginTop: "10px" }}>
                                                    Aucune donnée disponible pour la période sélectionnée.
                                                </p>
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default HoursWorkedPage;
