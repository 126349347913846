import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import logo from "../../../../assets/logo.png";
import "./ForgotPasswordPasswordForm.css";

import { usePasswordStrength } from "../../../../utils/passwordUtils";
import PasswordStrengthIndicator from "../../../PasswordStrengthIndicator/PasswordStrengthIndicator";

const ForgotPasswordPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { passwordStrength, isStrongEnough, missingCriteria, calculatePasswordStrength } = usePasswordStrength();

  const onFinish = async (values) => {
    if (!isStrongEnough) {
      message.error("Le mot de passe n'est pas assez fort");
      return;
    }

    setLoading(true);
    const resetPasswordEndpoint = `https://api.uama.fr/password/reset/${id}`;

    try {
      const response = await axios.put(resetPasswordEndpoint, { password: values.password });
      message.success("Le mot de passe a été réinitialisé avec succès");
      navigate("/login");
    } catch (error) {
      let msg = "Une erreur s'est produite lors de votre réinitialisation de mot de passe";
      if (error.response?.status !== 500) {
        msg = error.response.data.message;
      }
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="forgotpwd-container">
        <div className="forgotpwd-wrapper">
          <div className="forgotpwd-header">
            <img src={logo} alt="Logo UAMA" className="signIn-logo" />
            <h1>UAMA</h1>
            <p className="forgotpwd-description">
              On y est presque ! Entrez un nouveau mot de passe pour retrouver l'accès à votre compte
            </p>
          </div>

          <Form name="ForgotPasswordPassword" size="large" className="login-form" onFinish={onFinish}>
            <Form.Item
                hasFeedback
                name="password"
                rules={[
                  () => ({
                    validator(_, value) {
                      // Vérifie si le champ est vide avant de vérifier la force
                      if (!value) {
                        return Promise.reject(new Error("Merci de saisir un mot de passe"));
                      }

                      calculatePasswordStrength(value);

                      if (isStrongEnough) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Le mot de passe n'est pas assez fort"));
                    },
                  }),
                ]}
            >
              <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Nouveau mot de passe"
                  onChange={(e) => calculatePasswordStrength(e.target.value)}
              />
            </Form.Item>

            {/* Indicateur de force du mot de passe */}
            <PasswordStrengthIndicator
                passwordStrength={passwordStrength}
                isStrongEnough={isStrongEnough}
                missingCriteria={missingCriteria}
            />

            <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  { required: true, message: "Merci de confirmer votre mot de passe" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Les deux mots de passe ne correspondent pas"));
                    },
                  }),
                ]}
            >
              <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Confirmer le mot de passe"
              />
            </Form.Item>

            <Form.Item>
              <a href="/Login" className="forgotpwd-form-login">Se connecter</a>
            </Form.Item>

            <Form.Item>
              <Button
                  type="primary"
                  htmlType="submit"
                  className="forgot-password-password-button"
                  loading={loading}
              >
                Réinitialiser le mot de passe
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
  );
};

export default ForgotPasswordPassword;
