import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { motion } from "framer-motion";
import {Col, Layout, Row} from "antd";
import {useAuth} from "./context/AuthContext";
import setupAxiosInterceptors from "./utils/setupAxiosInterceptors";
import fetchWithInterceptor from "./utils/fetchWithInterceptor";
import {useNavigate} from "react-router-dom";

const { Content } = Layout;

const Main = ({ children }) => {
    const [isHeaderOpen, setIsHeaderOpen] = useState();
    const [firstAnimate, setFirstAnimate] = useState(false);
    const [animate, setAnimate] = useState("0");
    const { isAuthenticated, hasAcceptedTerms, setLatestTermsVersion, setHasAcceptedTerms  } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isHeaderOpen || firstAnimate) {
            setFirstAnimate(true);
            setAnimate("250px");
        }
    }, [isHeaderOpen]);

    useEffect(() => {
        setupAxiosInterceptors(navigate, setHasAcceptedTerms, setLatestTermsVersion);
        window.fetch = (url, options) => fetchWithInterceptor(url, options, navigate, setLatestTermsVersion, setHasAcceptedTerms);
    }, [navigate, setHasAcceptedTerms, setLatestTermsVersion]);

    return (
        <div className="App">
            <Layout className="body" style={{ minHeight: "100vh" }}>
                <Layout>
                    {isAuthenticated && hasAcceptedTerms && (
                        <Header setIsHeaderOpen={setIsHeaderOpen} />
                    )}
                    <div id="pageContent">
                        <motion.div
                            className="content"
                            initial={isHeaderOpen ? { x: "O" } : { x: animate }}
                            animate={isHeaderOpen ? { x: animate } : { x: "0px" }}
                            transition={{
                                type: "spring",
                                stiffness: 400,
                                damping: 30,
                                delay: isHeaderOpen ? 0 : 0.6,
                            }}
                        >
                            <motion.div
                                initial="closed"
                                animate={isHeaderOpen ? "open" : "closed"}
                                variants={{
                                    open: { width: "calc(85% - 4px)" },
                                    closed: { width: "calc(100% - 4px)" },
                                }}
                                transition={{ delay: isHeaderOpen ? 0 : 0.6 }}
                            >
                            </motion.div>
                        </motion.div>
                        <div id={"bodyContent"} className={isAuthenticated ? "bodyContent" : ""}>
                            <Content className={"children-content"} style={isHeaderOpen ? { filter : "blur(5px)", transition : "filter 0.3s ease" } : {}} >{children}</Content>
                        </div>
                    </div>
                </Layout>
                {isAuthenticated && <Sidebar />}
            </Layout>
        </div>
    );
};

export default Main;
