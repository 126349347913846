import React, {useState, useEffect, useRef} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap5 from "@fullcalendar/bootstrap5";
import listPlugin from "@fullcalendar/list";
import scrollgrid from "@fullcalendar/scrollgrid";
import interactionPlugin from "@fullcalendar/interaction";
import {Button, Checkbox, message, Modal, Popover, Row} from "antd";
import html2canvas from "html2canvas";

import {useAuth} from "../../context/AuthContext";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Planning.css";
import PlanningModalEdit from "./PlanningModalEdit/PlanningModalEdit";
import PlanningModalAdd from "./PlanningModalAdd/PlanningModalAdd";
import PlanningModalArretMaladie from "./PlanningModalArretMaladie/PlanningModalArretMaladie";
import useIsMobile from "../../hooks/useIsMobile";
import Loading from "../Loading/Loading";
import {DownloadOutlined, DownOutlined, ReloadOutlined} from "@ant-design/icons";
import ReactDOM from "react-dom";


const Planning = ({initialView, height, showBtnFC = false, showCustomBtn = true, modalType, closeModal, openModal}) => {
    const [holidays, setHolidays] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const {token, user} = useAuth();
    const isMobile = useIsMobile(1300);
    const calendarRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const updateNowIndicatorTime = () => {
            const now = new Date();
            const currentTime = now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            const nowIndicatorArrow = document.querySelector('.fc-timegrid-now-indicator-arrow');
            if (nowIndicatorArrow) {
                nowIndicatorArrow.setAttribute('data-time', currentTime);
            }
        };

        updateNowIndicatorTime();

        const intervalId = setInterval(updateNowIndicatorTime, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const exportButton = document.querySelector(".fc-addExportButton-button");
        if (exportButton) {
            exportButton.innerHTML = ""; // Efface le contenu texte
            exportButton.classList.add("export-icon-button"); // Ajoutez une classe pour personnaliser le style

            // Créez un nouvel élément React pour l'icône et injectez-le dans le bouton
            const iconContainer = document.createElement("div");
            iconContainer.classList.add("icon-container");
            exportButton.appendChild(iconContainer);

            // Utilisez React pour injecter le composant SaveOutlined dans iconContainer
            ReactDOM.render(<DownloadOutlined/>, iconContainer);
        }

        const refreshButton = document.querySelector(".fc-addRefreshButton-button");
        if (refreshButton) {
            refreshButton.innerHTML = ""; // Efface le contenu texte
            refreshButton.classList.add("export-icon-button"); // Ajoutez une classe pour personnaliser le style

            // Créez un nouvel élément React pour l'icône et injectez-le dans le bouton
            const iconContainer = document.createElement("div");
            iconContainer.classList.add("icon-container");
            refreshButton.appendChild(iconContainer);

            // Utilisez React pour injecter le composant SaveOutlined dans iconContainer
            ReactDOM.render(<ReloadOutlined/>, iconContainer);
        }
    }, []);

    const exportToImage = async (title = "calendrier") => {
        try {
            const calendarElement = document.querySelector(".fc");

            // Vérifier si l'élément du calendrier existe
            if (!calendarElement) throw new Error("L'élément du calendrier n'a pas été trouvé.");

            // Sélectionner les éléments nécessaires
            const colgroupElement = calendarElement.querySelector(".fc-timegrid-slots table");
            const headerElement = calendarElement.querySelector(".fc-header-toolbar");
            const headerSectionElement = calendarElement.querySelector(".fc-scrollgrid-section-header");
            const bodySectionElement = calendarElement.querySelector(".fc-scrollgrid-section-body");
            const dayHeaderElement = calendarElement.querySelector("thead[role='presentation']"); // En-têtes de jours

            // Calculer la hauteur et la largeur des différentes sections
            const colgroupHeight = colgroupElement ? colgroupElement.offsetHeight : 0;
            const colgroupWidth = colgroupElement ? colgroupElement.offsetWidth : 0;

            const headerStyle = headerElement ? getComputedStyle(headerElement) : {};
            const headerHeight = headerElement
                ? headerElement.offsetHeight +
                parseFloat(headerStyle.marginTop || 0) +
                parseFloat(headerStyle.marginBottom || 0) +
                parseFloat(headerStyle.paddingTop || 0) +
                parseFloat(headerStyle.paddingBottom || 0) +
                parseFloat(headerStyle.borderTopWidth || 0) +
                parseFloat(headerStyle.borderBottomWidth || 0)
                : 0;

            const headerSectionHeight = headerSectionElement ? headerSectionElement.offsetHeight : 0;
            const bodySectionHeight = bodySectionElement ? bodySectionElement.offsetHeight : 0;

            const dayHeaderHeight = dayHeaderElement ? dayHeaderElement.offsetHeight : 0;
            const dayHeaderWidth = dayHeaderElement ? dayHeaderElement.offsetWidth : 0;

            // Calculer la hauteur totale requise
            const requiredHeight = colgroupHeight + headerHeight + headerSectionHeight + bodySectionHeight + dayHeaderHeight;
            const requiredWidth = colgroupWidth + dayHeaderWidth

            // Sauvegarder les dimensions actuelles du calendrier
            const originalHeight = calendarElement.style.height;
            const originalWidth = calendarElement.style.width;

            // Appliquer les dimensions calculées
            calendarElement.style.height = `${requiredHeight}px`;
            calendarElement.style.width = `${requiredWidth}px`;

            // Générer l'image avec html2canvas
            const canvas = await html2canvas(calendarElement, {scale: 2});
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = `${title}.png`;
            link.click();

            // Restaurer les dimensions d'origine
            calendarElement.style.height = originalHeight;
            calendarElement.style.width = originalWidth;

            return {success: true};
        } catch (error) {
            console.error("Erreur lors de l'exportation :", error);
            return {success: false, error: error.message};
        }
    };

    const handleExportToImage = async () => {
        setIsLoading(true); // Afficher le chargement

        // Récupération du titre pour le nom du fichier
        const titleElement = document.querySelector(".fc-toolbar-title");
        const rawTitle = titleElement ? titleElement.innerText : "Planning";

        // Format de nom de fichier sans caractères spéciaux, avec des underscores et sans extension redondante
        let fileName = `UAMA_Planning_${rawTitle
            .replace(/\s+/g, '_')                // Remplacer les espaces multiples par un underscore
            .replace(/[^a-zA-Z0-9_]/g, '')       // Supprimer les caractères spéciaux
            .replace(/__+/g, '_')}`;             // Remplacer les doubles underscores par un seul

        // Basculer en vue `timeGridWeek` via la référence
        const calendarApi = calendarRef.current?.getApi();
        if (!calendarApi) {
            message.error("Le planning n'a pas été trouvé par notre système");
            setIsLoading(false);
            return;
        }
        const originalView = calendarApi.view.type; // Sauvegarder la vue actuelle
        calendarApi.changeView("timeGridWeek"); // Passer à la vue `timeGridWeek`

        // Cacher les boutons de la barre d'outils
        const toolbarButtons = document.querySelectorAll(".fc-toolbar-chunk .btn, .fc-toolbar-chunk .btn-group, .fc-timegrid-now-indicator-line, .fc-timegrid-now-indicator-arrow ");
        toolbarButtons.forEach(button => (button.style.display = "none"));

        // Désactiver les animations de pulsation
        const animatedEvents = document.querySelectorAll('.pending-event, .rejected-event');
        animatedEvents.forEach(event => event.classList.add('no-animation'));

        // Appeler exportToImage et gérer le retour
        const result = await exportToImage(fileName);

        // Afficher un message en fonction du résultat
        if (result.success) {
            message.success("Le planning a été exporté avec succès !");
        } else {
            message.error(result.error);
        }

        // Restaurer les animations
        animatedEvents.forEach(event => event.classList.remove('no-animation'));

        // Restaurer l'affichage des boutons et arrêter le chargement
        toolbarButtons.forEach(button => (button.style.display = ""));
        calendarApi.changeView(originalView); // Restaurer la vue initiale
        setIsLoading(false);
    };

    const handleRefresh = () => {
        fetchCustomEvents();
        fetchAllHolidays()
    }

    const [calendarSize, setCalendarSize] = useState({
        dayMinWidth: "120",
        height: height || "77vh"
    });

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 768) {
                setCalendarSize((prevSize) => ({...prevSize, dayMinWidth: "120"}));
            } else if (screenWidth <= 1024) {
                setCalendarSize((prevSize) => ({...prevSize, dayMinWidth: "100"}));
            } else {
                setCalendarSize((prevSize) => ({...prevSize, dayMinWidth: "80"}));
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isAssistanteMaternelle = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

    const getToolbarButtons = (isMobile, showBtnFC, showCustomBtn, isAssistanteMaternelle) => {
        if (!showBtnFC) return "";
        let buttons = "dayGridMonth,timeGridWeek,timeGridDay,listWeek";
        if (!isMobile && showCustomBtn) {
            buttons = isAssistanteMaternelle
                ? "addEventButton addMaladieButton " + buttons
                : "addEventButton " + buttons;
        }
        return buttons;
    };

    const refreshEvents = () => {
        fetchCustomEvents();
    }

    const cancelModalEdit = () => {
        setSelectedEvent("");
        closeModal();
    }

    const fetchHolidays = async (year) => {
        try {
            const response = await fetch(
                `https://api.uama.fr/v1.0/planning/jours-feries?annee=${year}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            return data.joursFeries;
        } catch (error) {
            console.error(`Erreur lors de la récupération des jours fériés pour l'année ${year}:`, error);
            return [];
        }
    };

    const getEventClassNames = (arg) => {
        const {event} = arg;
        let classNames = "custom-event";

        if (event.extendedProps.status === "Absences prévues au contrat") {
            classNames += " absence-event";
        } else if (event.extendedProps.isArretMaladie) {
            classNames += " arret-maladie-event";
        } else if (event.extendedProps.type === "holiday") {
            classNames += " holiday-event";
        } else if (event.extendedProps.status === "Accepté") {
            classNames += " accepted-event";
        } else if (event.extendedProps.status === "Refusé") {
            classNames += " rejected-event";
        } else if (event.extendedProps.status === "En attente de validation") {
            classNames += " pending-event";
        }

        return classNames;
    };

    // Fonction pour choisir une icône selon le titre du jour férié
    const getHolidayIcon = (title) => {
        switch (title) {
            case "1er janvier":
                return "🎆";
            case "Lundi de Pâques":
                return "✝️";
            case "1er mai":
                return "🛠️";
            case "8 mai":
                return "🕊️";
            case "Ascension":
                return "☁️";
            case "14 juillet":
                return "🇫🇷";
            case "Assomption":
                return "🌹";
            case "Toussaint":
                return "🕯️";
            case "11 novembre":
                return "🌹";
            case "Jour de Noël":
                return "🎄";
            default:
                return "🎉"; // Icône par défaut
        }
    };

    const fetchAllHolidays = async () => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        const nextYear = currentYear + 1;

        const currentYearHolidays = await fetchHolidays(currentYear);
        const previousYearHolidays = await fetchHolidays(previousYear);
        const nextYearHolidays = await fetchHolidays(nextYear);

        const allHolidays = [...currentYearHolidays, ...previousYearHolidays, ...nextYearHolidays];

        // Ajout des icônes aux titres des jours fériés
        const holidaysWithIcons = allHolidays.map((holiday) => ({
            ...holiday,
            title: `${getHolidayIcon(holiday.title)} ${holiday.title}`, // Ajoute l'icône au titre
        }));

        setHolidays(holidaysWithIcons);
    };

    const fetchCustomEvents = async () => {
        try {
            const response = await fetch("https://api.uama.fr/v1.0/planning/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();

            if (Array.isArray(data)) {
                setEvents(data);
            } else {
                setEvents([]);
                console.warn("No events found in the API response:", data);
            }
        } catch (error) {
            setEvents([]);
            console.error("Error fetching custom events:", error);
        }
    };

    useEffect(() => {
        fetchAllHolidays();
        fetchCustomEvents();
    }, [token]);

    const handleDateClick = (arg) => {
        // alert(arg.dateStr);
    };

    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
        openModal("editEvent");
    };

    // État pour gérer les filtres
    const [filters, setFilters] = useState({
        maladie: true,
        accepte: true,
        attente: true,
        refuse: true,
        absence: true,
    });

    // Fonction de mise à jour des filtres
    const handleFilterChange = (filter) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filter]: !prevFilters[filter]
        }));
    };

    // Fonction pour appliquer les filtres
    const applyFilters = (events) => {
        return events.filter(event => {
            if (event.isArretMaladie && !filters.maladie) return false;
            if (event.status === "Accepté" && !filters.accepte) return false;
            if (event.status === "En attente" && !filters.attente) return false;
            if (event.status === "Refusé" && !filters.refuse) return false;
            if (event.status === "Absences prévues au contrat" && !filters.absence) return false;
            return true;
        });
    };

    // Contenu du popover pour les filtres
    const filterContent = (
        <div>
            <Checkbox
                checked={filters.maladie}
                onChange={() => handleFilterChange("maladie")}
            >
                Arrêts maladie
            </Checkbox>
            <br/>
            <Checkbox
                checked={filters.accepte}
                onChange={() => handleFilterChange("accepte")}
            >
                Acceptés
            </Checkbox>
            <br/>
            <Checkbox
                checked={filters.attente}
                onChange={() => handleFilterChange("attente")}
            >
                En attente
            </Checkbox>
            <br/>
            <Checkbox
                checked={filters.refuse}
                onChange={() => handleFilterChange("refuse")}
            >
                Refusés
            </Checkbox>
            <br/>
            <Checkbox
                checked={filters.absence}
                onChange={() => handleFilterChange("absence")}
            >
                Absences prévues au contrat
            </Checkbox>
        </div>
    );

    const filteredEvents = applyFilters ? applyFilters(events) : events;

    return (
        <>
        {isLoading && (
            <div className="loading-overlay">
                <Loading/>
            </div>
        )}

            <div className={isLoading ? "blur-background" : ""}>
                <Row className="planning-filters">
                    {isMobile ? (
                        <Popover content={filterContent} trigger="click" placement="bottom">
                            <Button>
                                Filtres <DownOutlined/>
                            </Button>
                        </Popover>
                    ) : (
                        <>
                            <Checkbox
                                checked={filters.maladie}
                                onChange={() => handleFilterChange("maladie")}
                            >
                                Arrêts maladie
                            </Checkbox>

                            <Checkbox
                                checked={filters.accepte}
                                onChange={() => handleFilterChange("accepte")}
                            >
                                Acceptés
                            </Checkbox>

                            <Checkbox
                                checked={filters.attente}
                                onChange={() => handleFilterChange("attente")}
                            >
                                En attente
                            </Checkbox>

                            <Checkbox
                                checked={filters.refuse}
                                onChange={() => handleFilterChange("refuse")}
                            >
                                Refusés
                            </Checkbox>

                            <Checkbox
                                checked={filters.absence}
                                onChange={() => handleFilterChange("absence")}
                            >
                                Absences prévues au contrat
                            </Checkbox>
                        </>
                    )}
                </Row>

                <FullCalendar
                    ref={calendarRef}
                    plugins={[
                        interactionPlugin,
                        dayGridPlugin,
                        timeGridPlugin,
                        bootstrap5,
                        listPlugin,
                        scrollgrid,
                    ]}
                    initialView={initialView}
                    views={{
                        dayGridMonth: {
                            dayHeaderFormat: {weekday: 'long'}
                        },
                        timeGridWeek: {
                            dayHeaderFormat: {weekday: 'long', day: '2-digit', month: '2-digit'}
                        },
                        timeGridDay: {
                            dayHeaderFormat: {weekday: 'long', day: 'numeric', month: 'long'}
                        }
                    }}
                    navLinks={true}
                    locale="fr"
                    dayMinWidth={calendarSize.dayMinWidth}
                    themeSystem="bootstrap5"
                    firstDay={1}
                    nowIndicator={true}
                    weekNumbers={true}
                    weekText={"S"}
                    scrollTime={"00:00:00"}
                    height={height || calendarSize.height}
                    events={[
                        ...filteredEvents.map((event) => ({
                            title: event.title,
                            start: event.start,
                            end: event.end,
                            color: event.color,
                            textColor: event.textColor,
                            eventId: event.id,
                            malade: event.malade,
                            status: event.status,
                            heureComplementaire: event.heureComplementaire,
                            periode: event.periode,
                            isArretMaladie: event.isArretMaladie || false,
                            type: "event",
                        })),
                        ...holidays.map((holiday) => ({
                            title: holiday.title,
                            start: holiday.date,
                            end: holiday.date,
                            color: holiday.color,
                            textColor: holiday.textColor,
                            type: "holiday",
                        })),
                    ]}
                    headerToolbar={{
                        left: showCustomBtn ? "prev,next,today addRefreshButton addExportButton" : "prev,next,today addRefreshButton",
                        center: "title",
                        right: getToolbarButtons(isMobile, showBtnFC, showCustomBtn, isAssistanteMaternelle),
                    }}
                    noEventsContent="Aucun événement cette semaine"
                    allDayText="Journée Entière"
                    buttonText={{
                        today: "Aujourd'hui",
                        month: "Mois",
                        week: "Semaine",
                        day: "Jour",
                        listWeek: "Liste",
                    }}
                    eventClick={handleEventClick}
                    dateClick={handleDateClick}
                    eventClassNames={getEventClassNames}
                    customButtons={{
                        addEventButton: {
                            text: '+ Ajouter un événement',
                            click: () => openModal("addEvent"),
                        },
                        addExportButton: {
                            text: "",
                            click: () => handleExportToImage()
                        },
                        addRefreshButton: {
                            text: "",
                            click: () => handleRefresh()
                        },
                        ...(isAssistanteMaternelle && {
                            addMaladieButton: {
                                text: '+ Ajouter un arrêt maladie',
                                click: () => openModal("maladie"),
                            },
                        }),
                    }}
                />

                <div className="calendar-legend">
                    <span className="legend-item legend-maladie">🩺 Arrêt Maladie</span>
                    <span className="legend-item legend-accepte">✔️Accepté</span>
                    <span className="legend-item legend-attente">⏳ En attente</span>
                    <span className="legend-item legend-refuse">❌ Refusé</span>
                    <span className="legend-item legend-absence">🌴 Absence prévue</span>
                    <span className="legend-item legend-holiday">🎉 Jours Fériés</span>
                </div>

                <Modal
                    title={selectedEvent ? selectedEvent.title : ""}
                    open={modalType === "editEvent"}
                    onCancel={cancelModalEdit}
                    footer={null}
                >
                    <PlanningModalEdit cancelModalEdit={cancelModalEdit} refreshEvents={refreshEvents}
                                       selectedEvent={selectedEvent}/>
                </Modal>

                <Modal
                    title="Ajouter un événement"
                    isModalOpen={modalType === "addEvent"}
                    open={modalType === "addEvent"}
                    onCancel={closeModal}
                    footer={null}
                    width={600}
                >
                    <PlanningModalAdd refreshEvents={refreshEvents} closeAddEventModal={closeModal}/>
                </Modal>

                <Modal
                    title="Ajouter un arrêt maladie"
                    isModalOpen={modalType === "maladie"}
                    open={modalType === "maladie"}
                    onCancel={closeModal}
                    footer={null}
                >
                    <PlanningModalArretMaladie refreshEvents={refreshEvents} closeEventModalMaladie={closeModal}/>
                </Modal>
            </div>
        </>
    );
};

export default Planning;
