import React, {useState} from 'react';
import {Card, Row, Col, Statistic, Progress, Typography, Table, Button, Input} from 'antd';
import ReactEcharts from 'echarts-for-react';
import {
    TeamOutlined,
    FileOutlined,
    NotificationOutlined, UserOutlined,
    CloudOutlined, ReloadOutlined, CloseOutlined,
    ReconciliationOutlined
} from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import './Dashboard.css';
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../../Routes/Routes";
import { MdManageAccounts } from "react-icons/md";

const { Text } = Typography;
const { Search } = Input;

const Dashboard = ({ stats, onRefresh, user = {} }) => {
    const { users, contracts, files, space, notifications, platformUsageRate } = stats;
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState(space.spaceUsedByUser);

    const handleRefresh = () => {
        if (onRefresh) {
            onRefresh();
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
        const lowercasedValue = value.toLowerCase();

        // Filtrer uniquement sur la colonne "Utilisateur" :
        const filtered = space.spaceUsedByUser.filter((user) =>
            user.name.toLowerCase().includes(lowercasedValue)
        );

        // Rechercher dans toutes les colonnes :
        // const filtered = space.spaceUsedByUser.filter((user) =>
        //     user.name.toLowerCase().includes(lowercasedValue) ||
        //     formatSize(user.spaceUsed).toLowerCase().includes(lowercasedValue) ||
        //     formatSize(user.quota).toLowerCase().includes(lowercasedValue)
        // );

        setFilteredData(filtered);
    };

    // Options pour le graphique circulaire (utilisateurs par rôle)
    const userRolesPieOptions = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'vertical',
            left: 'right',
        },
        series: [
            {
                name: 'Utilisateurs',
                type: 'pie',
                radius: ['40%', '70%'],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)',
                },
                data: [
                    { value: users.totalParents, name: 'Parents' },
                    { value: users.totalAssistantes, name: 'Assistantes' },
                    { value: users.totalGestionnaires, name: 'Gestionnaires' },
                    { value: users.totalAdmins, name: 'Admins' },
                ],
            },
        ],
    };

    // Options pour le graphique en barres (Contrats)
    const contractsBarOptions = {
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
        },
        xAxis: { type: 'category', data: ['Actifs', 'En attente', 'Terminés', 'Nouveaux'] },
        yAxis: { type: 'value' },
        series: [
            {
                data: [
                    contracts.active,
                    contracts.pending,
                    contracts.finished,
                    contracts.newLastMonth,
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: (params) => {
                        const colors = ['#4e89ff', '#ff7f50', '#ffc53d', '#36cfc9'];
                        return colors[params.dataIndex];
                    },
                },
                label: { show: true, position: 'top' },
            },
        ],
    };

    // Regrouper les utilisateurs par département
    const groupByDepartment = (data) => {
        const groupedData = {};

        data.forEach((item) => {
            const department = item.codePostal ? item.codePostal.slice(0, 2) : 'Non spécifié';
            if (!groupedData[department]) {
                groupedData[department] = { ROLE_PARENT: 0, ROLE_ASSISTANTE_MATERNELLE: 0, ROLE_GESTIONNAIRE: 0, ROLE_ADMIN: 0 };
            }
            groupedData[department][item.role] += item.userCount;
        });

        return groupedData;
    };

    // Convertir les données groupées pour le graphique
    const prepareChartData = (groupedData) => {
        const departments = Object.keys(groupedData).sort(); // Trier par ordre alphabétique des départements
        const parents = [];
        const assistantes = [];
        const gestionnaires = [];
        const admins = [];

        departments.forEach((dep) => {
            const counts = groupedData[dep];
            parents.push(counts.ROLE_PARENT);
            assistantes.push(counts.ROLE_ASSISTANTE_MATERNELLE);
            gestionnaires.push(counts.ROLE_GESTIONNAIRE);
            admins.push(counts.ROLE_ADMIN);
        });

        return { departments, parents, assistantes, gestionnaires, admins };
    };

    // Regrouper les utilisateurs
    const groupedData = groupByDepartment(stats.users.usersByRegion);
    const chartData = prepareChartData(groupedData);


    // Options pour les utilisateurs par rôle et localisation
    const userRegionsBarOptions = {
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
        },
        legend: {
            data: ['Parents', 'Assistantes', 'Gestionnaires', 'Admins'],
        },
        xAxis: {
            type: 'category',
            data: chartData.departments,
        },
        yAxis: { type: 'value' },
        series: [
            {
                name: 'Parents',
                type: 'bar',
                stack: 'total',
                data: chartData.parents,
            },
            {
                name: 'Assistantes',
                type: 'bar',
                stack: 'total',
                data: chartData.assistantes,
            },
            {
                name: 'Gestionnaires',
                type: 'bar',
                stack: 'total',
                data: chartData.gestionnaires,
            },
            {
                name: 'Admins',
                type: 'bar',
                stack: 'total',
                data: chartData.admins,
            },
        ],
    };

    // Configuration pour afficher la durée moyenne des contrats
    const averageContractDurationTag = `${contracts.averageAgeOfChildren.years} ans et ${contracts.averageAgeOfChildren.months} mois`;

    // Options pour les fichiers et répertoires ajoutés
    const filesBarOptions = {
        tooltip: { trigger: "axis" },
        legend: { data: ["Nouveaux la semaine dernière", "Total"] },
        xAxis: {
            type: "category",
            data: ["Fichiers", "Répertoires"], // Catégories pour l'axe X
        },
        yAxis: { type: "value" },
        series: [
            {
                name: "Nouveaux la semaine dernière",
                type: "bar",
                data: [files.newFilesLastWeek, files.newDirectoriesLastWeek], // Données pour les nouveaux
                barWidth: "40%",
                itemStyle: { color: "#36cfc9" },
                label: { show: true, position: "top" },
            },
            {
                name: "Total",
                type: "bar",
                data: [files.totalFiles, files.totalDirectories], // Données pour les totaux
                barWidth: "40%",
                itemStyle: { color: "#ff85c0" },
                label: { show: true, position: "top" },
            },
        ],
    };


    // Options pour les notifications par type
    const notificationsPieOptions = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'vertical',
            left: 'right',
        },
        series: [
            {
                name: 'Notifications',
                type: 'pie',
                radius: ['40%', '70%'],
                label: {
                    show: true,
                    formatter: '{b}: {c}',
                },
                data: notifications.byType.map((type) => ({
                    value: type.count,
                    name: type.type,
                })),
            },
        ],
    };

    const columns = [
        {
            title: 'Utilisateur',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Espace utilisé',
            dataIndex: 'spaceUsed',
            key: 'spaceUsed',
            render: (spaceUsed) => formatSize(spaceUsed),
            sorter: (a, b) => a.spaceUsed - b.spaceUsed,
        },
        {
            title: 'Quota alloué',
            dataIndex: 'quota',
            key: 'quota',
            render: (quota) => formatSize(quota),
            sorter: (a, b) => a.quota - b.quota,
        },
    ];

    // Données pour le tableau
    const tableData = space.spaceUsedByUser.map((user, index) => ({
        key: index,
        name: user.name,
        spaceUsed: user.spaceUsed,
        quota: user.quota,
    }));

    const formatSize = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const renderInfoUser= () => {
        if(!user)
            return <></>

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                alignContent: 'center',
                border: '1px solid #f0f0f0',
                borderRadius: '10px',
                backgroundColor: '#fafafa',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                height: '100%'
            }}
            >
                <div style={{fontSize: "1.2em", textAlign: "center"}}>
                    <MdManageAccounts className="icon" /> {user.nomComplet}
                </div>
               <p style={{ margin: '0', color: '#777', fontStyle: 'italic', fontSize: "1.2em" }}>Dernière connexion : {user.derniereConnexion}</p>
            </div>
        );
    }

    const formatDurationInMonths = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = Math.round(months % 12);
        return `${years > 0 ? `${years} an${years > 1 ? 's' : ''}` : ''}${years > 0 && remainingMonths > 0 ? ' et ' : ''}${remainingMonths > 0 ? `${remainingMonths} mois` : ''}`;
    };

    return (
        <>
            <Row gutter={[16,16]}>
                <Col xs={16} lg={14} xl={8}>
                    {renderInfoUser()}
                </Col>
                <Col xs={8} lg={10} xl={16}>
                    <Button
                        type="primary"
                        style={{
                            width: '100%',
                            borderRadius: '10px',
                            fontSize: '1.3em',
                            padding: '20px',
                            marginBottom: '10px',
                            height: '100%',
                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={handleRefresh}
                    >
                        <ReloadOutlined/> Rafraîchir
                    </Button>

                </Col>
            </Row>

            <div className="dashboard" style={{marginTop: 16}}>
                <Row gutter={[16, 16]}>
                    {/* Graphique Utilisateurs par rôle */}
                    <Col xs={24} lg={8}>
                        <Card title="Répartition des utilisateurs par rôle" style={{ borderRadius: 10, height: '100%' }}>
                            <ReactEcharts option={userRolesPieOptions} style={{ height: '25vh' }} />
                        </Card>
                    </Col>

                    {/* Graphique Utilisateurs par région et rôle */}
                    <Col xs={24} lg={8}>
                        <Card title="Utilisateurs par région et rôle" style={{ borderRadius: 10, height: '100%' }}>
                            <ReactEcharts option={userRegionsBarOptions} style={{ height: '25vh' }} />
                        </Card>
                    </Col>

                    <Col xs={24} lg={8}>
                        <Row gutter={[16, 16]} style={{ height: '100%' }}>
                            {/* Carte Utilisateurs */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%', cursor: "pointer" }} onClick={() => {navigate(ROUTES.UTILISATEURS)}}>
                                    <Statistic
                                        title="Total utilisateurs"
                                        value={users.total}
                                        prefix={<TeamOutlined/>}
                                    />
                                    <Progress
                                        percent={((users.active || users.total - users.inactiveUsersLastMonth) / users.total) * 100}
                                        size="small"
                                        strokeColor="#4e89ff"
                                        format={(percent) => `${percent.toFixed(2)}% actifs`}
                                        status="active"
                                    />
                                </Card>
                            </Col>

                            {/* Carte Nouveaux utilisateurs */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%', cursor: "pointer" }} onClick={() => {navigate(ROUTES.UTILISATEURS)}}>
                                    <Statistic
                                        title="Nouveaux utilisateurs (7 derniers jours)"
                                        value={users.newUsersLastWeek}
                                        prefix={<TeamOutlined />}
                                    />
                                    <Progress
                                        percent={(users.newUsersLastWeek / users.total) * 100}
                                        size="small"
                                        strokeColor="#52c41a"
                                        format={(percent) => `${percent.toFixed(2)}%`}
                                        status="active"
                                    />
                                </Card>
                            </Col>

                            {/* Carte Utilisateurs inactifs */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%', cursor: "pointer" }} onClick={() => {navigate(ROUTES.UTILISATEURS)}}>
                                    <Statistic
                                        title="Utilisateurs inactifs (30 derniers jours)"
                                        value={users.inactiveUsersLastMonth}
                                        prefix={<CloseOutlined />}
                                    />
                                    <Progress
                                        percent={(users.inactiveUsersLastMonth / users.total) * 100}
                                        size="small"
                                        strokeColor="#ff4d4f"
                                        format={(percent) => `${percent.toFixed(2)}%`}
                                        status="active"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{marginTop: 16}}>
                    {/* Graphique Contrats */}
                    <Col xs={24} lg={8}>
                        <Card title="Statistiques des contrats" style={{ borderRadius: 10, height: '100%' }}>
                            <ReactEcharts option={contractsBarOptions} style={{ height: '25vh' }} />
                        </Card>
                    </Col>

                    <Col xs={24} lg={8}>
                        <Row gutter={[16, 16]} style={{ height: '100%' }}>
                            {/* Carte Contrats */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%' }}>
                                    <Statistic
                                        title="Contrats actifs"
                                        value={contracts.active}
                                        prefix={<FileOutlined />}
                                    />
                                    <Progress
                                        percent={(contracts.active / (contracts.active + contracts.finished)) * 100}
                                        size="small"
                                        strokeColor="#4e89ff"
                                        format={(percent) => `${percent.toFixed(2)}%`}
                                        status="active"
                                    />
                                </Card>
                            </Col>

                            {/* Carte Âge moyen */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%' }}>
                                    <Statistic
                                        title="Âge moyen d'un enfant en contrat actif"
                                        value={averageContractDurationTag}
                                        prefix={<UserOutlined />}
                                    />
                                </Card>
                            </Col>

                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: "100%" }}>
                                    <Statistic
                                        title="Durée moyenne des contrats actifs"
                                        value={formatDurationInMonths(contracts.averageContractDuration)}
                                        prefix={<FileOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} lg={8}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: "100%" }}>
                                    <Statistic
                                        title="Nombre moyen de contrats par assistante"
                                        value={contracts.contractsPerAssistante}
                                        prefix={<ReconciliationOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{marginTop: 16}}>
                    {/* Graphique Notifications */}
                    <Col xs={24} lg={8}>
                        <Card title="Notifications par type" style={{ borderRadius: 10, height: '100%' }}>
                            <ReactEcharts option={notificationsPieOptions} style={{ height: '25vh' }} />
                        </Card>
                    </Col>

                    <Col xs={24} md={8}>
                        <Row gutter={[16, 16]}>
                            {/* Carte Notifications */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%' }}>
                                    <Statistic
                                        title="Notifications envoyées"
                                        value={notifications.sent}
                                        prefix={<NotificationOutlined />}
                                    />
                                    <Progress
                                        percent={(notifications.readUnread.readCount / (notifications.readUnread.unreadCount + notifications.readUnread.readCount)) * 100}
                                        size="small"
                                        status="active"
                                        format={(percent) => `${percent.toFixed(2)}%`}
                                        status="active"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{marginTop: 16}}>
                    {/* Graphique Fichiers et Répertoires */}
                    <Col xs={24} lg={8}>
                        <Card title="Fichiers et Répertoires ajoutés" style={{ borderRadius: 10, height: '100%' }}>
                            <ReactEcharts option={filesBarOptions} style={{ height: '35vh' }} />
                        </Card>
                    </Col>

                    {/* Tableau des espaces utilisés */}
                    <Col xs={24} lg={8}>
                        <Card title="Espace utilisé par utilisateur" style={{ borderRadius: 10, height: '100%' }}>
                            <Search
                                placeholder="Rechercher un utilisateur"
                                allowClear
                                enterButton="Rechercher"
                                size="large"
                                onSearch={handleSearch}
                                onChange={(e) => {
                                    handleSearch(e.target.value)
                                }}
                            />
                            <Table
                                columns={columns}
                                dataSource={filteredData}
                                pagination={{ pageSize: 5 }}
                                rowKey={(record) => record.userUuid}
                                rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "odd-row")}
                            />
                        </Card>
                    </Col>

                    <Col xs={24} lg={8}>
                        <Row gutter={[16, 16]}>
                            {/* Carte Taux d'utilisation de la plateforme */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%' }}>
                                    <Statistic
                                        title="Taux d'utilisation de la plateforme"
                                        value={`${platformUsageRate.toFixed(2)}%`}
                                        prefix={<CloudOutlined />}
                                    />
                                    <Progress
                                        percent={platformUsageRate}
                                        size="small"
                                        strokeColor="#36cfc9"
                                        format={(percent) => `${percent.toFixed(2)}%`}
                                        status="active"
                                    />
                                </Card>
                            </Col>

                            {/* Carte Espace Utilisé */}
                            <Col xs={24}>
                                <Card style={{ borderRadius: 10, height: '100%' }}>
                                    <Statistic
                                        title="Espace aloué par les quotas"
                                        value={`${formatSize(space.totalSpaceUsed)}`}
                                        prefix={<CloudOutlined />}
                                    />
                                    <Progress
                                        percent={(space.totalSpaceUsed / space.totalQuotaForAssistantes) * 100}
                                        size="small"
                                        strokeColor="#36cfc9"
                                        format={(percent) => `${percent.toFixed(2)}% utilisé`}
                                        status="active"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Dashboard;
