import React, { useState } from "react";
import { Card, Tooltip, Modal, Typography, Tag, Divider } from "antd";
import {
    EditOutlined,
    InfoCircleOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import ImpotsTab from "../ImpotsTab/ImpotsTab";

import "./ImpotsCard.css";

const { Title, Text } = Typography;

const ImpotsCard = ({ impots, selectedContrat, refresh, nomEnfant }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [statusModal, setStatusModal] = useState("");

    const showModal = (status) => {
        setIsModalVisible(true);
        setStatusModal(status);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="impots-card-container"
            >
                <Card
                    className={`impots-card ${impots.isDeclared ? "completed" : "pending"}`}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Title level={4} style={{ margin: 0 }}>
                                {`Année ${impots.annee}`}
                            </Title>
                            <Tooltip
                                title={impots.isDeclared ? "Déclaration validée" : "Déclaration en attente"}
                                placement="top"
                            >
                                {impots.isDeclared ? (
                                    <CheckCircleOutlined style={{ color: "green", fontSize: "20px" }} />
                                ) : (
                                    <ExclamationCircleOutlined
                                        style={{ color: "red", fontSize: "20px" }}
                                    />
                                )}
                            </Tooltip>
                        </div>
                    }
                    hoverable
                    actions={[
                        impots.isDeclared ? (
                            <Tooltip title="Supprimer la déclaration">
                                <DeleteOutlined
                                    style={{ color: "red" }}
                                    onClick={() => showModal("delete")}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Compléter la déclaration">
                                <EditOutlined
                                    style={{ color: "blue" }}
                                    onClick={() => showModal("edit")}
                                />
                            </Tooltip>
                        ),
                        <Tooltip title="Voir les détails">
                            <InfoCircleOutlined
                                style={{ color: "#1890ff" }}
                                onClick={() => showModal("info")}
                            />
                        </Tooltip>,
                    ]}
                >
                    <Text type="secondary">
                        {impots.isDeclared
                            ? "Les déclarations fiscales pour cette année sont complétées."
                            : "Les déclarations fiscales pour cette année doivent être complétées."}
                    </Text>
                    <Divider style={{ margin: "10px 0" }} />
                    <Tag color="blue">{nomEnfant}</Tag>
                </Card>
            </motion.div>

            <Modal
                title={`Tableau des impôts - ${impots.annee}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={statusModal === "delete" ? "30%" : "80%"}
            >
                <ImpotsTab
                    nomEnfant={nomEnfant}
                    refresh={refresh}
                    statusModal={statusModal}
                    impots={impots}
                    selectedContrat={selectedContrat}
                    year={impots.annee}
                />
            </Modal>
        </>
    );
};

export default ImpotsCard;
