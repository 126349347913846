import axios from "axios";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../Routes/Routes";

const setupAxiosInterceptors = (navigate, setHasAcceptedTerms, setLatestTermsVersion) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (
                error.response &&
                error.response.status === 403 &&
                error.response.data.message ===
                "Vous devez accepter les dernières CGU pour accéder à cette ressource."
            ) {
                setHasAcceptedTerms(false);
                setLatestTermsVersion("reload")
                navigate(ROUTES.TERMS);
            }
            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
