import React from 'react';
import {Col, Progress, Row, Tooltip, Typography} from 'antd';
import useIsMobile from '../../hooks/useIsMobile';
import './QuotaResponsiveProgress.css';
import useWindowSize from "../../hooks/useWindowsSize";
import {useAuth} from "../../context/AuthContext";

const { Text } = Typography;

const QuotaResponsiveProgress = ({ usedSpace, totalSpace, dynamicHeight = 100, addBackground = true, topLargestItems = []}) => {
    const { width } = useWindowSize();
    const isMobile = useIsMobile(992);
    const percentageUsed = Math.min((usedSpace / totalSpace) * 100, 100);
    const { user } = useAuth()
    const isAdmin = user.Roles.includes("ROLE_ADMIN");

    const getStrokeColor = (percentage) => {
        if (percentage >= 90) return '#ff4d4f';
        if (percentage >= 80) return '#faad14';
        return '#52c41a';
    };

    // Calcule dynamiquement la largeur du spinner
    const progressWidth = (() => {
        if (width > 2500) return 100;
        if (width > 1440) return 80;
        if (width > 1024) return 70;
        return 50;
    })();

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 MB";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes" ,"KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    // Contenu du tooltip
    const renderTooltipContent = () => {
        if (topLargestItems.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '10px 0' }}>
                    <Text strong>Aucun fichier ou répertoire volumineux détecté.</Text>
                </div>
            );
        }

        return (
            <div style={{ padding: '10px 0 5px 0' }}>
                <Text strong style={{ fontSize: '16px', display: 'block', marginBottom: '8px', textAlign: 'center' }}>
                    Top {topLargestItems.length} des plus volumineux
                </Text>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: 'max-content' }}>
                    {topLargestItems.map((item, index) => (
                        <Row
                            key={index}
                            style={{
                                padding: '8px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '6px',
                                backgroundColor: '#fafafa',
                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
                            }}>
                            <Col xs={15}>
                                { item.nom && (
                                    <Row>
                                        <Text strong style={{ fontSize: '14px', color: '#595959' }}>
                                            {item.nom}
                                        </Text>
                                    </Row>
                                )}
                                <Row>
                                    <Text type="secondary" style={{ fontSize: '13px' }}>
                                        {item.type}
                                    </Text>
                                </Row>
                            </Col>
                            <Col xs={9} style={{ textAlign: 'end'}}>
                                <Text style={{ fontSize: '14px', fontWeight: 'bold', color: '#4e89ff', margin: 'auto' }}>
                                    {formatBytes(item.taille)}
                                </Text>
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        );
    };

    const renderQuotaMobile = () => {
        return (
            <div className="quota-bar">
                <Text strong style={{display: 'block', marginBottom: '8px', fontSize: '14px', color: '#595959'}}>
                    Espace utilisé
                </Text>
                <Progress
                    percent={Number(percentageUsed.toFixed(2))}
                    strokeColor={getStrokeColor(percentageUsed)}
                    status={percentageUsed > 90 ? 'exception' : 'active'}
                    percentPosition={{align: 'center', type: 'inner'}}
                    size={['100%', 20]}
                />
                <Text style={{color: '#595959'}}>
                    {formatBytes(usedSpace)} / {formatBytes(totalSpace)}
                </Text>
            </div>
        )
    }

    const renderQuota = () => {
        return (
            <div className="quota-circle">
                <Progress
                    type="circle"
                    percent={Number(percentageUsed.toFixed(2))}
                    strokeWidth={10}
                    strokeColor={getStrokeColor(percentageUsed)}
                    status={percentageUsed > 90 ? 'exception' : 'active'}
                    format={(percent) => (
                        <span style={{fontWeight: 'bold'}}>
                                {percent.toFixed(0)}%
                            </span>
                    )}
                    size={progressWidth}
                />
                <Text style={{marginTop: '8px', color: '#595959'}}>
                    {formatBytes(usedSpace)} / {formatBytes(totalSpace)}
                </Text>
            </div>
        )
    }


    return (
        <div className={addBackground ? "quota-responsive-progress bckgrd" : "quota-responsive-progress"}
             style={!isMobile ? {height: `${dynamicHeight}px`} : {maxWidth: '62vw', margin: '5px auto 0 auto'}}
        >
            {!isAdmin ? (
                <Tooltip title={renderTooltipContent()} placement="bottom" color={"white"} fresh>
                    {isMobile ? renderQuotaMobile() : renderQuota()}
                </Tooltip>
            ) : (
                isMobile ? renderQuotaMobile() : renderQuota()
            )}
        </div>
    );
};

export default QuotaResponsiveProgress;
