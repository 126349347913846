import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {ROUTES} from "./Routes";

const RoleProtectedRoute = ({ element, allowedRoles }) => {
  const { isAuthenticated, user, hasAcceptedTerms } = useAuth();

  const hasRequiredRole = user && allowedRoles.some(role => user.Roles.includes(role));

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  if (!hasRequiredRole) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  if(!hasAcceptedTerms) {
    return <Navigate to={ROUTES.TERMS} replace />;
  }

  return element;
};

export default RoleProtectedRoute;
