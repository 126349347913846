import React, {useState} from "react";
import {Modal, Input, Upload, Button, Typography, message, Tooltip} from "antd";
import {EditOutlined, UploadOutlined, FormOutlined} from "@ant-design/icons";
import {EditFichier, EditRepertoire} from "../DocumentsApi";

const {Text} = Typography;

// Composant pour éditer un dossier
export const EditFolder = ({nodeData, token, forceUpdate, title = ""}) => {
    const {nom, key} = nodeData;

    const [isRenameModalVisible, setRenameModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState(nom);

    const handleRename = () => {
        if (!newFolderName.trim()) {
            message.error("Le nom du dossier ne peut pas être vide.");
            return;
        }

        EditRepertoire({
            nom: newFolderName,
            idFolder: key,
            token,
            forceUpdate,
        });

        setRenameModalVisible(false);
    };

    const handleCancel = () => {
        setRenameModalVisible(false);
    };

    const handleClick = () => {
        setRenameModalVisible(true);
        setNewFolderName(nom);
    };

    const handleInputChange = (e) => {
        setNewFolderName(e.target.value);
    };

    return (
        <>
            <Tooltip title="Éditer le répertoire" placement="top">
                {title !== "" ? (
                    <div onClick={handleClick}>
                        <EditOutlined style={{color: "#4E89FF"}}/>
                        <span style={{marginLeft: "8px"}}>{title}</span>
                    </div>
                ) : (
                    <EditOutlined style={{color: "#4E89FF"}} onClick={handleClick}/>
                )}
            </Tooltip>

            <Modal
                title={
                    <span>
                        <FormOutlined style={{color: "#4E89FF", marginRight: "8px"}}/>
                        Renommer le dossier
                    </span>
                }
                open={isRenameModalVisible}
                onOk={handleRename}
                onCancel={handleCancel}
                maskClosable={false}
                okText="Renommer"
                cancelText="Annuler"
                okButtonProps={{type: "primary"}}
            >
                <p>
                    Voulez-vous vraiment renommer le dossier <Text strong>"{nom}"</Text> ?
                </p>
                <Input
                    value={newFolderName}
                    onChange={handleInputChange}
                    placeholder="Nouveau nom du dossier"
                    maxLength={255}
                    showCount
                    allowClear
                />
            </Modal>
        </>
    );
};

// Composant pour éditer un fichier
export const EditFile = ({nom, id, token, forceUpdate, title = ""}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [newFileName, setNewFileName] = useState(nom.replace(/\..+$/, "")); // Retirer l'extension

    const showModal = () => {
        setIsModalVisible(true);
        setNewFileName(nom.replace(/\..+$/, ""));
    };

    const handleRename = () => {
        if (!newFileName.trim()) {
            message.error("Le nom du fichier ne peut pas être vide.");
            return;
        }

        EditFichier({
            nom: newFileName,
            idFile: id,
            file: fileList[0],
            token,
            forceUpdate,
        });

        setFileList([]);
        setNewFileName("");
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setFileList([]);
        setNewFileName("");
        setIsModalVisible(false);
    };

    const handleInputChange = (e) => {
        setNewFileName(e.target.value);
    };

    const onFileChange = (info) => {
        const uploadedFile = info.file.originFileObj;
        setFileList([info.file]);

        if (info.file.status === "done") {
            message.success(`${info.file.name} téléchargé avec succès.`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} échec du téléchargement.`);
        }
    };

    return (
        <>
            <Tooltip title="Éditer le fichier" placement="top">
                {title !== "" ? (
                    <div onClick={showModal}>
                        <EditOutlined style={{color: "#4E89FF"}}/>
                        <span style={{marginLeft: "8px"}}>{title}</span>
                    </div>
                ) : (
                    <EditOutlined style={{color: "#4E89FF"}} onClick={showModal}/>
                )}
            </Tooltip>

            <Modal
                title={
                    <span>
                        <FormOutlined style={{color: "#4E89FF", marginRight: "8px"}}/>
                        Modifier le fichier
                    </span>
                }
                open={isModalVisible}
                onOk={handleRename}
                onCancel={handleCancel}
                maskClosable={false}
                okText="Enregistrer"
                cancelText="Annuler"
                okButtonProps={{type: "primary"}}
            >
                <p>
                    Voulez-vous vraiment modifier le fichier <Text strong>"{nom}"</Text> ?
                </p>
                <Input
                    value={newFileName}
                    onChange={handleInputChange}
                    placeholder="Nouveau nom du fichier"
                    allowClear
                    maxLength={255}
                    showCount
                />
                <br/>
                <br/>
                <p>Sélectionnez un nouveau fichier pour remplacer l'existant :</p>
                <Upload.Dragger
                    onChange={onFileChange}
                    fileList={fileList}
                    beforeUpload={() => false}
                    disabled={false}
                >
                    <p className="ant-upload-drag-icon">
                        <UploadOutlined/>
                    </p>
                    <p className="ant-upload-text">Cliquez ou glissez-déposez un fichier ici</p>
                </Upload.Dragger>
            </Modal>
        </>
    );
};
