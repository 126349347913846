import React, { useState, useEffect } from 'react';
import { Upload, Button, List, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {useLocation, useParams} from 'react-router-dom'; // Importer useParams

import { useDocuments } from '../../../context/DocumentsContext';



import ArborescenceDocuments from "../../../components/Documents/ArborescenceDocuments";


import '../DocumentsGlobal.css';
import Loading from '../../../components/Loading/Loading';
import FileExplorer from "../../../components/Documents/FileExplorer";
import NoDocuments from "../NoDocuments";
import {useAuth} from "../../../context/AuthContext";

const DocumentsParents = () => {
    const location = useLocation();
    const color = location.state?.color || "#4e89ff";

    const { user } = useAuth();
    const { documents, forceUpdate, quota, error } = useDocuments();
    const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");

    const identifiant = +window.location.href.split('/')[window.location.href.split('/').length - 1]

    if (error) {
        return (
            <Row justify="center" gutter={[16, 16]}>
                <Col xs={24} md={18} lg={14} xl={10}>
                    <NoDocuments
                        isAssistantMaternel={isAssistantMaternel}
                        context="contrat"
                        message={error}
                        subMessage={"Essayez de recharger ou contactez le support si le problème persiste."}
                        forceUpdate={forceUpdate}
                    />
                </Col>
            </Row>
        );
    }

    // Trouver le répertoire en fonction du type de données dans `documents`
    let repertoireGlobal;
    if (Array.isArray(documents)) {
        // Si `documents` est un tableau (cas des parents)
        repertoireGlobal = documents
            .flatMap(doc => doc.arborescence.contient)
            .find(item => item.id === identifiant);
    } else if (documents?.contient) {
        // Si `documents` est un objet avec une propriété `contient` (cas des assistantes maternelles)
        repertoireGlobal = documents.contient.find(item => item.id === identifiant);
    }

    // Combiner dossiers et fichiers en une seule liste
    const combinedData = [
        ...(repertoireGlobal?.contient || []), // Dossiers
        ...(repertoireGlobal?.fichiers || [])  // Fichiers
    ];

    if(!documents) {
        return <Loading />;
    }
    else if (!repertoireGlobal) {
        return (
            <Row justify="center" gutter={[16, 16]}>
                <Col xs={24} md={18} lg={14} xl={10}>
                    <NoDocuments isAssistantMaternel={isAssistantMaternel} context={"contrat"} forceUpdate={forceUpdate} />;
                </Col>
            </Row>
        )
    }

    return (
        <div className="documentContent">
            <Row justify="space-around" align="middle">
                <Col xs={24} sm={24} md={24} lg={22} xl={22} className="documentsCol">
                    {/* Utilisation de FileExplorer pour afficher les fichiers et dossiers du répertoire global */}
                    <FileExplorer
                        data={combinedData}
                        rootFolderName={repertoireGlobal["nom du répertoire"]}
                        rootFolderId={repertoireGlobal.id}
                        forceUpdate={forceUpdate}
                        rootSize={isAssistantMaternel ? repertoireGlobal.taille : 0}
                        quota={quota}
                        highlightColor={color}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default DocumentsParents;
