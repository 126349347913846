import { useState, useEffect } from 'react';

const useIsMobile = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

    const handleResizeOrOrientationChange = () => {
        setIsMobile(window.innerWidth <= breakpoint);
    };

    useEffect(() => {
        // Écoute des événements 'resize' et 'orientationchange'
        window.addEventListener('resize', handleResizeOrOrientationChange);
        window.addEventListener('orientationchange', handleResizeOrOrientationChange);

        // Nettoyage lors du démontage
        return () => {
            window.removeEventListener('resize', handleResizeOrOrientationChange);
            window.removeEventListener('orientationchange', handleResizeOrOrientationChange);
        };
    }, [breakpoint]);

    return isMobile;
};

export default useIsMobile;
