import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";

const ResumeAbscence = () => {
  const { token, user } = useAuth();
  const [absenceData, setAbsenceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.uama.fr/v1.0/planning/absence-contrat", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Transform data based on structure
        const transformedData = transformData(data, user.Roles);
        setAbsenceData(transformedData);
      } catch (error) {
        console.error("Error fetching absence data:", error);
      }
    };

    fetchData();
  }, [token, user.Roles]);

  // Function to transform data into a consistent structure
  const transformData = (data, roles) => {
    // If the user is a parent (data contains "Assistante maternelle")
    if (roles.includes("ROLE_PARENT") && data[0]?.["Assistante maternelle"]) {
      return data.flatMap((entry) =>
          entry.Enfants.map((enfant) => ({
            AssistanteMaternelle: entry["Assistante maternelle"],
            Enfant: enfant.Nom,
            "Date début": enfant.Absences["Date début"],
            "Date fin": enfant.Absences["Date fin"],
          }))
      );
    }

    // Default for other roles (e.g., assistante maternelle)
    return data.flatMap((entry) =>
        entry.Absences.map((absence) => ({
          Enfant: entry.Enfant,
          "Date début": absence["Date début"],
          "Date fin": absence["Date fin"],
        }))
    );
  };

  const allColumns = [
    {
      title: "Assistante maternelle",
      dataIndex: "AssistanteMaternelle",
      key: "AssistanteMaternelle",
      render: (text) => text || "-",
    },
    {
      title: "Enfant",
      dataIndex: "Enfant",
      key: "Enfant",
    },
    {
      title: "Date début",
      dataIndex: "Date début",
      key: "Date début",
    },
    {
      title: "Date fin",
      dataIndex: "Date fin",
      key: "Date fin",
    },
  ];

  // Filter columns based on role
  const columnsToShow = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")
      ? allColumns.filter((col) => col.key !== "AssistanteMaternelle")
      : allColumns;

  return (
      <div className="ResumeAbscence">
        <Table
            columns={columnsToShow}
            dataSource={absenceData}
            pagination={{ pageSize: 3 }}
            size={"small"}
            bordered
            rowKey={(record) => `${record.Enfant}-${record["Date début"]}-${record.AssistanteMaternelle || ""}`}
            locale={{
              emptyText: (
                  <div style={{ textAlign: 'center' }}>
                    <p style={{ color: '#888' }}>Aucune absence prévue pour le moment.</p>
                  </div>
              ),
            }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        />
      </div>
  );
};

export default ResumeAbscence;
