// src/routes.js

import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import RoleProtectedRoute from "./RoleProtectedRoute";
import HomePage from "../view/HomePage/HomePage";
import PlanningPage from "../view/PlanningPage/PlanningPage";
import ImpotsPage from "../view/ImpotsPage/ImpotsPage";
import DocumentsPage from "../view/DocumentsPage/DocumentsPage";
import LogoutPage from "../view/Auth/LogoutPage/LogoutPage";
import ForgotPasswordPasswordForm from "../components/Auth/ForgotPassword/ForgotPasswordPasswordForm/ForgotPasswordPasswordForm";
import LoginPage from "../view/Auth/LoginPage/LoginPage";
import ForgotPassword from "../view/Auth/ForgotPassword/ForgotPassword";
import SignInAsmat from "../view/Auth/SignInPage/SignInAsmat/SignInAsmat";
import SignInParent from "../view/Auth/SignInPage/SignInParent/SignInParent";
import DocumentsAll from "../view/DocumentsPage/DocumentsAll/DocumentsAll";
import DocumentsPerso from "../view/DocumentsPage/DocumentsPerso/DocumentsPerso";
import DocumentsParents from "../view/DocumentsPage/DocumentsParents/DocumentsParents";
import EditProfil from "../view/Auth/EditProfil/EditProfil";
import ContratsPage from "../view/ContratsPage/ContratsPage";
import Page404 from "../components/Page404/Page404";
import UsersPage from "../components/Admin/Users/UsersPage";
import TermsPage from "../view/TermsPage/TermsPage";
import Main from "../Main";
import HoursWorkedPage from "../view/HoursWorkedPage/HoursWorkedPage";

export const ROUTES = {
  HOME: "/",
  IMPOTS: "/taxManagement",
  PLANNING: "/planning",
  CONTRATS: "/agreements",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgotPassword/:id",
  SIGNIN_ASMAT: "/signin/asmat",
  SIGNIN_PARENT: "/signin/parent/:idParent",
  EDIT_PROFILE: "/editProfil",
  DOCUMENTS: "/documents",
  DOCUMENTS_ALL: "/documents/global",
  DOCUMENTS_PERSO: "/documents/personal",
  DOCUMENTS_PARENTS: "/documents/parents/:idRepertoire",
  UTILISATEURS: "/admin/users",
  TERMS: "/terms",
  HOURS_WORKED: "/hoursWorked",
  NOT_FOUND: "*",
};

const renderWithMain = (element) => {
  return <Main>{element}</Main>;
};

const Routes = [
  {
    path: ROUTES.HOME,
    element: renderWithMain(<ProtectedRoute element={<HomePage />} />),
  },
  {
    path: ROUTES.IMPOTS,
    element: renderWithMain(<RoleProtectedRoute element={<ImpotsPage />} allowedRoles={['ROLE_ASSISTANTE_MATERNELLE']} />),
  },
  {
    path: ROUTES.HOURS_WORKED,
    element: renderWithMain(<RoleProtectedRoute element={<HoursWorkedPage />} allowedRoles={['ROLE_ASSISTANTE_MATERNELLE']} />),
  },
  {
    path: ROUTES.PLANNING,
    element: renderWithMain(<ProtectedRoute element={<PlanningPage />} />),
  },
  {
    path: ROUTES.CONTRATS,
    element: renderWithMain(<ProtectedRoute element={<ContratsPage />} />),
  },
  {
    path: ROUTES.LOGIN,
    element: renderWithMain(<LoginPage />),
  },
  {
    path: ROUTES.LOGOUT,
    element: renderWithMain(<LogoutPage />),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: renderWithMain(<ForgotPassword />),
  },
  {
    path: ROUTES.SIGNIN_ASMAT,
    element: renderWithMain(<SignInAsmat />),
  },
  {
    path: ROUTES.SIGNIN_PARENT,
    element: renderWithMain(<SignInParent />),
  },
  {
    path: ROUTES.EDIT_PROFILE,
    element: renderWithMain(<ProtectedRoute element={<EditProfil />} />),
  },
  {
    path: ROUTES.DOCUMENTS,
    element: renderWithMain(<ProtectedRoute element={<DocumentsPage />} />),
  },
  {
    path: ROUTES.DOCUMENTS_ALL,
    element: renderWithMain(<ProtectedRoute element={<DocumentsAll />} />),
  },
  {
    path: ROUTES.DOCUMENTS_PERSO,
    element: renderWithMain(<ProtectedRoute element={<DocumentsPerso />} />),
  },
  {
    path: ROUTES.DOCUMENTS_PARENTS,
    element: renderWithMain(<ProtectedRoute element={<DocumentsParents />} />),
  },
  {
    path: ROUTES.UTILISATEURS,
    element: renderWithMain(<RoleProtectedRoute element={<UsersPage />} allowedRoles={["ROLE_ADMIN"]} />),
  },
  {
    path: ROUTES.NOT_FOUND,
    element: renderWithMain(<Page404 />),
  },
  {
    path: ROUTES.TERMS,
    element : renderWithMain(<TermsPage />)
  }
];

export default Routes;
