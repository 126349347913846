import { FolderAddOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {Modal, Input, Button, Typography, Alert} from "antd";

const { Text } = Typography;

const AddFolder = ({ folderParentName, parentId, token, forceUpdate, title = "" }) => {
  const { AddRepertoire } = require("../DocumentsApi.js");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderName, setFolderName] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddFolder = () => {
    if (!folderName.trim()) {
      // Afficher une erreur si le nom du dossier est vide
      Modal.error({
        title: "Erreur",
        content: "Le nom du dossier ne peut pas être vide.",
      });
      return;
    }

    AddRepertoire({
      nom: folderName.trim(),
      parentId: parentId,
      token: token,
      forceUpdate: forceUpdate,
    });

    setFolderName("");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setFolderName("");
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    setFolderName(e.target.value);
  };

  return (
      <>
        <Button type={"default"} icon={<FolderAddOutlined />} onClick={showModal} className="btn-add-folder">
          {title}
        </Button>

        <Modal
            title={
              <span>
                <PlusOutlined style={{color: "#4caf50", marginRight: "8px"}}/>
                Ajouter un dossier
              </span>
            }
            open={isModalVisible}
            onOk={handleAddFolder}
            onCancel={handleCancel}
            maskClosable={false}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Annuler
              </Button>,
              <Button key="add" type="primary" icon={<FolderAddOutlined/>} onClick={handleAddFolder}>
                Ajouter
              </Button>,
            ]}
        >
          <br/>
          <Alert
              description={
                <Text>Vous allez ajouter un répertoire dans le répertoire <Text
                    strong>{folderParentName || ""}</Text></Text>
              }
              type="info"
              showIcon
          />
          <br/>
          <Text>Merci de saisir le nom du dossier à ajouter :</Text>
          <Input
              value={folderName}
              onChange={handleInputChange}
              placeholder="Nom du dossier"
              maxLength={255}
              showCount
              style={{marginTop: "10px"}}
          />
        </Modal>
      </>
  );
};

export default AddFolder;
