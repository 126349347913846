import React from 'react';
import { Progress } from 'antd';

const PasswordStrengthIndicator = ({ passwordStrength, isStrongEnough, missingCriteria }) => {
    const getColor = () => {
        if (!isStrongEnough) return "#ff4d4f";
        if (passwordStrength < 50) return "#ff4d4f";
        if (passwordStrength < 75) return "#faad14";
        return "#52c41a";
    };

    const getText = () => {
        if (!isStrongEnough && missingCriteria.length > 0) {
            return `Faible - Le mot de passe doit contenir : ${missingCriteria.join(", ")}`;
        }
        if (passwordStrength < 50) return "Faible";
        if (passwordStrength < 75) return "Moyen";
        return "Fort";
    };

    return (
        <div className="password-strength">
            <Progress
                percent={passwordStrength}
                showInfo={false}
                strokeColor={getColor()}
                status="active"
            />
            <p style={{ color: getColor() }}>
                {getText()}
            </p>
        </div>
    );
};

export default PasswordStrengthIndicator;
