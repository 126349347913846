import React, {useState} from "react";
import {
    Form,
    Input,
    DatePicker,
    Button,
    Steps,
    Divider,
    message,
    Alert, Progress, AutoComplete, Checkbox,
} from "antd";
import "../SignInPage.css";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import logo from "../../../../assets/logo.png";
import {HomeOutlined, InfoCircleOutlined, UserOutlined, FileTextOutlined} from "@ant-design/icons";

import {usePasswordStrength} from "../../../../utils/passwordUtils";
import PasswordStrengthIndicator from "../../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator";
import {ROUTES} from "../../../../Routes/Routes";
import { fetchCitiesFromGeoAPI, fetchZipCodesFromGeoAPI} from "../../../../utils/cityAndZipCodeUtils";
import useAddClassOnPath from "../../../../hooks/useAddClassOnPath";

moment.locale("fr");

const {Item} = Form;
const {Step} = Steps;

const SignInPage = () => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const {passwordStrength, isStrongEnough, missingCriteria, calculatePasswordStrength} = usePasswordStrength();
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    useAddClassOnPath("bodyContent", "file-explorer-active", ROUTES.SIGNIN_PARENT);

    // FormData pour stocker les valeurs des formulaires pour les différentes pages.
    const handleNext = () => {
        form
            .validateFields()
            .then((values) => {
                setFormData({...formData, ...values}); // Merge values into formData
                setCurrentStep(currentStep + 1);
            })
            .catch((error) => {
                console.log("Validation Error:", error);
                message.error("Veuillez remplir correctement tous les champs.");
            });
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await onFinish(values);
        } catch (error) {
            message.error("Erreur lors de la soumission du formulaire.");
        }
    };

    // Soumission du formulaire d'inscription
    const onFinish = async (values) => {
        setLoading(true); // Activer le chargement
        try {
            const response = await axios.post(
                "https://api.uama.fr/auth/inscription/assistanteMaternelle",
                {
                    dateFinAgrement: values.dateFinAgrement.format("YYYY-MM-DD"),
                    dateFinAssuranceHabitation:
                        values.dateFinAssuranceHabitation.format("YYYY-MM-DD"),
                    dateFinAssuranceVoiture:
                        values.dateFinAssuranceVoiture.format("YYYY-MM-DD"),
                    email: formData.email,
                    numeroTelephone: formData.numeroTelephone,
                    dateNaissance: formData.dateNaissance.format("YYYY-MM-DD"),
                    password: formData.password,
                    prenom: formData.prenom,
                    nom: formData.nom,
                    adresse: formData.adresse,
                    codePostal: formData.codePostal,
                    ville: formData.ville,
                    pays: "FRANCE",
                    joursTravailles: values.joursTravailles,
                    receiveNotification: `${formData.receiveNotification}`,
                }
            );
            console.log("API Response:", response.data);
            message.success("Inscription réussie");
            navigate("/");
        } catch (error) {
            console.error("API Error:", error.response.data.message);
            message.error(error.response.data.message);
        } finally {
            setLoading(false); // Désactiver le chargement
        }
    };

    // Fonction de recherche pour les villes et les codes postaux
    const handleSearch = async (value) => {
        if (!value) {
            setOptions([]);
            return;
        }

        const isZipCode = /^\d+$/.test(value); // Vérifie si c'est un code postal
        let results = [];

        if (isZipCode) {
            results = await fetchZipCodesFromGeoAPI(value); // Recherche par code postal
        } else {
            results = await fetchCitiesFromGeoAPI(value); // Recherche par ville
        }

        setOptions(
            results.map((item) => ({
                value: `${item.ville}, ${item.codePostal}`,
                label: `${item.ville} (${item.codePostal})`,
                ville: item.ville,
                codePostal: item.codePostal,
            }))
        );
    };

    // Fonction pour remplir automatiquement les champs après sélection d'une ville
    const handleSelect = (value, option) => {
        form.setFieldsValue({
            ville: option.ville,
            codePostal: option.codePostal,
        });
    };

    return (
        <div className="signIn-container">
            <div className="signIn-wrapper">
                <div className="signIn-header">
                    <img src={logo} alt="Logo UAMA" className="signIn-logo"/>
                    <h1>UAMA</h1>
                    <p className="login-fullname" style={{fontSize: "0.9rem", color: "#888"}}>
                        Univers des Assistantes Maternelles Agrées
                    </p>
                    <p className="signIn-description">
                        Rejoignez UAMA et simplifiez vos tâches administratives en tant qu'assistante maternelle
                    </p>
                    <Link to={ROUTES.TERMS}>Conditions générales d'utilisation</Link>
                </div>

                <Alert
                    description={
                        <span
                            dangerouslySetInnerHTML={{__html: "<b>Attention</b> : Cette page est destinée aux assistantes maternelles uniquement !"}}/>
                    }
                    type="warning"
                    showIcon
                    className="alert-Asmat"
                />

                <Form
                    form={form}
                    className="signIn-form"
                    onFinish={onFinish}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 24}}
                >
                    {/* Etape 1: Identifiants */}
                    {currentStep === 0 && (
                        <>
                            <Form.Item
                                name="email"
                                label="Email"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                    {
                                        type: "email",
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                ]}
                            >
                                <Input type="email" autoComplete="email"/>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Mot de passe"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            // Vérifie si le champ est vide avant de vérifier la force
                                            if (!value) {
                                                return Promise.reject(new Error("Merci de saisir un mot de passe"));
                                            }

                                            calculatePasswordStrength(value);

                                            if (isStrongEnough) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Le mot de passe n'est pas assez fort"));
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(e) => calculatePasswordStrength(e.target.value)}
                                />
                            </Form.Item>

                            {/* Indicateur de force du mot de passe */}
                            <PasswordStrengthIndicator
                                passwordStrength={passwordStrength}
                                isStrongEnough={isStrongEnough}
                                missingCriteria={missingCriteria}
                            />

                            <Form.Item
                                name="passwordConfirmation"
                                label="Confirmation du mot de passe"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de confirmer votre mot de passe",
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Les mots de passe ne correspondent pas ")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password type="password" autoComplete="new-password"/>
                            </Form.Item>

                            <Form.Item className="checkboxNotif" name="receiveNotification" valuePropName="checked">
                                <Checkbox>Recevoir des notifications</Checkbox>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 2: Informations */}
                    {currentStep === 1 && (
                        <>
                            <Form.Item
                                name="nom"
                                label="Nom"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un nom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="family-name"/>
                            </Form.Item>
                            <Form.Item
                                name="prenom"
                                label="Prénom"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un prénom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="given-name"/>
                            </Form.Item>
                            <Form.Item
                                name="numeroTelephone"
                                label="Numéro de téléphone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un numéro de téléphone.",
                                    },
                                    {
                                        pattern: /^(0\d{9}|(\+?[1-9]\d{0,2}[-.\s]?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}))$/,
                                        message: "Merci de saisir un numéro de téléphone valide. Exemple : +33 6 12 34 56 78 ou 06 12 34 56 78.",
                                    },
                                ]}
                            >
                                <Input type="tel" autoComplete="tel" />
                            </Form.Item>


                            <Form.Item
                                name="dateNaissance"
                                label="Date de naissance"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une date de naissance",
                                    },
                                ]}
                            >
                                <DatePicker style={{width: "100%"}} format={dateFormats}/>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 3: Adresse */}
                    {currentStep === 2 && (
                        <>
                            <Form.Item
                                name="adresse"
                                label="Adresse"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="street-address"/>
                            </Form.Item>

                            <Form.Item
                                name="codePostal"
                                label="Code postal"
                                rules={[
                                    { required: true, message: "Merci de saisir un code postal" },
                                    { pattern: /^\d{5}$/, message: "Le code postal doit être composé de 5 chiffres" },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez un code postal"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ codePostal: value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="ville"
                                label="Ville"
                                rules={[{ required: true, message: "Merci de saisir une ville" }]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez une ville"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ ville: value });
                                    }}
                                />
                            </Form.Item>

                        </>
                    )}
                    {/* Etape 4: Administratif */}
                    {currentStep === 3 && (
                        <>
                            <Form.Item
                                name="dateFinAgrement"
                                label="Date de fin d'agrement"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une date de fin d'agrement",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>
                            <Form.Item
                                name="dateFinAssuranceHabitation"
                                label="Date de fin d'assurance habitation"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Merci de saisir une date de fin d'assurance habitation",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>
                            <Form.Item
                                name="dateFinAssuranceVoiture"
                                label="Date de fin d'assurance voiture"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Merci de saisir une date de fin d'assurance voiture",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>

                            <Form.Item
                                name="joursTravailles"
                                label="Jours travaillés"
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de sélectionner les jours travaillés",
                                    },
                                ]}
                            >
                                <Checkbox.Group>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Checkbox value="1">Lundi</Checkbox>
                                        <Checkbox value="2">Mardi</Checkbox>
                                        <Checkbox value="3">Mercredi</Checkbox>
                                        <Checkbox value="4">Jeudi</Checkbox>
                                        <Checkbox value="5">Vendredi</Checkbox>
                                        <Checkbox value="6">Samedi</Checkbox>
                                        <Checkbox value="7">Dimanche</Checkbox>
                                        <Checkbox value="0">Jours fériés</Checkbox>
                                    </div>
                                </Checkbox.Group>
                            </Form.Item>
                        </>
                    )}

                    <Item wrapperCol={{offset: 8, span: 16}}>
                        {currentStep === 0 && (
                            <Link to={ROUTES.LOGIN} style={{margin: "0 8px"}}>
                                Se connecter
                            </Link>
                        )}
                        {currentStep > 0 && (
                            <Button style={{margin: "0 8px"}} onClick={handlePrev}>
                                Précédent
                            </Button>
                        )}
                        {currentStep < 3 && (
                            <Button type="primary" onClick={handleNext}>
                                Suivant
                            </Button>
                        )}
                        {currentStep === 3 && (
                            <Button type="primary" onClick={handleSubmit} loading={loading}>
                                S'inscrire
                            </Button>
                        )}
                    </Item>

                    <Divider/>

                    <Steps current={currentStep}>
                        <Step title="Identifiants" icon={<UserOutlined/>}/>
                        <Step title="Informations" icon={<InfoCircleOutlined/>}/>
                        <Step title="Adresse" icon={<HomeOutlined/>}/>
                        <Step title="Administratif" icon={<FileTextOutlined/>}/>
                    </Steps>
                </Form>
            </div>
        </div>
    );
};

export default SignInPage;
