import React, { useState, useEffect } from "react";
import {
    Table,
    Button,
    Modal,
    Card,
    Typography,
    Tag,
    Space,
    Tooltip,
    message,
    Descriptions,
    Row,
    Col, Divider, Spin, Input, Statistic, Progress
} from "antd";
import {EditOutlined, DeleteOutlined, EyeOutlined, CloseOutlined, CheckOutlined, TeamOutlined} from "@ant-design/icons";
import { useAuth } from "../../../context/AuthContext";
import './UsersPage.css'
import QuotaResponsiveProgress from "../../Documents/QuotaResponsiveProgress";
import { debounce } from "lodash";
import Highlighter from "react-highlight-words";

const {Search} = Input;
const { Title } = Typography;

const UsersPage = () => {
    const { token } = useAuth();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingStat, setLoadingStat] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [quotaData, setQuotaData] = useState(null);
    const [quotaLoading, setQuotaLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [stats, setStats] = useState({});

    const [searchText, setSearchText] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);

    // Charger les utilisateurs
    const fetchUsers = async () => {
        try {
            const response = await fetch("https://api.uama.fr/v1.0/admin/users?limit=inf", {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            setUsers(data.users);
            setFilteredUsers(data.users)
            setLoading(false);
        } catch (error) {
            console.error("Erreur lors de la récupération des utilisateurs :", error);
            setLoading(false);
        }
    };

    // Fonction pour récupérer les statistiques depuis l'API
    const fetchStats = async () => {
        console.log("Start fetching stats ....")
        try {
            const response = await fetch("https://api.uama.fr/v1.0/admin/stats", {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            setStats(data.users);
            setLoadingStat(false);
        } catch (error) {
            console.error("Erreur lors de la récupération des statistiques :", error);
            setLoadingStat(false);
        }
    };

    useEffect(() => {
        fetchStats();
        fetchUsers();
    }, [token]);

    // Charger le quota de l'utilisateur
    const fetchQuota = async (uuid) => {
        setQuotaLoading(true);
        try {
            const response = await fetch(`https://api.uama.fr/v1.0/admin/user/quota/${uuid}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || "Erreur inconnue");
            }
            const data = await response.json();
            setQuotaData(data);
        } catch (error) {
            message.error("QUOTA  : " + error.message || "Erreur lors de la récupération du quota");
        }
        setQuotaLoading(false);
    };

    // Ouvrir le modal avec les détails de l'utilisateur
    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setModalVisible(true);
        if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
            fetchQuota(user.UUID);
        }
    };

    // Fermer le modal
    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedUser(null);
    };

    // Actions pour gérer les utilisateurs
    const handleEditUser = (user) => {
        console.log("Éditer l'utilisateur :", user);
        // Logique d'édition ici (redirection ou modal d'édition)
    };

    const handleDeleteUser = async (uuid, action) => {
        try {
            const response = await fetch(`https://api.uama.fr/v1.0/admin/user/delete/${uuid}?action=${action}`, {
                method: "DELETE",
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            if (response.ok) {
                message.success(data.message);
                fetchUsers();
                handleCloseModal();
            } else {
                console.error("Erreur lors de la suppression/désactivation :", await response.json());
            }
        } catch (error) {
            console.error("Erreur :", error);
        }
    };

    const formatSize = (sizeInMB) => {
        if (!sizeInMB) return "0 MB";
        if (sizeInMB >= 1024) return `${(sizeInMB / 1024).toFixed(2)} GB`;
        if (sizeInMB < 1) return `${(sizeInMB * 1024).toFixed(2)} KB`;
        return `${sizeInMB.toFixed(2)} MB`;
    };

    const columns = [
        {
            title: "Nom complet",
            dataIndex: "Nom complet",
            key: "Nom complet",
            sorter: (a, b) => a["Nom complet"].localeCompare(b["Nom complet"]),
            render: (text) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || ""}
                />
            ),
        },
        {
            title: "Adresse email",
            dataIndex: "Email",
            key: "Email",
            responsive: ["xl"],
            sorter: (a, b) => a["Email"].localeCompare(b["Email"]),
            render: (text) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || ""}
                />
            )
        },
        {
            title: "Rôle",
            dataIndex: "Roles",
            key: "Roles",
            responsive: ["md"],
            render: (roles) => (
                <Space>
                    {roles
                        .filter((role) => role !== "ROLE_USER")
                        .map((role, index) => (
                            <Tag key={index} color="blue">
                                {role.replace("ROLE_", "").replace("_", " ").toUpperCase()}
                            </Tag>
                        ))}
                </Space>
            ),
        },
        {
            title: "Statut du compte",
            dataIndex: "Statut du compte",
            key: "Statut du compte",
            responsive: ["xl"],
            sorter: (a, b) => a["Statut du compte"].localeCompare(b["Statut du compte"]),
            render: (status) => (
                <Tag color={status === "Actif" ? "green" : "red"}>{status.toUpperCase()}</Tag>
            ),
        },
        {
            title: "Dernière connexion",
            dataIndex: "Dernière connexion",
            key: "Dernière connexion",
            responsive: ["xl"],
            sorter: (a, b) => {
                const parseDate = (dateString) => {
                    if (!dateString)
                        return new Date(0);
                    const [day, month, year, time] = dateString.split(/[/ ]/);
                    return new Date(`${year}-${month}-${day}T${time}`);
                };

                return parseDate(a["Dernière connexion"]) - parseDate(b["Dernière connexion"]);
            },
            render: (date) => (date ? date : "Jamais connecté"),
        },
        {
            title: "Recevoir notifications",
            dataIndex: "Recevoir des notifications",
            key: "Recevoir des notifications",
            responsive: ["xl"],
            sorter: (a, b) => (a["Recevoir des notifications"] === b["Recevoir des notifications"]
                ? 0
                : a["Recevoir des notifications"]
                    ? -1
                    : 1),
            render: (value) => (
                <Tag color={value ? "green" : "red"}>{value ? "OUI" : "NON"}</Tag>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, user) => (
                <Space>
                    <Tooltip title="Voir les détails">
                        <Button
                            shape="circle"
                            icon={<EyeOutlined />}
                            onClick={() => handleViewDetails(user)}
                        />
                    </Tooltip>
                    <Tooltip title="Éditer">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => handleEditUser(user)}
                        />
                    </Tooltip>
                    {["Désactivé", "Supprimé"].includes(user["Statut du compte"]) ? (
                        <Tooltip title="Activer">
                            <Button
                                style={{ background: "green" }}
                                type="primary"
                                shape="circle"
                                icon={<CheckOutlined />}
                                onClick={() => handleDeleteUser(user.UUID, "enable")}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Désactiver">
                            <Button
                                type="default"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => handleDeleteUser(user.UUID, "disable")}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const handleRowExpand = (key) => {
        setExpandedRowKeys((prevKeys) =>
            prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        );
    };

    const expandedRowRender = (record) => (
        <div style={{padding: "5px 16px"}}>
            <p>
                <strong>Email :</strong> {record["Email"] || "Non renseigné"}
            </p>
            <p>
                <strong>Rôle :</strong>{" "}
                <Space>
                    {record.Roles
                        .filter((role) => role !== "ROLE_USER")
                        .map((role, index) => (
                            <Tag key={index} color="blue">
                                {role.replace("ROLE_", "").replace("_", " ").toUpperCase()}
                            </Tag>
                        ))}
                </Space>
            </p>
            <p>
                <strong>Status du compte :</strong>{" "}<Tag
                color={record["Statut du compte"] === "Actif" ? "green" : "red"}>{(record["Statut du compte"] || "Non renseigné").toUpperCase()}</Tag>
            </p>
            <p>
                <strong>Notifications :</strong>{" "}<Tag
                color={record["Recevoir des notifications"] ? "green" : "red"}>{record["Recevoir des notifications"] ? "OUI" : "NON"}</Tag>
            </p>
            <p>
                <strong>Dernière connexion :</strong> {record["Dernière connexion"] || "Jamais connecté"}
            </p>
            <p>
                <strong>Jours travaillés : </strong>
                {(() => {
                    const allDays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Jours férié"];
                    const isAsmat = record?.Roles?.includes("ROLE_ASSISTANTE_MATERNELLE");

                    if (!isAsmat) {
                        return (
                                <i style={{ color: "gray"}}>Non applicable</i>
                        );
                    }

                    const jours = record["Jours travaillés"];

                    return (
                        <div style={{display: "flex", flexWrap: "wrap", gap: "5px"}}>
                            {allDays.map((day) => {
                                const isSpecialDay = day === "Jours férié" && jours?.includes("Jours férié");
                                const isWorked = jours?.includes(day);
                                return (
                                    <Tag
                                        key={day}
                                        style={{
                                            padding: "5px 10px",
                                            fontSize: "12px",
                                            textAlign: "center",
                                            borderRadius: "10px",
                                        }}
                                        color={
                                            isSpecialDay
                                                ? "orange" // Couleur spéciale pour les jours fériés
                                                : isWorked
                                                    ? "green" // Couleur pour les jours travaillés
                                                    : "red" // Couleur par défaut pour les autres jours
                                        }
                                    >
                                        {day}
                                    </Tag>
                                );
                            })}
                        </div>
                    );
                })()}
            </p>
        </div>
    );

    const onSearch = ((value) => handleSearch(value));
    const handleSearch = (searchTerm) => {
        setSearchText(searchTerm);
        const lowercasedSearch = searchTerm.toLowerCase();
        const filtered = users.filter((user) =>
            [user["Nom complet"], user["Email"]]
                .filter(Boolean)
                .some((value) => value.toLowerCase().includes(lowercasedSearch))
        );
        setFilteredUsers(filtered);
    };

    return (
        <div className={"user-page"}>
            <Row gutter={[16, 16]} style={{padding: "0 24px"}}>
                {/* Carte Utilisateurs */}
                <Col xs={24} md={8}>
                    <Card style={{borderRadius: 10, height: '100%'}}>
                        <Statistic
                            title="Total utilisateurs"
                            value={stats.total}
                            prefix={<TeamOutlined/>}
                        />
                        <Progress
                            percent={((stats.active || stats.total - stats.inactiveUsersLastMonth) / stats.total) * 100}
                            size="small"
                            strokeColor="#4e89ff"
                            format={(percent) => `${percent.toFixed(2)}% actifs`}
                            status="active"
                        />
                    </Card>
                </Col>

                {/* Carte Nouveaux utilisateurs */}
                <Col xs={24} md={8}>
                    <Card style={{borderRadius: 10, height: '100%'}}>
                        <Statistic
                            title="Nouveaux utilisateurs (7 derniers jours)"
                            value={stats.newUsersLastWeek}
                            prefix={<TeamOutlined />}
                        />
                        <Progress
                            percent={(stats.newUsersLastWeek / stats.total) * 100}
                            size="small"
                            strokeColor="#52c41a"
                            format={(percent) => `${percent.toFixed(2)}%`}
                            status="active"
                        />
                    </Card>
                </Col>

                {/* Carte Utilisateurs inactifs */}
                <Col xs={24} md={8}>
                    <Card style={{ borderRadius: 10, height: '100%' }}>
                        <Statistic
                            title="Utilisateurs inactifs (30 derniers jours)"
                            value={stats.inactiveUsersLastMonth}
                            prefix={<CloseOutlined />}
                        />
                        <Progress
                            percent={(stats.inactiveUsersLastMonth / stats.total) * 100}
                            size="small"
                            strokeColor="#ff4d4f"
                            format={(percent) => `${percent.toFixed(2)}%`}
                            status="active"
                        />
                    </Card>
                </Col>

                <Col xs={24}>
                    <Card>
                        <Title level={3}>Liste des utilisateurs</Title>

                        <Search
                            placeholder="Rechercher..."
                            value={searchText}
                            onSearch={onSearch}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                handleSearch(e.target.value);
                            }}
                            style={{ marginBottom: "16px" }}
                            enterButton
                        />

                        <Table
                            columns={columns}
                            dataSource={filteredUsers.map((user, index) => ({
                                ...user,
                                key: index,
                            }))}
                            loading={loading}
                            pagination={{
                                pageSizeOptions: ["10", "20", "50", "100"],
                                showSizeChanger: true,
                                locale: { items_per_page: "par page" },
                            }}
                            onRow={(record) => ({
                                onClick: (e) => {
                                    if (!e.target.closest("button")) {
                                        handleViewDetails(record);
                                    }
                                },
                                style: { cursor: "pointer" },
                            })}
                            locale={{
                                emptyText: searchText
                                    ? "Aucun résultat trouvé pour votre recherche."
                                    : "Aucune donnée disponible."
                            }}
                            rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "odd-row")}
                            expandable={{
                                expandedRowKeys,
                                expandedRowRender,
                                onExpand: ( expanded, record ) => {
                                    handleRowExpand(record.key);
                                }
                            }}
                        />
                    </Card>

                    {/* Modal pour les détails de l'utilisateur */}
                    <Modal
                        visible={modalVisible}
                        title={<Title level={4}>Détails de {selectedUser?.["Nom complet"]}</Title>}
                        onCancel={handleCloseModal}
                        footer={null}
                        width={800}
                    >
                        {selectedUser && (
                            <>
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label="Nom complet">{selectedUser["Nom complet"]}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{selectedUser.Email}</Descriptions.Item>
                                    <Descriptions.Item label="Téléphone">{selectedUser.Téléphone || "Non renseigné"}</Descriptions.Item>
                                    <Descriptions.Item label="Adresse">
                                        {selectedUser.Adresse?.Adresse || "Non renseignée"} -{" "}
                                        {selectedUser.Adresse["Code postal"] || "Non renseignée"}{" "}
                                        {selectedUser.Adresse?.Ville || "Non renseignée"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Création du compte">{selectedUser["Création du compte"]}</Descriptions.Item>
                                    <Descriptions.Item label="Dernière connexion">
                                        {selectedUser["Dernière connexion"] || "Jamais connecté"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Recevoir des notifications">
                                        <Tag color={selectedUser["Recevoir des notifications"] ? "green" : "red"}>
                                            {selectedUser["Recevoir des notifications"] ? "OUI" : "NON"}
                                        </Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Statut">
                                        <Tag color={selectedUser["Statut du compte"] === "Actif" ? "green" : "red"}>
                                            {selectedUser["Statut du compte"].toUpperCase()}
                                        </Tag>
                                    </Descriptions.Item>
                                    {selectedUser.Roles.includes("ROLE_ASSISTANTE_MATERNELLE") && (
                                        <>
                                            <Descriptions.Item
                                                label={`Quota (${selectedUser.Quota ? formatSize(selectedUser.Quota) : "Non défini"})`}
                                            >
                                                {quotaLoading ? (
                                                    <Spin />
                                                ) : (
                                                    <QuotaResponsiveProgress
                                                        usedSpace={quotaData.used_space}
                                                        totalSpace={quotaData.quota}
                                                        addBackground={false}
                                                    />
                                                )}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Date de fin d'agrément">
                                                {selectedUser["Date de fin d'agrément"] || "Non renseignée"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Date de fin assurance habitation">
                                                {selectedUser["Date de fin assurance habitation"] || "Non renseignée"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Date de fin assurance voiture">
                                                {selectedUser["Date de fin assurance voiture"] || "Non renseignée"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Jours travaillés">
                                                {(() => {
                                                    const allDays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Jours férié"];
                                                    const jours = selectedUser["Jours travaillés"];

                                                    return (
                                                        <div style={{display: "flex", flexWrap: "wrap", gap: "5px"}}>
                                                            {allDays.map((day) => {
                                                                const isSpecialDay = day === "Jours férié" && jours?.includes("Jours férié");
                                                                const isWorked = jours?.includes(day);
                                                                return (
                                                                    <Tag
                                                                        key={day}
                                                                        style={{
                                                                            padding: "5px 10px",
                                                                            fontSize: "12px",
                                                                            textAlign: "center",
                                                                            borderRadius: "10px",
                                                                        }}
                                                                        color={
                                                                            isSpecialDay
                                                                                ? "orange" // Couleur spéciale pour les jours fériés
                                                                                : isWorked
                                                                                    ? "green" // Couleur pour les jours travaillés
                                                                                    : "red" // Couleur par défaut pour les autres jours
                                                                        }
                                                                    >
                                                                        {day}
                                                                    </Tag>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })()}
                                            </Descriptions.Item>
                                        </>
                                    )}
                                    <Descriptions.Item label="Date d'expiration du compte">
                                        {selectedUser["Date d'expiration "] || "Non définie"}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider />
                                <Row justify="center" gutter={[16, 16]}>
                                    <Col>
                                        <Button type="primary" icon={<EditOutlined />} onClick={() => handleEditUser(selectedUser)}>
                                            Éditer
                                        </Button>
                                    </Col>
                                    {["Désactivé", "Supprimé"].includes(selectedUser["Statut du compte"]) ? (
                                        <Col>
                                            <Button
                                                type="primary"
                                                style={{ background: "green" }}
                                                icon={<CheckOutlined />}
                                                onClick={() => handleDeleteUser(selectedUser.UUID, "enable")}
                                            >
                                                Activer
                                            </Button>
                                        </Col>
                                    ) : (
                                        <>
                                            <Col>
                                                <Button
                                                    type="default"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => handleDeleteUser(selectedUser.UUID, "disable")}
                                                >
                                                    Désactiver
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => handleDeleteUser(selectedUser.UUID, "delete")}
                                                >
                                                    Supprimer
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                    <Col>
                                        <Button type="default" icon={<CloseOutlined />} onClick={handleCloseModal}>
                                            Fermer
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Modal>
                </Col>
            </Row>
        </div>
    );
};

export default UsersPage;
