import React, {useEffect, useRef, useState} from 'react';
import {
    FolderOutlined,
    FileOutlined,
    LeftOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    CloseOutlined, DeleteOutlined, CheckOutlined
} from '@ant-design/icons';
import {
    Table,
    Button,
    Tooltip,
    Typography,
    Row,
    Input,
    Menu,
    Dropdown,
    Radio,
    Breadcrumb,
    Divider,
    Progress, Col, Card, Checkbox, TreeSelect, Alert, Modal, message
} from 'antd';
import {useAuth} from '../../context/AuthContext';
import AddFile from './IconComponents/AddFile';
import AddFolder from './IconComponents/AddFolder';
import DownloadFile from './IconComponents/DownloadFile';
import {DeleteFile, DeleteFolder} from './IconComponents/DeleteFileFolder';
import {EditFile, EditFolder} from './IconComponents/EditFileFolder';
import './FileExplorer.css';
import {DeleteFichier, DeleteRepertoire, Download, MoveItem} from "./DocumentsApi";
import FilePreviewModal from './FilePreviewModal';
import useIsMobile from "../../hooks/useIsMobile";
import {ROUTES} from "../../Routes/Routes";
import {useNavigate} from "react-router-dom";
import QuotaResponsiveProgress from "./QuotaResponsiveProgress";
import useAddClassOnPath from "../../hooks/useAddClassOnPath";
import DragAndDropZone from "./DragAndDropZone";
import MoveItemIcon from "./IconComponents/MoveItemIcon";
import ActionButtons from "./ActionButton";

const {Search} = Input;
const { Text } = Typography;

const FileExplorer = ({data, forceUpdate, quota, rootFolderName = "Global", rootFolderId = -1, rootSize = 0, highlightColor = ""}) => {
    const TOP_NUMBER_LARGEST_ITEMS = 3;

    const {user, token} = useAuth();
    const [searchValue, setSearchValue] = useState('');
    const [viewMode, setViewMode] = useState('list');
    const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");
    const isMobile = useIsMobile(992)
    const [longPressItem, setLongPressItem] = useState(null); // Pour détecter l'élément sur lequel on reste appuyé
    const longPressTimer = useRef(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [previewFileType, setPreviewFileType] = useState('');
    const [previewFilePath, setPreviewFilePath] = useState('');
    const [previewFileName, setPreviewFileName] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
    const [targetFolderId, setTargetFolderId] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState(
        selectedItems.map((item) => ({ ...item, isSelected: true }))
    );
    const navigate = useNavigate();

    useAddClassOnPath("bodyContent", "file-explorer-active", ROUTES.DOCUMENTS);

    // Références pour chaque item
    const itemRefs = useRef({});

    // État pour l'arborescence d'origine et l'affichage actuel
    const [originalData, setOriginalData] = useState(data);
    const [displayData, setDisplayData] = useState(data);  // Données affichées

    // Chemin de navigation
    const [pathHistory, setPathHistory] = useState([{nom: rootFolderName, id: rootFolderId}]);
    const [visiblePath, setVisiblePath] = useState(pathHistory);
    const breadcrumbRef = useRef(null);

    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);

    const isTouchDevice = (() => {
        // Vérifie si `ontouchstart` est supporté
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            // Vérifie si l'utilisateur a activé l'interaction tactile
            return matchMedia('(pointer: coarse)').matches;
        }
        return false;
    })();

    // Rafraîchit les données affichées chaque fois que `data` change
    useEffect(() => {
        setOriginalData(data);
        setDisplayData(data);
    }, [data, forceUpdate]);

    // Fonction pour accéder au dossier en suivant `pathHistory`
    const getCurrentFolderContent = () => {
        let folder = {contient: displayData};
        pathHistory.forEach((path, index) => {
            if (index > 0) {
                folder = folder.contient.find(item => item.id === path.id) || folder;
            }
        });
        return [
            ...(folder.contient || []),
            ...(folder.fichiers || [])
        ];
    };

    const handleOpenFolder = (folder) => {
        const lastPath = pathHistory[pathHistory.length - 1];
        if (lastPath && lastPath.id !== folder.key) {
            setPathHistory([...pathHistory, { nom: folder.nom, id: folder.key }]);
        }
        setSelectedItems([])
    };

    const handleGoBack = () => {
        if(pathHistory.length > 1) {
            const newPathHistory = [...pathHistory];
            newPathHistory.pop();
            setPathHistory(newPathHistory);
        } else {
            navigate(ROUTES.DOCUMENTS)
        }
    };

    const handleKeyPress = (event, record, actionType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            if (actionType === 'open') {
                handleOpenFolder(record);
            } else if (actionType === 'file') {
                handleFilePreview(record.nom, record.chemin);
            }
        }
    };

    const handleRefresh = () => {
        forceUpdate();
        setSearchValue('');
        handleSearch('');
    };

    const onSearch = (value, _e, info) => handleSearch(value);
    const handleSearch = (searchTerm) => {
        // Si le terme de recherche est vide, réinitialisez les données affichées et effacez les messages de recherche
        if (!searchTerm) {
            setDisplayData(originalData);
            return;
        }

        // Filtrage des données basé sur searchTerm
        const search = (nodes) => {
            return nodes
                .map((node) => {
                    let hasMatch = false;
                    if ((node.nom || node["nom du répertoire"]) && (node.nom || node["nom du répertoire"]).toLowerCase().includes(searchTerm.toLowerCase())) {
                        hasMatch = true;
                    }

                    let filteredFiles = [];
                    if (node.fichiers) {
                        filteredFiles = node.fichiers.filter((file) =>
                            file.nom.toLowerCase().includes(searchTerm.toLowerCase())
                        );
                        if (filteredFiles.length > 0) {
                            hasMatch = true;
                        }
                    }

                    let filteredSubfolders = [];
                    if (node.contient) {
                        filteredSubfolders = search(node.contient);
                        if (filteredSubfolders.length > 0) {
                            hasMatch = true;
                        }
                    }

                    if (hasMatch) {
                        return {
                            ...node,
                            contient: filteredSubfolders,
                            fichiers: filteredFiles,
                            containsSearchResults: true
                        };
                    } else {
                        return null;
                    }
                })
                .filter(Boolean);
        };

        const filteredData = search(originalData);
        setDisplayData(filteredData);
    };

    const handleFileAction = (record) => {
        if (record.chemin) {
            Download(record.chemin)
        }
    };

    useEffect(() => {
        const handleGlobalKeyPress = (event) => {
            const isInputField = document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA';

            if (event.ctrlKey && event.key === 'f') {
                event.preventDefault();
                document.getElementById('file-search-input').focus();
            } else if (event.key === 'Backspace' && pathHistory.length > 1 && !isInputField) {
                event.preventDefault();
                handleGoBack();
            }
        };

        window.addEventListener('keydown', handleGlobalKeyPress);
        return () => {
            window.removeEventListener('keydown', handleGlobalKeyPress);
        };
    }, [pathHistory]);

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes" ,"KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const convertToTreeData = (data, isRoot = false, excludedIds = []) => {
        const nodes = data
            .filter((node) => node["nom du répertoire"] || node.contient)
            .map((node) => {
                if (excludedIds.includes(node.id)) {
                    return null;
                }

                return {
                    title: (
                        <span>
                            <FolderOutlined style={{marginRight: 8, color: '#4e89ff'}}/>
                            {node["nom du répertoire"] || node.nom}
                            {" "}({formatBytes(node.taille)})
                        </span>
                    ),
                    value: node.id,
                    key: node.id,
                    children: node.contient ? convertToTreeData(node.contient, false, excludedIds) : []
                }
            })
            .filter(Boolean);

        if (isRoot) {
            return [
                {
                    title: (
                        <span>
                            <FolderOutlined style={{ marginRight: 8, color: '#4e89ff' }} />
                            {rootFolderName}
                            {" "}({formatBytes(rootSize)})
                        </span>
                    ),
                    value: rootFolderId,
                    key: rootFolderId,
                    children: nodes,
                },
            ];
        }

        return nodes;
    };

    const treeData = convertToTreeData(originalData, true);

    const handleConfirmDelete = async () => {
        const selectedForDeletion = itemsToDelete.filter((item) => item.isSelected);
        const selectedFiles = selectedForDeletion.filter(item => item.type === 'Fichier');
        const selectedFolders = selectedForDeletion.filter(item => item.type === 'Dossier');

        for (const file of selectedFiles) {
            await DeleteFichier({ idFichier: file.key, token, forceUpdate });
        }

        for (const folder of selectedFolders) {
            await DeleteRepertoire({ idRepertoire: folder.key, token, forceUpdate });
        }

        setSelectedItems((prev) =>
            prev.filter((item) => !itemsToDelete.some((toDelete) => toDelete.key === item.key))
        );

        closeDeleteModal();
    };

    const handleDownloadSelected = async () => {
        const selectedFiles = selectedItems.filter(item => item.type === 'Fichier');

        for (const file of selectedFiles) {
            await Download(file.chemin, file.nom);
        }

        setSelectedItems([]);
    };

    const handleMoveSelected = async (newParentId) => {
        if (!newParentId) {
            message.error("Veuillez sélectionner un répertoire cible.");
            return;
        }

        const movePromises = selectedItems.map((item) =>
            MoveItem({ itemId: item.key, newParentId, token })
        );

        try {
            await Promise.all(movePromises);
            message.success("Éléments déplacés avec succès !");
        } catch (error) {
            message.error("Erreur lors du déplacement : " + error.message);
        }
        finally {
            setSelectedItems([]);
            forceUpdate();
        }
    };

    // Définir les colonnes pour le tableau
    const columns = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            width: '44%',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            render: (text, record) =>
                record.type === 'Dossier' ? (
                    <span
                        onKeyPress={(e) => handleKeyPress(e, record, 'open')}
                        tabIndex={0}
                        className="folder-name"
                        style={{cursor: 'pointer'}}
                    >
                        <FolderOutlined style={{marginRight: 8, color: '#4e89ff'}}/>{record.nom}
                        {record.containsSearchResults && (
                            <span style={{fontStyle: 'italic', color: 'gray', marginLeft: '8px'}}>
                                (Contient votre recherche)
                            </span>
                        )}
                    </span>
                ) : (
                    <span
                        onKeyPress={(e) => handleKeyPress(e, record, 'file')}
                        style={{cursor: 'pointer'}}
                        tabIndex={0}
                    >
                        <FileOutlined style={{marginRight: 8, color: "#ff7f50"}}/>{record.nom}
                    </span>
                ),
        },
        {
            title: 'Taille',
            dataIndex: 'taille',
            key: 'taille',
            width: '12%',
            sorter: (a, b) => parseFloat(a.taille || 0) - parseFloat(b.taille || 0),
            render: (_, record) => {
                return record.taille ? formatBytes(record.taille) : ""
            }
        },
        {
            title: 'Modifié le',
            dataIndex: 'modifyAt',
            key: 'modifyAt',
            width: '20%',
            responsive: ['md'],
            sorter: (a, b) => {
                const parseDate = (dateString) => {
                    if (!dateString)
                        return new Date(0);
                    const [day, month, year, time] = dateString.split(/[/ ]/);
                    return new Date(`${year}-${month}-${day}T${time}`);
                };

                return parseDate(a.modifyAt) - parseDate(b.modifyAt);
            },
            render: (text) => text || '',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '6%',
            responsive: ['xxl'],
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            align: 'center',
            title: 'Actions',
            key: 'actions',
            width: '20%',
            className: 'action-column',
            render: (_, record) =>
                isAssistantMaternel ? (
                    record.type === 'Dossier' ? (
                        <div className="container-action-file-explorer">
                            <Tooltip title="Déplacer le dossier">
                                <MoveItemIcon
                                    itemId={record.key}
                                    currentFolderId={pathHistory[pathHistory.length - 1].id}
                                    token={token}
                                    treeData={treeData}
                                    forceUpdate={forceUpdate}
                                    nomItem={record.nom}
                                    type={record.type}
                                />
                            </Tooltip>
                            <Tooltip title="Éditer le dossier">
                                <EditFolder nodeData={record} token={token} forceUpdate={forceUpdate}/>
                            </Tooltip>
                            <Tooltip title="Supprimer le dossier">
                                <DeleteFolder name={record.nom} id={record.key} token={token} forceUpdate={forceUpdate}/>
                            </Tooltip>
                        </div>
                    ) : (
                        <div className="container-action-file-explorer">
                            <Tooltip title="Télécharger">
                                <DownloadFile url={record.chemin} filename={record.nom}/>
                            </Tooltip>
                            <Tooltip>
                                <MoveItemIcon
                                    itemId={record.key}
                                    currentFolderId={pathHistory[pathHistory.length - 1].id}
                                    token={token}
                                    treeData={treeData}
                                    forceUpdate={forceUpdate}
                                    nomItem={record.nom}
                                    type={record.type}
                                />
                            </Tooltip>
                            <Tooltip title="Éditer le fichier">
                                <EditFile nom={record.nom} id={record.key} token={token} forceUpdate={forceUpdate}/>
                            </Tooltip>
                            <Tooltip title="Supprimer le fichier">
                                <DeleteFile name={record.nom} id={record.key} token={token} forceUpdate={forceUpdate}/>
                            </Tooltip>
                        </div>
                    )
                ) : (
                    record.chemin && (
                        <div className="container-action-file-explorer">
                            <Tooltip title="Télécharger">
                                <DownloadFile url={record.chemin} filename={record.nom}/>
                            </Tooltip>
                        </div>
                    )
                ),
        },
    ];

    // Données actuelles pour le tableau
    const tableData = getCurrentFolderContent().map((item) => ({
        key: item.id,
        nom: item["nom du répertoire"] || item.nom,
        type: item["nom du répertoire"] ? 'Dossier' : 'Fichier',
        chemin: item.chemin || null,
        taille: (item.taille || 0).toFixed(2),
        modifyAt: item.modifyAt || null,
        containsSearchResults: item.containsSearchResults || false,
    }));

    const startLongPress = (item) => {
        if (isTouchDevice) {
            longPressTimer.current = setTimeout(() => {
                setLongPressItem(item);
                triggerContextMenu(item); // Déclencher le menu contextuel
            }, 600);
        }
    };

    const getEmptyMessage = () => {
        if (searchValue !== '') {
            return "Aucun élément ne correspond à votre recherche : '" + searchValue + "'";
        }
        return isAssistantMaternel
            ? "Vous n'avez pas encore ajouté de fichiers ou de répertoires. Cliquez sur 'Ajouter un répertoire' ou 'Ajouter un fichier' pour commencer."
            : "Aucun fichier ou répertoire disponible."
    }

    const renderTable = () => (
        <div className={"fe-table"}>
            <Table
                size={"middle"}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedItems.map((item) => item.key),
                    onChange: (selectedRowKeys) => {
                        const updatedSelectedItems = tableData.filter((item) => selectedRowKeys.includes(item.key));
                        setSelectedItems(updatedSelectedItems);
                    },
                }}
                scroll={{ y: 'calc(var(--table-max-height) - 50px)' }}
                locale={{
                    emptyText: getEmptyMessage(),
                }}
                onRow={(record) => ({
                    onClick: (e) => {
                        if (!e.target.closest(".action-column") && isTouchDevice) {
                            // Simple-clic pour les appareils tactiles
                            if (record.type === "Dossier") {
                                handleOpenFolder(record);
                            } else {
                                handleFilePreview(record.nom, record.chemin);
                            }
                        }
                    },
                    onDoubleClick: (e) => {
                        if (!e.target.closest(".action-column") && !isTouchDevice) {
                            // Double-clic pour les appareils non-tactiles
                            if (record.type === "Dossier") {
                                handleOpenFolder(record);
                            } else {
                                handleFilePreview(record.nom, record.chemin);
                            }
                        }
                    }
                })}
            />
        </div>
    );

    const endLongPress = () => {
        clearTimeout(longPressTimer.current);
    };

    const triggerContextMenu = (item) => {
        const ref = itemRefs.current[item.key];
        if (ref && ref.current) {
            const event = new MouseEvent('contextmenu', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            ref.current.dispatchEvent(event);
        }
    };

    // Détection des éléments en fin de ligne
    const updateIconRowClass = () => {
        const iconItems = document.querySelectorAll('.icon-item');
        let previousTopOffset = iconItems[0]?.getBoundingClientRect().top;

        iconItems.forEach((item, index) => {
            const currentTopOffset = item.getBoundingClientRect().top;

            // Vérifie si on change de ligne
            if (index > 0 && currentTopOffset > previousTopOffset) {
                iconItems[index - 1].classList.add('last-in-row');
            } else {
                item.classList.remove('last-in-row');
            }

            previousTopOffset = currentTopOffset;
        });

        // Marque le dernier élément de la liste si c'est le dernier de sa ligne
        if (iconItems.length > 0) {
            iconItems[iconItems.length - 1].classList.add('last-in-row');
        }
    };

    // Appel de la fonction dans useEffect
    useEffect(() => {
        if (viewMode === 'icon') {
            updateIconRowClass();
            window.addEventListener('resize', updateIconRowClass);
        }

        return () => {
            window.removeEventListener('resize', updateIconRowClass);
        };
    }, [tableData, viewMode]);

    const getSortedIconData = () => {
        let data = [...tableData];

        // Appliquer le tri en fonction de l'état `sortOrder`
        if (sortOrder === 'asc') {
            data.sort((a, b) => a.nom.localeCompare(b.nom));
        } else if (sortOrder === 'desc') {
            data.sort((a, b) => b.nom.localeCompare(a.nom));
        }

        return data;
    };

    const handleIconSelect = (item, isChecked) => {
        setSelectedItems((prevSelectedItems) => {
            if (isChecked) {
                return [...prevSelectedItems, item];
            } else {
                return prevSelectedItems.filter((selectedItem) => selectedItem.key !== item.key);
            }
        });
    };

    const renderIcons = () => {
        const sortedData = getSortedIconData();

        if (sortedData.length === 0) {
            return (
                <div className="no-items-message">
                    { getEmptyMessage() }
                </div>
            );
        }

        const handleSelectAllVisible = (isChecked) => {
            if (isChecked) {
                const visibleItems = sortedData;
                setSelectedItems((prevSelectedItems) => [
                    ...prevSelectedItems,
                    ...visibleItems.filter(
                        (item) =>
                            !prevSelectedItems.some(
                                (selectedItem) => selectedItem.key === item.key
                            )
                    ),
                ]);
            } else {
                const visibleKeys = sortedData.map((item) => item.key);
                setSelectedItems((prevSelectedItems) =>
                    prevSelectedItems.filter(
                        (selectedItem) => !visibleKeys.includes(selectedItem.key)
                    )
                );
            }
        };

        const allVisibleSelected = sortedData.every((item) =>
            selectedItems.some((selectedItem) => selectedItem.key === item.key)
        );

        const isIndeterminate =
            selectedItems.some((item) =>
                sortedData.some(
                    (visibleItem) => visibleItem.key === item.key
                )
            ) && !allVisibleSelected;

        return (
            <div className={"container-fe-icone"}>
                <div className="icon-filters">
                    <Radio.Group value={allVisibleSelected ? 'select-all' : isIndeterminate ? 'partial' : null}>
                        <Radio.Button
                            value="select-all"
                            onClick={() =>
                                handleSelectAllVisible(!allVisibleSelected)
                            }
                        >
                            {allVisibleSelected ? (
                                <>
                                    <CloseOutlined style={{ marginRight: 5 }} />
                                    {!isMobile ? 'Tout désélectionner' : 'Désélect.'}
                                </>
                            ) : (
                                <>
                                    <CheckOutlined style={{ marginRight: 5 }} />
                                    {!isMobile ? 'Sélectionner tout' : 'Sélect.'}
                                </>
                            )}
                        </Radio.Button>
                    </Radio.Group>
                    <Radio.Group value={sortOrder} optionType="button" buttonStyle="solid" onChange={(e) => setSortOrder(e.target.value)}>
                        <Radio.Button value="asc"><SortAscendingOutlined/>{!isMobile ? ' Nom Ascendant' : ' Asc'}
                        </Radio.Button>
                        <Radio.Button value="desc"><SortDescendingOutlined/>{!isMobile ? ' Nom Descendant' : ' Desc'}
                        </Radio.Button>
                        <Radio.Button value={null}><UnorderedListOutlined/>{!isMobile ? ' Ordre Original' : ' Original'}
                        </Radio.Button>
                    </Radio.Group>
                </div>

                <div className="icons-view-grid">
                    {sortedData.map((item) => {
                        if (!itemRefs.current[item.key]) {
                            itemRefs.current[item.key] = React.createRef();
                        }

                        const menu = (
                            <Menu>
                                <Divider style={{borderColor: '#4e89ff', margin: "0 0 5px 0", fontWeight: "bold"}}
                                         plain>{item.nom}</Divider>
                                <table style={{margin: "0"}}>
                                    <tr>
                                        <td style={{padding: "0 5px", textAlign: "end", fontWeight: "500"}}>Modifié le
                                        </td>
                                        <td style={{padding: "0 5px"}}>{item.modifyAt}</td>
                                    </tr>
                                    {item.taille && (
                                        <tr>
                                            <td style={{padding: "0 5px", textAlign: "end", fontWeight: "500"}}>Taille</td>
                                            <td style={{padding: "0 5px"}}>{formatBytes(item.taille)}</td>
                                        </tr>
                                    )}
                                </table>
                                {isAssistantMaternel ? (
                                    item.type === 'Dossier' ? (
                                        <>
                                            <Divider style={{borderColor: '#4e89ff', margin: "0 0 5px 0"}} plain>Action</Divider>

                                            <Menu.Item key="move-folder">
                                                <MoveItemIcon
                                                    itemId={item.key}
                                                    currentFolderId={pathHistory[pathHistory.length - 1].id}
                                                    token={token}
                                                    treeData={treeData}
                                                    forceUpdate={forceUpdate}
                                                    title={"Déplacer le dossier et son contenu"}
                                                    nomItem={item.nom}
                                                    type={item.type}
                                                />
                                            </Menu.Item>
                                            <Menu.Item key="edit-folder">
                                                <EditFolder nodeData={item} token={token} forceUpdate={forceUpdate}
                                                            title={"Éditer le dossier"}/>
                                            </Menu.Item>
                                            <Menu.Item key="delete-folder">
                                                <DeleteFolder name={item.nom} id={item.key} token={token}
                                                              forceUpdate={forceUpdate} title={"Supprimer le dossier"}/>
                                            </Menu.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Divider style={{borderColor: '#4e89ff', margin: "0 0 5px 0"}} plain>Action</Divider>

                                            <Menu.Item key="download">
                                                <DownloadFile url={item.chemin} title={"Télécharger le fichier"}
                                                              filename={item.nom}/>
                                            </Menu.Item>
                                            <Menu.Item key="move-file">
                                                <MoveItemIcon
                                                    itemId={item.key}
                                                    currentFolderId={pathHistory[pathHistory.length - 1].id}
                                                    token={token}
                                                    treeData={treeData}
                                                    forceUpdate={forceUpdate}
                                                    title={"Déplacer le fichier"}
                                                    nomItem={item.nom}
                                                    type={item.type}
                                                />
                                            </Menu.Item>
                                            <Menu.Item key="edit-file">
                                                <EditFile nom={item.nom} id={item.key} token={token}
                                                          forceUpdate={forceUpdate} title={"Éditer le fichier"}/>
                                            </Menu.Item>
                                            <Menu.Item key="delete-file">
                                                <DeleteFile name={item.nom} id={item.key} token={token}
                                                            forceUpdate={forceUpdate} title={"Supprimer le fichier"}/>
                                            </Menu.Item>
                                        </>
                                    )
                                ) : (
                                    item.type === 'Fichier' && (
                                        <>
                                            <Divider style={{borderColor: '#4e89ff', margin: "0 0 5px 0"}} plain>Action</Divider>

                                            <Menu.Item key="download-file">
                                                <DownloadFile url={item.chemin} title={"Télécharger le fichier"} filename={item.nom}/>
                                            </Menu.Item>
                                        </>
                                    )
                                )}
                            </Menu>
                        );


                        const isSelected = selectedItems.some(
                            (selectedItem) => selectedItem.key === item.key
                        );

                        return (
                            <Dropdown key={item.key} overlay={menu} trigger={['contextMenu']}>
                                <div
                                    className="icon-item"
                                    ref={itemRefs.current[item.key]}
                                    onTouchStart={() => startLongPress(item)}
                                    onTouchEnd={endLongPress}
                                >
                                    <Tooltip title={item.nom}>
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={(e) => handleIconSelect(item, e.target.checked)}
                                            style={{margin: "0 0 5px 0"}}
                                        />
                                        <div className="fe-icon">
                                            {item.type === 'Dossier' ? (
                                                <FolderOutlined
                                                    onClick={isTouchDevice ? () => handleOpenFolder(item) : null}
                                                    onDoubleClick={!isTouchDevice ? () => handleOpenFolder(item) : null}
                                                    onKeyPress={(e) => handleKeyPress(e, item, 'open')}
                                                    style={{fontSize: '48px', color: '#4e89ff'}}
                                                />
                                            ) : (
                                                <FileOutlined
                                                    onClick={isTouchDevice ? () => handleFilePreview(item.nom, item.chemin) : null}
                                                    onDoubleClick={!isTouchDevice ? () => handleFilePreview(item.nom, item.chemin) : null}
                                                    onKeyPress={(e) => handleKeyPress(e, item, 'file')}
                                                    style={{fontSize: '48px', color: '#ff7f50'}}
                                                />
                                            )}
                                        </div>
                                        <div className="icon-label">{item.nom}</div>
                                        <span style={{color: "#888"}}>{formatBytes(item.taille)}</span>
                                    </Tooltip>
                                </div>
                            </Dropdown>
                        );
                    })}
                </div>
            </div>
        );
    };

    const handleFilePreview = (fileName, filePath) => {
        const fileExtension = filePath.split('.').pop().toLowerCase();

        let fileType;
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            fileType = 'image';
        } else if (fileExtension === 'pdf') {
            fileType = 'pdf';
        } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
            fileType = 'video';
        } else if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
            fileType = 'audio';
        } else if (['txt', 'md', 'log'].includes(fileExtension)) {
            fileType = 'text';
        } else {
            fileType = 'other';
        }

        setPreviewFileName(fileName)
        setPreviewFileType(fileType);
        setPreviewFilePath(filePath);
        setIsPreviewVisible(true);
    };

    const handleBreadcrumbClick = (index) => {
        if (index < pathHistory.length - 1) {
            setPathHistory(pathHistory.slice(0, index + 1));
        }
    };

    const getTextWidth = (text) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '14px Arial';
        return context.measureText(text).width + 50;
    };

    useEffect(() => {
        const updateVisiblePath = () => {
            if (!breadcrumbRef.current) return;

            const availableWidth = breadcrumbRef.current.offsetWidth;
            const pathWidths = pathHistory.map(folder => getTextWidth(folder.nom));

            // Initialisez visibleItems avec le dossier racine uniquement s'il n'est pas déjà présent
            let visibleItems = [{ nom: pathHistory[0].nom, id: pathHistory[0].id }];
            let remainingWidth = availableWidth - getTextWidth('...') - pathWidths[pathWidths.length - 1];

            // Parcours des dossiers intermédiaires et ajoute les éléments tant que l'espace le permet
            for (let i = 1; i < pathHistory.length - 1; i++) {
                if (remainingWidth >= pathWidths[i]) {
                    visibleItems.push(pathHistory[i]);
                    remainingWidth -= pathWidths[i];
                } else {
                    break;
                }
            }

            // Ajoutez "..." uniquement si des éléments sont masqués, en vérifiant que le dossier racine n'est pas ajouté deux fois
            if (visibleItems.length > 1 && visibleItems.length < pathHistory.length - 1) {
                visibleItems = [visibleItems[0], { nom: '...', id: 'ellipsis' }];
            }

            // Ajoutez toujours le dossier actuel en dernier, sans doublon du dossier racine
            if (!visibleItems.some(item => item.id === pathHistory[pathHistory.length - 1].id)) {
                visibleItems.push(pathHistory[pathHistory.length - 1]);
            }

            setVisiblePath(visibleItems);
        };

        const handleResize = () => updateVisiblePath();
        window.addEventListener('resize', handleResize);
        updateVisiblePath();

        return () => window.removeEventListener('resize', handleResize);
    }, [pathHistory]);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }

        const handleResize = () => {
            if (headerRef.current) {
                setHeaderHeight(headerRef.current.offsetHeight);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getTopLargestItems = (data) => {
        const flattenData = (nodes) => {
            let items = [];

            nodes.forEach((node) => {
                if (node.taille) {
                    items.push({
                        nom: node.nom || node["nom du répertoire"],
                        taille: parseFloat(node.taille),
                        type: node["nom du répertoire"] ? "Dossier" : "Fichier",
                    });
                }

                if (node.contient) {
                    items = items.concat(flattenData(node.contient));
                }

                if (node.fichiers) {
                    items = items.concat(
                        node.fichiers.map((file) => ({
                            nom: file.nom,
                            taille: parseFloat(file.taille),
                            type: "Fichier",
                        }))
                    );
                }
            });

            return items;
        };

        const items = flattenData(data || []);
        return items
            .sort((a, b) => b.taille - a.taille) // Trier par taille décroissante
            .slice(0, TOP_NUMBER_LARGEST_ITEMS); // Garder les `count` plus volumineux
    };

    const getTextColorForBackground = (bgColor) => {
        if(bgColor !== "") {
            const hexToRgb = (hex) => {
                let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? {
                        r: parseInt(result[1], 16),
                        g: parseInt(result[2], 16),
                        b: parseInt(result[3], 16),
                    }
                    : null;
            };

            const { r, g, b } = bgColor.startsWith("#") ? hexToRgb(bgColor) : { r: 0, g: 0, b: 0 };
            const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

            return luminance > 128 ? "black" : "white";
        }

        return "black";
    };

    const convertHexToRgba = (hex, opacity = 1) => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
            : hex;
    };

    const openMoveModal = () => setIsMoveModalVisible(true);
    const closeMoveModal = () => {
        setIsMoveModalVisible(false);
        setTargetFolderId(null);
    };

    const openDeleteModal = () => {
        setItemsToDelete(
            selectedItems.map((item) => ({
                ...item,
                isSelected: true,
            }))
        );
        setIsDeleteModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalVisible(false);
        setItemsToDelete([]);
    };

    const handleDeleteSelectionChange = (itemKey, isSelected) => {
        setItemsToDelete((prevItems) =>
            prevItems.map((item) =>
                item.key === itemKey ? { ...item, isSelected } : item
            )
        );
    };

    const calculateTotalSize = (items, type) =>
        items
            .filter((item) => item.type === type && item.isSelected)
            .reduce((total, item) => total + parseFloat(item.taille || 0), 0);

    return (
        <>
            {(isAssistantMaternel && isMobile) && (
                selectedItems.length > 0 ? (
                    <ActionButtons
                        selectedItems={selectedItems}
                        onMove={openMoveModal}
                        onDelete={openDeleteModal}
                        onDownload={handleDownloadSelected}
                        height={headerHeight}
                    />
                ) : (
                    <QuotaResponsiveProgress
                        usedSpace={quota.used_space}
                        totalSpace={quota.quota}
                        dynamicHeight={headerHeight}
                        topLargestItems={getTopLargestItems(originalData)}
                        topNumber={TOP_NUMBER_LARGEST_ITEMS}
                    />
                )
            )}

            <div
                className="file-explorer"
                style={{
                    '--background-color': highlightColor,
                    '--hover-color': convertHexToRgba(highlightColor, 0.5),
                    '--hover-text-color': getTextColorForBackground(highlightColor),
                }}>
                <Row gutter={[8,0]}>
                    <Col xs={24} md={24} lg={isAssistantMaternel ? (selectedItems.length === 0 ? 19 : 17) : 24} xxl={isAssistantMaternel ? (selectedItems.length === 0 ? 20 : 19): 24}>
                        <Row ref={headerRef} className={`${isAssistantMaternel ? "file-explorer-header" : "file-explorer-header feh-parent"} ${!isAssistantMaternel && isMobile ? "mobile" : ""}`}>
                            <div className="back-button">
                                <Button color={pathHistory.length > 1 ? "primary" : "danger"} variant={"solid"} onClick={handleGoBack}>
                                    {pathHistory.length > 1 ? (
                                        <>
                                            <LeftOutlined/> Retour
                                        </>
                                    ) : (
                                        <>
                                            <CloseOutlined/> Quitter
                                        </>
                                    )}
                                </Button>
                                <Tooltip title="Actualiser l'explorateur de fichier" placement="top">
                                    <Button
                                        type={"primary"}
                                        onClick={handleRefresh}
                                    >
                                        <ReloadOutlined/>
                                    </Button>
                                </Tooltip>
                            </div>


                            {/* Affichage du chemin de navigation dynamique */}
                            <div className="path-history" ref={breadcrumbRef}>
                                <Breadcrumb separator=">">
                                    {visiblePath.map((folder, index) => (
                                        <Breadcrumb.Item key={folder.id || "root"}>
                                            {folder.nom === '...' ? (
                                                <span>...</span>
                                            ) : index === visiblePath.length - 1 ? (
                                                <span style={{fontWeight: "bold"}}>{folder.nom}</span>
                                            ) : (
                                                <a onClick={() => handleBreadcrumbClick(index)}>{folder.nom}</a>
                                            )}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </div>

                            {/* Boutons pour ajouter un dossier ou un fichier */}
                            {isAssistantMaternel && (
                                <div className="action-buttons">
                                    <AddFolder
                                        title={"Répertoire"}
                                        folderParentName={pathHistory[pathHistory.length - 1].nom}
                                        parentId={pathHistory[pathHistory.length - 1].id}
                                        token={token}
                                        forceUpdate={forceUpdate}
                                    />
                                    <AddFile
                                        title={"Fichier"}
                                        folderName={pathHistory[pathHistory.length - 1].nom}
                                        idFolder={pathHistory[pathHistory.length - 1].id}
                                        token={token}
                                        forceUpdate={forceUpdate}
                                    />
                                </div>
                            )}

                            <div className="search-input">
                                <Radio.Group value={viewMode} optionType="button" buttonStyle="solid" onChange={(e) => setViewMode(e.target.value)}>
                                    <Radio.Button value="list"><UnorderedListOutlined/>{!isMobile ? " Liste" : ""}</Radio.Button>
                                    <Radio.Button value="icon"><AppstoreOutlined/>{!isMobile ? " Icône" : ""}</Radio.Button>
                                </Radio.Group>

                                <Search
                                    id="file-search-input"
                                    placeholder="Rechercher un répertoire / fichier"
                                    onSearch={onSearch}
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        handleSearch(e.target.value);
                                    }}
                                    enterButton
                                />
                            </div>
                        </Row>
                    </Col>

                    {(isAssistantMaternel && !isMobile) && (
                        <Col xs={24} md={24} lg={selectedItems.length === 0 ? 5 : 7} xxl={(selectedItems.length === 0 ? 4 : 5)} style={selectedItems.length !== 0 ? { height: headerHeight, margin: 'auto' } : { height: headerHeight }}>
                            {selectedItems.length > 0 ? (
                                <ActionButtons
                                    selectedItems={selectedItems}
                                    onMove={openMoveModal}
                                    onDelete={openDeleteModal}
                                    onDownload={handleDownloadSelected}
                                    height={headerHeight}
                                />
                            ) : (
                                <QuotaResponsiveProgress
                                    usedSpace={quota.used_space}
                                    totalSpace={quota.quota}
                                    dynamicHeight={headerHeight}
                                    topLargestItems={getTopLargestItems(originalData)}
                                    topNumber={TOP_NUMBER_LARGEST_ITEMS}
                                />
                            )}
                        </Col>
                    )}
                </Row>

                <DragAndDropZone
                    idFolder={pathHistory[pathHistory.length - 1].id}
                    folderName={pathHistory[pathHistory.length - 1].nom}
                    forceUpdate={forceUpdate}
                    token={token}
                    canAdd={isAssistantMaternel}
                >
                    {viewMode === 'list' ? renderTable() : renderIcons()}
                </DragAndDropZone>

                <FilePreviewModal
                    isVisible={isPreviewVisible}
                    onClose={() => setIsPreviewVisible(false)}
                    fileType={previewFileType}
                    filePath={previewFilePath}
                    fileName={previewFileName}
                />

                <Modal
                    title="Déplacer les éléments"
                    open={isMoveModalVisible}
                    onOk={async () => {
                        await handleMoveSelected(targetFolderId);
                        closeMoveModal();
                    }}
                    onCancel={closeMoveModal}
                    okText="Déplacer"
                    cancelText="Annuler"
                    maskClosable={false}
                >
                    <Alert
                        description={<Text>Vous allez déplacer <Text strong>{selectedItems.length} élément(s)</Text> dans un autre répertoire.</Text>}
                        type="info"
                        showIcon
                    />
                    <p style={{ marginTop: 10 }}>Sélectionnez un répertoire cible :</p>
                    <TreeSelect
                        style={{ width: "100%" }}
                        value={targetFolderId}
                        onChange={(value) => setTargetFolderId(value)}
                        treeData={convertToTreeData(originalData, true, selectedItems.filter(item => item.type === 'Dossier').map(item => item.key))}
                        placeholder="Sélectionnez un répertoire cible"
                        treeDefaultExpandAll
                        allowClear
                    />
                </Modal>

                <Modal
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DeleteOutlined style={{ color: '#ff4d4f', marginRight: 8, fontSize: '20px' }} />
                            <Text strong style={{ fontSize: '16px', color: '#ff4d4f' }}>
                                Confirmer la suppression
                            </Text>
                        </div>
                    }
                    open={isDeleteModalVisible}
                    onOk={handleConfirmDelete}
                    onCancel={closeDeleteModal}
                    okText={
                        <span>
                            <DeleteOutlined style={{ marginRight: 8 }} />
                            Supprimer
                        </span>
                    }
                    cancelText="Annuler"
                    maskClosable={false}
                    okButtonProps={{
                        danger: true,
                    }}
                >
                    <Alert
                        description={
                            <Text>
                                Vous êtes sur le point de supprimer les éléments suivants. Décochez ceux que vous ne souhaitez pas supprimer.
                            </Text>
                        }
                        type="warning"
                        showIcon
                    />
                    <Divider />
                    <div style={{ maxHeight: "45vh", overflowY: 'auto', padding: '0 8px' }}>
                        {/* Groupes séparés pour les dossiers et fichiers */}
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
                                <Text strong>Dossiers</Text>
                                <Checkbox
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        setItemsToDelete((prev) =>
                                            prev.map((item) =>
                                                item.type === 'Dossier' ? { ...item, isSelected: isChecked } : item
                                            )
                                        );
                                    }}
                                >
                                    Tout sélectionner
                                </Checkbox>
                            </div>
                            <Text type="secondary" style={{ marginBottom: 8, display: 'block' }}>
                                Taille totale : {formatBytes(calculateTotalSize(itemsToDelete, 'Dossier'))}
                            </Text>
                            {itemsToDelete.filter((item) => item.type === 'Dossier').map((item) => (
                                <div
                                    key={item.key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8,
                                        padding: '4px 8px',
                                        borderRadius: 4,
                                        backgroundColor: item.isSelected ? '#f0f5ff' : 'transparent',
                                        border: item.isSelected ? '1px solid #1890ff' : '1px solid transparent',
                                        transition: 'all 0.3s',
                                    }}
                                >
                                    <FolderOutlined style={{ fontSize: 20, marginRight: 8, color: '#4e89ff' }} />
                                    <Text style={{ flex: 1 }}>{item.nom}</Text>
                                    <Text type="secondary" style={{ marginRight: 16 }}>
                                        {formatBytes(item.taille)}
                                    </Text>
                                    <Checkbox
                                        checked={item.isSelected}
                                        onChange={(e) =>
                                            handleDeleteSelectionChange(item.key, e.target.checked)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        <Divider dashed style={{ margin: '16px 0' }} />
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
                                <Text strong>Fichiers</Text>
                                <Checkbox
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        setItemsToDelete((prev) =>
                                            prev.map((item) =>
                                                item.type === 'Fichier' ? { ...item, isSelected: isChecked } : item
                                            )
                                        );
                                    }}
                                >
                                    Tout sélectionner
                                </Checkbox>
                            </div>
                            <Text type="secondary" style={{ marginBottom: 8, display: 'block' }}>
                                Taille totale : {formatBytes(calculateTotalSize(itemsToDelete, 'Fichier'))}
                            </Text>
                            {itemsToDelete.filter((item) => item.type === 'Fichier').map((item) => (
                                <div
                                    key={item.key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8,
                                        padding: '4px 8px',
                                        borderRadius: 4,
                                        backgroundColor: item.isSelected ? '#fff7e6' : 'transparent',
                                        border: item.isSelected ? '1px solid #ffa940' : '1px solid transparent',
                                        transition: 'all 0.3s',
                                    }}
                                >
                                    <FileOutlined style={{ fontSize: 20, marginRight: 8, color: '#ff7f50' }} />
                                    <Text style={{ flex: 1 }}>{item.nom}</Text>
                                    <Text type="secondary" style={{ marginRight: 16 }}>
                                        {formatBytes(item.taille)}
                                    </Text>
                                    <Checkbox
                                        checked={item.isSelected}
                                        onChange={(e) =>
                                            handleDeleteSelectionChange(item.key, e.target.checked)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    );
};

export default FileExplorer;
