import React, {useEffect, useState} from "react";
import {AddFichier} from "../DocumentsApi";
import {
    Modal,
    Input,
    Upload,
    notification,
    Button,
    Progress,
    Typography,
    Alert,
    Tabs,
    Divider,
    Steps,
    Form, message
} from "antd";
import {
    UploadOutlined,
    FileAddOutlined,
    WarningOutlined,
    ThunderboltOutlined,
    HourglassOutlined,
    StopOutlined, UserOutlined, InfoCircleOutlined, HomeOutlined, FileTextOutlined, LeftOutlined, RightOutlined
} from "@ant-design/icons";
import axios from "axios";
import FilePreview from "../FilePreview";

const {Item} = Form;
const {Step} = Steps;

const {Text} = Typography;

const AddFile = ({folderName, idFolder, token, forceUpdate, title = ""}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileName, setFileName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [previewFileType, setPreviewFileType] = useState('');

    const handleFilePreview = (fileExtension) => {
        let fileType;
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
            fileType = "image";
        } else if (fileExtension === "pdf") {
            fileType = "pdf";
        } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
            fileType = "video";
        } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
            fileType = "audio";
        } else if (["txt", "md", "log"].includes(fileExtension)) {
            fileType = "text";
        } else {
            fileType = "other";
        }

        setPreviewFileType(fileType);
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const formatTimeRemaining = (seconds) => {
        if (seconds <= 0) return "Moins d'une seconde";
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return minutes > 0
            ? `${minutes} min ${remainingSeconds} sec`
            : `${remainingSeconds} sec`;
    };


    const handleAddFile = async () => {
        if (fileList.length === 0) {
            notification.warning({
                message: "Aucun fichier sélectionné",
                description: "Veuillez sélectionner un fichier à télécharger.",
                icon: <WarningOutlined style={{color: "#faad14"}}/>,
            });
            return;
        }

        const defFileName = fileName || fileList[0].name.substring(0, fileList[0].name.lastIndexOf("."))

        setIsUploading(true);
        setIsModalVisible(false);

        const source = axios.CancelToken.source();
        setCancelToken(source);

        let lastLoaded = 0;
        let timeRemaining = 0;
        let lastTimestamp = Date.now();

        const extension = fileList[0].name.substring(fileList[0].name.lastIndexOf(".") + 1);
        const notificationKey = `upload-${Date.now()}`;

        // Notification initiale
        notification.info({
            key: notificationKey,
            message: "Transfert du fichier en cours...",
            description: (
                <>
                    <Text strong>{fileName}.{extension}</Text>
                    <Progress percent={0} status="active"/>
                    <Text>0 / {formatBytes(fileList[0].size)}</Text>
                </>
            ),
            duration: 0,
            btn: (
                <Button
                    danger
                    icon={<StopOutlined/>}
                    onClick={() => {
                        source.cancel("Le transfert a été annulé par l'utilisateur.");
                        notification.destroy(notificationKey);
                        setIsUploading(false);
                        setFileList([]);
                        setFileName("");
                    }}
                >
                    Annuler
                </Button>
            ),
        });

        try {
            await AddFichier({
                nom: defFileName,
                idFolder: idFolder,
                file: fileList[0],
                token: token,
                cancelToken: source.token,
                forceUpdate,
                onUploadProgress: (progress, loaded, total) => {
                    const now = Date.now();
                    const elapsed = now - lastTimestamp; // Temps écoulé depuis la dernière mise à jour
                    const speed = ((loaded - lastLoaded) / (elapsed / 1000)).toFixed(2); // Débit en bytes par seconde
                    lastLoaded = loaded;
                    lastTimestamp = now;

                    timeRemaining = speed > 0 ? (total - loaded) / speed : 0;

                    notification.info({
                        key: notificationKey,
                        message: "Transfert du fichier en cours...",
                        description: (
                            <>
                                <Text strong>{defFileName}.{extension}</Text>
                                <Progress percent={progress} status={progress < 100 ? "active" : "success"}/>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "10px"
                                }}>
                                    <div>
                                        <Typography.Text strong style={{color: "#4caf50"}}>
                                            {formatBytes(loaded)}
                                        </Typography.Text>
                                        <span> / </span>
                                        <Typography.Text strong>{formatBytes(total)}</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                        <ThunderboltOutlined style={{color: "#faad14"}}/>
                                        <Typography.Text style={{color: "#1890ff", fontWeight: "bold"}}>
                                            {formatBytes(speed)}/s
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div style={{marginTop: "10px", display: "flex", alignItems: "center", gap: "5px"}}>
                                    <HourglassOutlined style={{color: "#faad14"}}/>
                                    <Text style={{fontStyle: "italic"}}>Temps restant
                                        : {formatTimeRemaining(timeRemaining)}</Text>
                                </div>
                            </>
                        ),
                        duration: 0,
                        btn: (
                            <Button
                                danger
                                icon={<StopOutlined/>}
                                onClick={() => {
                                    source.cancel("Le transfert a été annulé par l'utilisateur.");
                                    notification.destroy(notificationKey);
                                    setIsUploading(false);
                                    setFileList([]);
                                    setFileName("");
                                }}
                            >
                                Annuler
                            </Button>
                        ),
                    });
                },
            });

            // Notification de succès
            notification.success({
                key: notificationKey,
                message: "Transfert réussi",
                description: (
                    <span>
            Le fichier <Text strong>{defFileName}.{extension}</Text> a été envoyé avec succès.
          </span>
                ),
                duration: 10,
                showProgress: true,
                pauseOnHover: true,
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                notification.warning({
                    key: notificationKey,
                    message: "Transfert annulé",
                    description: (
                        <span>
              Le transfert du fichier <Text strong>{defFileName}.{extension}</Text> a été annulé.
            </span>
                    ),
                    duration: 10,
                    showProgress: true,
                    pauseOnHover: true,
                });
            } else {
                notification.error({
                    key: notificationKey,
                    message: "Échec du transfert",
                    description: (
                        <span>
              {error.response?.data?.message || `Une erreur est survenue lors de l'envoi du fichier`}<br/><Text
                            strong>{defFileName}.{extension}</Text>.
            </span>
                    ),
                    duration: 10,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } finally {
            setIsUploading(false);
            setFileList([]);
            setFileName("");
            setCurrentStep(0)
            setCancelToken(null);
        }
    };

    const handleCancel = () => {
        setFileName("");
        setFileList([]);
        setCurrentStep(0)
        setIsModalVisible(false);
    };

    const handleInputChange = (e) => {
        setFileName(e.target.value);
    };

    const onFileChange = (info) => {
        const file = info.file.originFileObj || info.file;

        if (file) {
            setFileList([file]);
            handleFilePreview(file.name.substring(file.name.lastIndexOf(".") + 1));
        }
    };

    useEffect(() => {
        return () => {
            fileList.forEach((file) => {
                if (file.preview) {
                    URL.revokeObjectURL(file.preview);
                }
            });
        };
    }, [fileList]);

    const renderFooter = () => (
        <div>
            {currentStep === 0 && (
                <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                    Annuler
                </Button>
            )}
            {currentStep > 0 && (
                <Button style={{ marginRight: 8 }} onClick={handlePrev}>
                    <LeftOutlined /> Précédent
                </Button>
            )}
            {currentStep < 1 && (
                <Button type="primary" onClick={handleNext} disabled={!fileList.length}>
                    Suivant <RightOutlined />
                </Button>
            )}
            {currentStep === 1 && (
                <Button
                    type="primary"
                    onClick={handleAddFile}
                    loading={isUploading}
                    disabled={fileList.length === 0}
                >
                    <FileAddOutlined /> Ajouter
                </Button>
            )}
        </div>
    );

    return (
        <>
            <Button type={"default"} icon={<FileAddOutlined/>} onClick={showModal} className="btn-add-file">
                {title}
            </Button>

            <Modal
                title={
                    <span>
                        <UploadOutlined style={{color: "#4caf50", marginRight: "8px"}}/>
                        Ajouter un fichier
                    </span>
                }
                onCancel={handleCancel}
                footer={renderFooter()}
                open={isModalVisible}
                maskClosable={false}
            >
                <br/>
                <Alert
                    description={
                        <Text>Vous allez ajouter un fichier dans le répertoire <Text
                            strong>{folderName || ""}</Text></Text>
                    }
                    type="info"
                    showIcon
                />

                <Divider />

                {currentStep === 0 && (
                    <>
                        <Text>Merci de saisir le nom du fichier à ajouter :</Text>
                        <Input
                            value={fileName}
                            onChange={handleInputChange}
                            placeholder="Nom du fichier"
                            maxLength={255}
                            showCount
                            style={{marginTop: "10px"}}
                        />
                        <br/>
                        <br/>
                        <Text>Sélectionnez un fichier :</Text>
                        <Upload.Dragger
                            onChange={onFileChange}
                            fileList={fileList}
                            maxCount={1}
                            beforeUpload={() => false}
                            disabled={isUploading}
                            style={{marginTop: "10px"}}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined/>
                            </p>
                            <p className="ant-upload-text">Cliquez ou glissez-déposez un fichier ici</p>
                        </Upload.Dragger>
                    </>
                )}

                {currentStep === 1 && (
                    <>
                        {fileList.length > 0 ? (
                            <>
                                <Text>Taille du fichier : </Text><Text strong>{formatBytes(fileList[0].size)}</Text>
                                <FilePreview
                                    fileType={previewFileType}
                                    fileName={fileName || fileList[0].name}
                                    filePath={fileList.length > 0 ? URL.createObjectURL(fileList[0]) : ""}
                                />
                            </>
                        ) : (
                            <Alert description={"Veuillez ajouter un fichier avant de le visualiser"} type="warning" showIcon/>
                        )}
                    </>
                )}

                <Divider/>

                <Steps current={currentStep}>
                    <Step title="Informations" icon={<InfoCircleOutlined/>}/>
                    <Step title="Prévisualisation" icon={<FileTextOutlined/>}/>
                </Steps>
            </Modal>
        </>
    )
        ;
};

export default AddFile;
