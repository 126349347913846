import React, {useState, useEffect} from 'react';
import {Form, Input, Button, DatePicker, TimePicker, Checkbox, Tabs, Select, Row, Col, message} from 'antd';
import {useAuth} from "../../../context/AuthContext.js";
import './PlanningModalAdd.css';

import {SaveOutlined} from "@ant-design/icons";
import {TbMoodKid} from "react-icons/tb";
import {MdFamilyRestroom} from "react-icons/md";
import {FaBabyCarriage} from "react-icons/fa6";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;
const {Option, OptGroup} = Select;

const PlanningModalAdd = ({closeAddEventModal, refreshEvents}) => {
    const {user, token} = useAuth();
    const [loading, setLoading] = useState(false);
    const [contrats, setContrats] = useState([]);
    const [formPonctuelle] = Form.useForm();
    const [formHebdomadaire] = Form.useForm();
    const [contrat, setContrat] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    useEffect(() => {
        if (closeAddEventModal) {
            formPonctuelle.resetFields();
            formHebdomadaire.resetFields();
            setContrat(null);
        }
    }, [closeAddEventModal, activeTab, formPonctuelle, formHebdomadaire]);

    useEffect(() => {
        const fetchContrats = async () => {
            try {
                setLoading(true);
                let contratsList = [];

                if (isAssistantMaternel) {
                    // Si l'utilisateur est une assistante maternelle, récupérer tous les contrats
                    const response = await fetch('https://api.uama.fr/v1.0/contrat/index?limit=inf&option=all', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const data = await response.json();
                    contratsList = data.contrats;
                } else {
                    // Si l'utilisateur est un parent, faire des requêtes séparées pour "Actif" et "En préparation"
                    const options = ["Actif", "En préparation"];

                    for (let option of options) {
                        const response = await fetch(`https://api.uama.fr/v1.0/contrat/index?limit=inf&option=${option}`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        const data = await response.json();
                        contratsList = [...contratsList, ...data.contrats];
                    }
                }

                setContrats(contratsList);
            } catch (error) {
                console.error('Error fetching contrats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchContrats();
    }, [token, isAssistantMaternel]);

    const handleContratChange = (contratUUID) => {
        const selectedContrat = contrats.find(contrat => contrat.UUID === contratUUID);
        if (selectedContrat) {
            setContrat(selectedContrat);
        } else {
            setContrat(null);
        }
    };

    const renderOptions = () => {
        const statusOrder = ["Actif", "En préparation", "Terminé", "Statut inconnu"];

        // Regroupez les contrats par statut
        const groupedContrats = contrats.reduce((groups, contrat) => {
            const status = contrat.Statut || "Statut inconnu"; // Assurez-vous qu'il y a un statut
            if (!groups[status]) groups[status] = [];
            groups[status].push(contrat);
            return groups;
        }, {});

        const normalizeStatus = (status) => {
            return status
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
                .replace(/\s+/g, "_") // Remplace les espaces par des underscores ou des tirets
                .toLowerCase(); // Convertit en minuscule pour être cohérent
        };

        // Affichez chaque groupe dans l'ordre souhaité
        return statusOrder.map(status => (
            groupedContrats[status] ? (
                <OptGroup key={status} label={status} className={`custom-opt-group ${normalizeStatus(status)}`}>
                    {groupedContrats[status].map(contrat => (
                        <Option key={contrat.UUID} value={contrat.UUID}>
                            <TbMoodKid style={{color: "#4e89ff"}}/> {contrat.Enfant["Nom complet"]} (
                            {isAssistantMaternel ? (
                                <>
                                    <MdFamilyRestroom style={{color: "#ff7f50"}}/> {contrat.Enfant.Parent}
                                </>
                            ) : (
                                <>
                                    <FaBabyCarriage style={{color: "#ff7f50"}}/> {contrat["Assistante maternelle"]["Nom complet"]}
                                </>
                            )})
                        </Option>
                    ))}
                </OptGroup>
            ) : null
        ));
    };

    const onFinishPlanificationPonctuelle = async (values) => {
        values.date = values.date.format('DD-MM-YYYY');
        values.heureDebut = values.heureDebut.format('HH:mm:ss');
        values.heureFin = values.heureFin.format('HH:mm:ss');
        // Logique pour la planification ponctuelle
        onFinish(values);
    };

    const onFinishPlanificationHebdomadaire = async (values) => {
        // Logique pour la planification hebdomadaire

        console.log('values date fin : ', values.periode.dateDebut[1].format('DD-MM-YYYY'))
        values.contrat = values.contratHebdomadaire;
        values.periode.dateFin = values.periode.dateDebut[1].format('DD-MM-YYYY');
        values.periode.dateDebut = values.periode.dateDebut[0].format('DD-MM-YYYY');
        values.periode.heureDebut = values.heureDebutHebdomadaire.format('HH:mm:ss')
        values.periode.heureFin = values.heureFinHebdomadaire.format('HH:mm:ss')

        onFinish(values)
    };

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const response = await fetch('https://api.uama.fr/v1.0/planning/saisie', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(values)
            });

            if (response.ok) {
                const data = await response.json();
                closeAddEventModal();
                message.success(data.message);
                refreshEvents();
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Tabs defaultActiveKey="1" onChange={(key) => setActiveTab(key)}>
            <TabPane tab="Planification ponctuelle" key="1">
                <div className="form-container">
                    <Form
                        name="contrat"
                        layout="vertical"
                        onFinish={onFinishPlanificationPonctuelle}
                        form={formPonctuelle}
                    >
                        <Row justify="space-between">
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Contrat"
                                    name="contrat"
                                    rules={[{required: true, message: 'Veuillez choisir un contrat!'}]}
                                >
                                    <Select
                                        style={{width: '100%'}}
                                        showSearch
                                        placeholder="Rechercher un contrat"
                                        onChange={handleContratChange}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {renderOptions()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                {contrat && contrat["Assistante maternelle"]["Jours travaillés"].length > 0 && (
                                    <div>
                                        <p><strong>Jours travaillés :</strong> {contrat["Assistante maternelle"]["Jours travaillés"].join(', ')}</p>
                                    </div>
                                )}
                                {contrat && (
                                    <div style={{display: 'flex', justifyContent: "space-between" ,gap: '10px'}}>
                                        <p><strong>Début du contrat :</strong> {contrat["Date de début"]}</p>
                                        <p><strong>Fin du contrat :</strong> {contrat["Date de fin"]}</p>
                                    </div>
                                )}

                                <Form.Item
                                    label="Date"
                                    name="date"
                                    rules={[{required: true, message: 'Veuillez sélectionner une date!'}]}
                                >
                                    <DatePicker style={{width: ' 100%'}} format={dateFormats}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Heure de début"
                                    name="heureDebut"
                                    rules={[{required: true, message: 'Veuillez sélectionner une heure de début!'}]}
                                >
                                    <TimePicker style={{width: ' 100%'}} format="HH:mm" showNow={false} minuteStep={5} needConfirm={false}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={11}>

                                <Form.Item

                                    label="Heure de fin"
                                    name="heureFin"
                                    rules={[{required: true, message: 'Veuillez sélectionner une heure de fin!'}]}
                                >
                                    <TimePicker style={{width: ' 100%'}} format="HH:mm" showNow={false} minuteStep={5} needConfirm={false}/>
                                </Form.Item>
                            </Col>
                            <Form.Item>
                                <Button icon={<SaveOutlined/>} type="primary" htmlType="submit" loading={loading}
                                        className="form-button">
                                    Enregistrer
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </div>
            </TabPane>
            <TabPane tab="Planification hebdomadaire" key="2">
                <div className="form-container">
                    <Form
                        name="contrat-hebdo"
                        layout="vertical"
                        onFinish={onFinishPlanificationHebdomadaire}
                        form={formHebdomadaire}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="Contrat"
                                    name="contratHebdomadaire"
                                    rules={[{required: true, message: 'Veuillez choisir un contrat!'}]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Rechercher un contrat"
                                        onChange={handleContratChange}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {renderOptions()}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                {contrat && contrat["Assistante maternelle"]["Jours travaillés"].length > 0 && (
                                    <div>
                                        <p><strong>Jours travaillés :</strong> {contrat["Assistante maternelle"]["Jours travaillés"].join(', ')}</p>
                                    </div>
                                )}
                                {contrat && (
                                    <div style={{display: 'flex', justifyContent: "space-between" ,gap: '10px'}}>
                                        <p><strong>Début du contrat :</strong> {contrat["Date de début"]}</p>
                                        <p><strong>Fin du contrat :</strong> {contrat["Date de fin"]}</p>
                                    </div>
                                )}

                                <Form.Item
                                    label="Période"
                                    name={['periode', 'dateDebut']}
                                    rules={[{required: true, message: 'Veuillez sélectionner une date de début!'}]}
                                >
                                    <RangePicker style={{width: '100%'}} format={dateFormats}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label="Jours"
                            name={['periode', 'jours']}
                            rules={[{required: true, message: 'Veuillez sélectionner au moins un jour!'}]}
                        >
                            <Checkbox.Group
                                options={['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']}/>
                        </Form.Item>
                        <Row justify="space-between">
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Heure de début"
                                    name="heureDebutHebdomadaire"
                                    rules={[{required: true, message: 'Veuillez sélectionner une heure de début!'}]}
                                >
                                    <TimePicker style={{width: ' 100%'}} format="HH:mm" showNow={false} minuteStep={5} needConfirm={false}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={11}>

                                <Form.Item
                                    label="Heure de fin"
                                    name="heureFinHebdomadaire"
                                    rules={[{required: true, message: 'Veuillez sélectionner une heure de fin!'}]}
                                >
                                    <TimePicker style={{width: ' 100%'}} format="HH:mm" showNow={false} minuteStep={5} needConfirm={false}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button icon={<SaveOutlined/>} type="primary" htmlType="submit" loading={loading}
                                    className="form-button">
                                Enregistrer
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </TabPane>
        </Tabs>
    );
};

export default PlanningModalAdd;
