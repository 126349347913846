import React, { useState, useEffect } from 'react';
import { List, Statistic, Row, Col, Input, Radio, Modal, message } from 'antd';
import { useAuth } from '../../context/AuthContext';
import Contrat from '../../components/Contrat/Contrat';
import { motion } from 'framer-motion';
import './ContratPage.css';

import Loading from '../../components/Loading/Loading';
import ContratAdd from '../../components/Contrat/ContratAdd/ContratAdd';
import { useSidebar } from '../../context/SideBarContext';

const ContratsPage = () => {
  const { token, user } = useAuth();
  const [contrats, setContrats] = useState([]);
  const [filteredContrats, setFilteredContrats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSelect, setFilterSelect] = useState('Actif');

  useEffect(() => {
    if (!isLoading) {
      const queryParams = new URLSearchParams(window.location.search);
      const messageParam = queryParams.get('successMessage');
      if (messageParam) {
        message.success(messageParam);
        window.history.replaceState(null, '', window.location.pathname);
      }
    }
  }, [isLoading]);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.uama.fr/v1.0/contrat/index?limit=inf', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setContrats(data.contrats);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    const filteredData = contrats.filter((contrat) => {
      return contrat.Enfant['Nom complet'].toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredContrats(filterSelect === 'Tous' ? filteredData : filteredData.filter((contrat) => contrat.Statut === filterSelect));
  }, [contrats, searchTerm, filterSelect]);

  const changeFilter = (filter) => {
    setFilterSelect(filter);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { siderOpen } = useSidebar();

  const canAddContrat = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

  return (
    <div className={"contrat-page-container"}>
      {isLoading || !siderOpen ? (
        <Loading />
      ) : (
        <>
          <Row justify="center" className="filterSearch">
            <Col xs={9} sm={9} md={7} lg={4} xl={4} className="stats borderDark">
              <Statistic title="Nombre de contrats :" value={filteredContrats.length} />
            </Col>

            {canAddContrat && (
              <Col xs={9} sm={9} md={9} lg={4} xl={4}>
                <button className="buttonAdd borderDark" type="button" onClick={showModal}>
                  <span className="button__text">Ajouter un contrat</span>
                  <span className="button__icon"><svg className="svg" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><line x1="12" x2="12" y1="5" y2="19"></line><line x1="5" x2="19" y1="12" y2="12"></line></svg></span>
                </button>
              </Col>
            )}

            <Modal
              title="Ajout d'un contrat"
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              className='AjoutAddEditContrat'
            >
              <ContratAdd contrat="ajouter" />
            </Modal>

            <Col xs={24} sm={24} md={18} lg={11} xl={11}>
              <Row justify="center" className="filterSearch">
                <Col span={24}>
                  <Input className="" placeholder="Rechercher un contrat" onChange={(e) => setSearchTerm(e.target.value)} />
                </Col>
                <div className="filter">
                  <Radio.Group defaultValue="Actif" buttonStyle="solid" onChange={(e) => changeFilter(e.target.value)}>
                      <Radio.Button value="En préparation">En préparation</Radio.Button>
                      <Radio.Button value="Actif">Actif</Radio.Button>
                      <Radio.Button value="Terminé">Terminé</Radio.Button>
                      <Radio.Button value="Tous"><b>Tous contrats</b></Radio.Button>
                  </Radio.Group>
                </div>
              </Row>
            </Col>
          </Row>

          <List
            className={`contratList ${filteredContrats.length === 0 ? 'emptyList' : ''}`}
            grid={{
              gutter: 30,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 5,
            }}
            dataSource={filteredContrats}
            renderItem={(contrat, index) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.25 }}
                key={index}
              >
                <List.Item>
                  <Contrat
                      contrat={contrat}
                      onRefresh={fetchData}
                  />
                </List.Item>
              </motion.div>
            )}
          />
        </>
      )}
    </div>
  );
};

export default ContratsPage;
