import axios from "axios";
import {Button, notification, Progress, Typography} from "antd";
import React, {useState} from "react";
import {ThunderboltOutlined, HourglassOutlined, StopOutlined} from "@ant-design/icons";

const { Text } = Typography;

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const formatTimeRemaining = (seconds) => {
  if (seconds <= 0) return "Moins d'une seconde";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return minutes > 0
      ? `${minutes} min ${remainingSeconds} sec`
      : `${remainingSeconds} sec`;
};

export const Download = async (url, filename = "") => {
  const notificationKey = `download-${Date.now()}`;
  const cancelToken = axios.CancelToken.source()

  // Notification initiale
  notification.info({
    key: notificationKey,
    message: "Téléchargement en cours...",
    description: (
        <>
          <Text strong>{filename}</Text>
          <Progress percent={0} status="active" />
          <Text>0 / Inconnu</Text>
        </>
    ),
    duration: 0,
    btn: (
        <Button
            danger
            icon={<StopOutlined />}
            onClick={() => {
              cancelToken.cancel("Le transfert a été annulé par l'utilisateur.");
              notification.destroy(notificationKey);
            }}
        >
          Annuler
        </Button>
    ),
  });

  let lastLoaded = 0;
  let lastTimestamp = Date.now();

  try {
    const response = await axios.get(url, {
      responseType: "blob",
      cancelToken: cancelToken.token,
      onDownloadProgress: (progressEvent) => {
        if (progressEvent && progressEvent.total) {
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;
          const percentCompleted = Math.round((loaded * 100) / total);

          const now = Date.now();
          const elapsed = now - lastTimestamp; // Temps écoulé depuis la dernière mise à jour
          const speed = ((loaded - lastLoaded) / (elapsed / 1000)).toFixed(2); // Débit en bytes par seconde
          lastLoaded = loaded;
          lastTimestamp = now;

          let timeRemaining = speed > 0 ? (total - loaded) / speed : 0;

          // Mise à jour de la notification
          notification.info({
            key: notificationKey,
            message: "Téléchargement en cours...",
            description: (
                <>
                  <Text strong>{filename}</Text>
                  <Progress percent={percentCompleted} status="active"/>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px"
                  }}>
                    <div>
                      <Typography.Text strong style={{color: "#4caf50"}}>
                        {formatBytes(loaded)}
                      </Typography.Text>
                      <span> / </span>
                      <Typography.Text strong>{formatBytes(total)}</Typography.Text>
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                      <ThunderboltOutlined style={{color: "#faad14"}}/>
                      <Typography.Text style={{color: "#1890ff", fontWeight: "bold"}}>
                        {formatBytes(speed)}/s
                      </Typography.Text>
                    </div>
                  </div>
                  <div style={{marginTop: "10px", display: "flex", alignItems: "center", gap: "5px"}}>
                    <HourglassOutlined style={{color: "#faad14"}}/>
                    <Text style={{fontStyle: "italic"}}>Temps restant : {formatTimeRemaining(timeRemaining)}</Text>
                  </div>
                </>
            ),
            duration: 0,
            btn: (
                <Button
                    danger
                    icon={<StopOutlined />}
                    onClick={() => {
                      cancelToken.cancel("Le transfert a été annulé par l'utilisateur.");
                      notification.destroy(notificationKey);
                    }}
                >
                  Annuler
                </Button>
            ),
          });
        }
      },
    });

    // Création du lien pour télécharger le fichier
    const blob = new Blob([response.data]);
    const downloadLink = document.createElement("a");
    const fileUrl = downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = getFileNameFromUrl(url);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Création du lien explicite pour télécharger le fichier
    const manualDownloadLink  = (
        <a href={fileUrl} download={filename || getFileNameFromUrl(url)} style={{ color: "#1890ff" }}>
          Cliquez ici pour télécharger le fichier manuellement.
        </a>
    );

    // Notification de succès
    notification.success({
      key: notificationKey,
      message: "Téléchargement terminé",
      description: (
          <>
            <span>Le fichier <Text strong>{filename}</Text> a été téléchargé avec succès</span>
            <p style={{ margin: "0" }}>{manualDownloadLink}</p>
          </>
      ),
      duration: 10,
      showProgress: true,
      pauseOnHover: true,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      notification.warning({
        key: notificationKey,
        message: "Transfert annulé",
        description: (
            <span>
              Le téléchargement du fichier <Text strong>{filename}</Text> a été annulé.
            </span>
        ),
        duration: 10,
        showProgress: true,
        pauseOnHover: true,
      });
    } else {
      console.log(error);
      // Notification d'erreur
      notification.error({
        key: notificationKey,
        message: "Erreur de téléchargement",
        description: (
            <span>
          Une erreur est survenue lors du téléchargement de <Text strong>{filename}</Text>.
        </span>
        ),
        duration: 10,
        showProgress: true,
        pauseOnHover: true,
      });
    }
  }
};


// Fonction utilitaire pour extraire le nom du fichier de l'URL
const getFileNameFromUrl = (url) => {
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 1];
};

export const AddRepertoire = async ({ nom, parentId, token, forceUpdate }) => {
  const url = "https://api.uama.fr/v1.0/explorer/addRepertoire";

  try {
    const response = await fetch(url, {
      method: "POST", // Specify the method
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ nom, parentId }), // Convert the JavaScript object to a JSON string
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    forceUpdate();
  } catch (error) {}
};

export const DeleteRepertoire = async ({ idRepertoire, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/deleteRepertoire/${idRepertoire}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Repertoire deleted successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during deleting repertoire:", error);
  }
};

export const DeleteFichier = async ({ idFichier, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/deleteFichier/${idFichier}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Fichier deleted successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during deleting Fichier:", error);
  }
};

export const EditRepertoire = async ({ nom, idFolder, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/updateRepertoire/${idFolder}`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ nouveauNom: nom }),
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Repertoire rename successfully");

    forceUpdate();
  } catch (error) {
    console.error("Error during rename repertoire:", error);
  }
};

export const EditFichier = async ({ nom, idFile, file, token, forceUpdate }) => {
  
  const url = `https://api.uama.fr/v1.0/explorer/updateFichier/${idFile}`;

  const formData = new FormData();
  formData.append("nom", nom);
  formData.append("fichier", file);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("File updated successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during updating file:", error);
  }
};

export const AddFichier = async ({ nom, idFolder, file, token, cancelToken, forceUpdate, onUploadProgress }) => {
  const url = "https://api.uama.fr/v1.0/explorer/addFichier";

  const formData = new FormData();
  formData.append("nom", nom);
  formData.append("repertoireId", idFolder);
  formData.append("fichier", file.originFileObj || file);

  try {
    const response = await axios.post(url, formData, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent && progressEvent.total) {
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;
          const percentCompleted = Math.round((loaded * 100) / total);

          if (onUploadProgress)
            onUploadProgress(percentCompleted, loaded, total);
        } else {
          console.warn("Incomplete progress event:", progressEvent);
        }
      },
      cancelToken,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    forceUpdate();
    return response.data;
  } catch (error) {
    console.error("Error during upload:", error.message);
    throw error;
  }
};

export const MoveItem = async ({ itemId, newParentId, token }) => {
  const url = "https://api.uama.fr/v1.0/explorer/move";

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ itemId, newParentId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Erreur inconnue");
    }

    return await response.json();
  } catch (error) {
    console.error("Erreur lors du déplacement :", error.message);
    throw error;
  }
};





