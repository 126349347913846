import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "./PlanningModalEdit.css";
import { useAuth } from "../../../context/AuthContext";
import {
  Button,
  message,
  Form,
  Select,
  Checkbox,
  DatePicker,
  Row,
  Col,
  TimePicker,
  Tabs,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Option } = Select;

const PlanningModal = ({ selectedEvent, refreshEvents, cancelModalEdit }) => {
  dayjs.locale("fr");

  const { user } = useAuth();

  const isAssistanteMaternelle = user.Roles.includes(
    "ROLE_ASSISTANTE_MATERNELLE"
  );

  const { token } = useAuth();
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const [formData, setFormData] = useState({
    date: null,
    heureDebut: null,
    heureFin: null,
    heureComplementaire: "",
    status: "",
    sick: false,
  });

  const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

  const apiStat = selectedEvent.extendedProps.periode
    ? "periode-horaire"
    : "horaire";

  useEffect(() => {
    if (selectedEvent) {
      const newFormData = {
        date: dayjs(selectedEvent.startStr),
        heureDebut: dayjs(selectedEvent.startStr),
        heureFin: dayjs(selectedEvent.endStr),
        heureComplementaire: selectedEvent.extendedProps.heureComplementaire
          ? dayjs(selectedEvent.extendedProps.heureComplementaire, "HH:mm")
          : null,
        status: selectedEvent.extendedProps.status,
        sick: selectedEvent.extendedProps.malade,
      };
      setFormData(newFormData);
      form.setFieldsValue(newFormData);
    }
  }, [selectedEvent, form]);

  const handleFormChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const deleteArretMaladie = async () => {
    try {
      const eventId = parseInt(selectedEvent.extendedProps.eventId);
      const response = await fetch(
        `https://api.uama.fr/v1.0/planning/arret-maladie/${eventId}/delete`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.message);
        refreshEvents();
        cancelModalEdit();
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement :", error);
    }
  };

  const handleDeleteEvent = async (evenementPeriode) => {
    try {
      const eventId = parseInt(selectedEvent.extendedProps.eventId);
      const response = await fetch(
        `https://api.uama.fr/v1.0/planning/${evenementPeriode}/${eventId}/supprimer`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.message);
        refreshEvents();
        cancelModalEdit();
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement :", error);
    }
  };

  const handleUpdateEvent = async () => {
    try {
      if (formData.date && dayjs.isDayjs(formData.date)) {
        formData.date = formData.date.format("YYYY-MM-DD");
      }
      if (formData.heureDebut && dayjs.isDayjs(formData.heureDebut)) {
        formData.heureDebut = formData.heureDebut.format("HH:mm:ss");
      }
      if (formData.heureFin && dayjs.isDayjs(formData.heureFin)) {
        formData.heureFin = formData.heureFin.format("HH:mm:ss");
      }
      const eventId = parseInt(selectedEvent.extendedProps.eventId);
      if (formData.heureComplementaire === "") {
        formData.heureComplementaire = "00:00:00";
      } else {
        formData.heureComplementaire =
          formData.heureComplementaire.format("HH:mm");
      }

      const response = await fetch(
        `https://api.uama.fr/v1.0/planning/horaire/${eventId}/modifier`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.message);
        refreshEvents();
        cancelModalEdit();
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateEventPeriode = async () => {
    const eventId = parseInt(selectedEvent.extendedProps.eventId);
    try {
      const response = await fetch(
        `https://api.uama.fr/v1.0/planning/periode-horaire/${eventId}/modifier`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.message);
        refreshEvents();
        cancelModalEdit();
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const EditForm = () => {
    return (
      <>
        <Row justify="space-between">
          <Col xs={24} lg={11}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                { required: true, message: "Veuillez sélectionner une date!" },
              ]}
            >
              <DatePicker format={dateFormats} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={11}>
            <Form.Item label="Status :" name="status">
              <Select
                placeholder="Sélectionnez le statut"
                disabled={!isAssistanteMaternelle}
              >
                <Option value="Accepté">Accepté</Option>
                <Option value="Refusé">Refusé</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={24} lg={11}>
            <Form.Item
              label="Heure de début"
              name="heureDebut"
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner une heure de début!",
                },
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                showNow={false}
                needConfirm={false}
                minuteStep={5}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={11}>
            <Form.Item
              label="Heure de fin"
              name="heureFin"
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner une heure de fin!",
                },
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                showNow={false}
                needConfirm={false}
                minuteStep={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={24} lg={11}>
            <Form.Item
              label="Heure Complémentaire :"
              name="heureComplementaire"
            >
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                picker="time"
                disabled={!isAssistanteMaternelle}
                placeholder="Sélectionnez l'heure"
                showNow={false}
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={11}>
            <Form.Item label="Malade : " name="sick" valuePropName="checked">
              <Checkbox disabled={!isAssistanteMaternelle}>Malade</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={12} lg={8}>
            <Button
              type="primary"
              danger
              onClick={() => handleDeleteEvent("horaire")}
              icon={<DeleteOutlined />}
            >
              Supprimer
            </Button>
          </Col>
          <Col xs={12} lg={8}>
            <Button
              type="primary"
              onClick={handleUpdateEvent}
              icon={<EditOutlined />}
            >
              Modifier
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="modal-container">
      {!(
        selectedEvent.title.includes("- Absences prévues au contrat") ||
        selectedEvent.extendedProps.type.includes("holiday") ||
        selectedEvent.title.includes("Arrêt maladie")
      ) ? (
        selectedEvent.extendedProps.periode ? (
          <>
            <Form
              form={form}
              layout="vertical"
              initialValues={formData}
              onValuesChange={handleFormChange}
              className="mt-1 planningModalEdit"
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Cette Événement" key="1">
                  <EditForm />
                </TabPane>
                <TabPane tab="Tous les Événements" key="2">
                  <Form.Item label="Status :" name="status">
                    <Select
                      placeholder="Sélectionnez le statut"
                      disabled={!isAssistanteMaternelle}
                    >
                      <Option value="Accepté">Accepté</Option>
                      <Option value="Refusé">Refusé</Option>
                    </Select>
                  </Form.Item>
                  <Row justify="center" gutter={[16, 16]}>
                    <Col xs={12} lg={8}>
                      <Button
                        type="primary"
                        danger
                        onClick={() => handleDeleteEvent("periode-horaire")}
                        icon={<DeleteOutlined />}
                      >
                        Supprimer
                      </Button>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Button
                        type="primary"
                        onClick={handleUpdateEventPeriode}
                        icon={<EditOutlined />}
                      >
                        Modifier
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Form>
          </>
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              initialValues={formData}
              onValuesChange={handleFormChange}
              className="mt-1 planningModalEdit"
            >
              <EditForm />
            </Form>
          </>
        )
      ) : (
        selectedEvent && (
          <div className="event-details">
            {selectedEvent.title.includes("Arrêt maladie") ? (
              isAssistanteMaternelle ? (
                <div>
                  <p>
                    Voulez-vous vraiment effacer l'arrêt maladie du :{" "}
                    {dayjs(selectedEvent.startStr).format("DD/MM/YYYY")}
                  </p>
                  <Button key="cancel" onClick={cancelModalEdit}>
                    Annuler
                  </Button>
                  <Button type="primary" onClick={deleteArretMaladie} danger>
                    Effacer
                  </Button>
                </div>
              ) : (
                <p>
                  Arrêt maladie du :{" "}
                  {dayjs(selectedEvent.startStr).format("DD/MM/YYYY")}
                </p>
              )
            ) : (
              <p>
                <strong>Date : </strong>{" "}
                {dayjs(selectedEvent.startStr).format("DD/MM/YYYY")}
              </p>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default PlanningModal;
