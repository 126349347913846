import React, { useEffect, useState } from "react";
import {Card, Modal, Button, Spin, Alert, Divider} from "antd";
import {CheckCircleOutlined, LeftOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ROUTES } from "../../Routes/Routes";
import logo from "../../assets/logo.png";
import "./TermsPage.css";
import axios from "axios";
import Loading from "../../components/Loading/Loading";

const TermsPage = () => {
    const [terms, setTerms] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { user, latestTermsVersion, token, setUser, setHasAcceptedTerms, hasAcceptedTerms } = useAuth();

    const formatDateToFrench = (dateString) => {
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        return new Date(dateString).toLocaleDateString("fr-FR", options).replace(",", " à");
    };

    useEffect(() => {
        if (!latestTermsVersion) {
            return;
        }

        const fetchTerms = async () => {
            try {
                const response = await axios.get(`https://api.uama.fr/terms/${latestTermsVersion}/general`);
                setTerms(response.data);
                setLoading(false);
            } catch (err) {
                setError("Impossible de charger les CGU. Veuillez réessayer.");
                setLoading(false);
            }
        };

        fetchTerms();
    }, [latestTermsVersion]);

    const handleAccept = async () => {
        try {
            const response = await fetch("https://api.uama.fr/terms/accept",{
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if(response.status === 200) {
                setUser(data.user);
                setHasAcceptedTerms(true);
                Modal.success({
                    title: "CGU Acceptées",
                    content: "Merci d'avoir accepté les Conditions Générales d'Utilisation.",
                    onOk: () => navigate(ROUTES.HOME),
                });
            }
            else {
                Modal.error({
                    title: "Erreur",
                    content: data.status + " : " + data.message,
                });
            }
        } catch (err) {
            console.error(err);
            Modal.error({
                title: "Erreur",
                content: "Une erreur est survenue lors de l'acceptation des CGU. Veuillez réessayer.",
            });
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Loading />
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <Alert message="Erreur" description={error} type="error" showIcon />
            </div>
        );
    }

    return (
        <div className="terms-page">
            <Card className="terms-card" bordered>
                <div className="terms-header">
                    <img src={logo} alt="Logo UAMA" className="terms-logo"/>
                    <h1 className="terms-title">Conditions générales d'utilisation</h1>
                    {user && (
                        <p className="terms-greeting">
                            Bonjour <strong>{user["Nom complet"]}</strong>, merci de prendre connaissance des CGU.
                        </p>
                    )}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" , width: "100%", margin: "2vh 0 0 0" }}>
                        <p style={{ margin : "0"}}><strong>Version : </strong>{terms.version}</p>
                        <p style={{ margin : "0"}}><strong>Créé le : </strong>{formatDateToFrench(terms.created_at)}</p>
                    </div>
                </div>
                <Divider/>
                <div style={{maxHeight: "45vh", overflowY: "auto"}}>
                    {terms.sections.map((section, index) => (
                        <div key={index} className="terms-section">
                            <h2>{section.title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: section.content }} />
                        </div>
                    ))}
                </div>
                <Divider />
                {hasAcceptedTerms ? (
                    <Button type="primary" block icon={<LeftOutlined/>} onClick={() => navigate(ROUTES.HOME)}>
                        Retour à l'accueil
                    </Button>
                ) : (
                    <Button type="primary" block icon={<CheckCircleOutlined />} onClick={handleAccept}>
                        J'accepte
                    </Button>
                )}
            </Card>
        </div>
    );
};

export default TermsPage;
