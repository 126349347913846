import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import {ROUTES} from "./Routes";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, hasAcceptedTerms } = useAuth();

  if(!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }
  if(!hasAcceptedTerms) {
    return <Navigate to={ROUTES.TERMS} replace />;
  }

  return element
};

export default ProtectedRoute;
