import * as React from "react";
import { motion } from "framer-motion";
import * as AntIcons from "@ant-design/icons"; // Import all Ant Design icons

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ["#28a745", "#e73c7e", "#23a6d5", "#801336", "#F57D1F", "#113A5D"];

export const MenuItem = ({ i, headerLink }) => {
  const IconComponent = AntIcons[headerLink.icon];
  
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <a
        id="headerLink"
        href={headerLink.url}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="icon-placeholder" style={{ color: colors[i] }}>
          {React.createElement(IconComponent)}
        </div>
        <div className="text-placeholder" style={{ color: colors[i] }}>
          {headerLink.title}
        </div>
      </a>
    </motion.li>
  );
};
