import React, { useEffect, useState } from "react";
import Planning from "../../components/Planning/Planning";
import { Button, Col, Row } from "antd";
import "./Planningpage.css";
import { useAuth } from "../../context/AuthContext";
import useIsMobile from "../../hooks/useIsMobile";

const PlanningPage = () => {
    const [modalType, setModalType] = useState(null); // État pour le type de modal ouvert
    const [planningKey, setPlanningKey] = useState(0);
    const { user } = useAuth();
    const isAssistanteMaternelle = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");
    const isMobile = useIsMobile(1300);

    const openModal = (type) => {
        setModalType(type);
    };

    const closeModal = () => {
        setModalType(null);
    };

    useEffect(() => {
        setPlanningKey((prevKey) => prevKey + 1); // Change la clé pour forcer le re-render
    }, [isMobile]);

    return (
        <div className="planningContent">
            <Row justify="space-around" className="planningCol">
                {isMobile && (
                    <Row className="planning-mobile-btn">
                        <Button type="primary" onClick={() => openModal("addEvent")}>
                            + Ajouter un événement
                        </Button>
                        {isAssistanteMaternelle && (
                            <Button type="primary" onClick={() => openModal("maladie")}>
                                + Ajouter un arrêt maladie
                            </Button>
                        )}
                    </Row>
                )}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="view-container-bgWhite">
                        <Planning
                            key={planningKey}
                            modalType={modalType}
                            closeModal={closeModal}
                            openModal={openModal}
                            showBtnFC={true}
                            initialView="timeGridWeek"
                            addEvent={true}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PlanningPage;
