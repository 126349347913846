import { useState } from "react";

export const usePasswordStrength = () => {
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [isStrongEnough, setIsStrongEnough] = useState(false);
    const [missingCriteria, setMissingCriteria] = useState([]);

    const calculatePasswordStrength = (password) => {
        let strength = 0;
        const criteria = [];

        const hasUppercaseLetter = /[A-Z]/.test(password);
        const hasLowercaseLetter = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

        // Calcul de la force
        if (password.length >= 8) {
            if (hasUppercaseLetter) strength += 20;
            if (hasLowercaseLetter) strength += 20;
            if (hasNumber) strength += 20;
            if (hasSpecialChar) strength += 20;
            if (password.length >= 12) strength += 20; // Bonus pour la longueur
        } else {
            // Ajouter des points pour les critères remplis même si la longueur est insuffisante
            if (hasUppercaseLetter) strength += 10;
            if (hasLowercaseLetter) strength += 10;
            if (hasNumber) strength += 10;
            if (hasSpecialChar) strength += 10;

            // Ajouter une indication de critère manquant pour la longueur
            criteria.push("8 caractères ou plus");
        }

        // Critères manquants
        if (!hasUppercaseLetter) criteria.push("une majuscule");
        if (!hasLowercaseLetter) criteria.push("une minuscule");
        if (!hasNumber) criteria.push("un chiffre");
        if (!hasSpecialChar) criteria.push("un caractère spécial");

        // Mises à jour d'état
        setPasswordStrength(strength);
        setIsStrongEnough(strength >= 80);
        setMissingCriteria(criteria);
    };

    return { passwordStrength, isStrongEnough, missingCriteria, calculatePasswordStrength };
};
