import React from 'react';
import {Button, Col, Row} from 'antd';
import {DeleteOutlined, DownloadOutlined} from '@ant-design/icons';
import { MdOutlineDriveFileMove } from "react-icons/md";

const ActionButton = ({ icon, text, number, onClick, disabled, color }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%',
                width: '90%',
                borderRadius: '12px',
                border: '1px solid #d9d9d9',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                backgroundColor: '#f5f5f5',
                transition: 'all 0.3s ease',
                padding: '0 5px',
                margin: 'auto',
            }}
        >
            <div style={{fontSize: '34px', color: color }}>
                {icon}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', width: '100%', padding: '0 0 10px 0'}}>
                <span style={{fontSize: '12px', fontWeight: '500', color: '#595959', textAlign: 'center', margin: "0 5px"}}>{text}</span>
                <span style={{fontSize: '12px', fontWeight: '500', color: '#595959', textAlign: 'center'}}>({number})</span>
            </div>
        </Button>
    );
};

const ActionButtons = ({selectedItems, onMove, onDelete, onDownload, height, isMobile = false}) => {
    return (
        <Row style={isMobile ? { height: "100%" } : { height: height }}>
            <Col xs={8} style={{ padding: '0', margin: 'auto' }}>
                <ActionButton
                    icon={<DownloadOutlined />}
                    text={"Télécharger"}
                    number={selectedItems.filter(item => item.type === 'Fichier').length}
                    onClick={onDownload}
                    disabled={selectedItems.filter(item => item.type === 'Fichier').length === 0}
                    color="#1890ff"
                />
            </Col>
            <Col xs={8} style={{ padding: '0', margin: 'auto' }}>
                <ActionButton
                    icon={<MdOutlineDriveFileMove />}
                    text={"Déplacer"}
                    number={selectedItems.length}
                    onClick={onMove}
                    disabled={selectedItems.length === 0}
                    color="#1890ff"
                />
            </Col>
            <Col xs={8} style={{ padding: '0', margin: 'auto' }}>
                <ActionButton
                    icon={<DeleteOutlined />}
                    text={"Supprimer"}
                    number={selectedItems.length}
                    onClick={onDelete}
                    disabled={selectedItems.length === 0}
                    color="red"
                />
            </Col>
        </Row>
    );
};

export default ActionButtons;
