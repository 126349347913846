import {ROUTES} from "../Routes/Routes";

const originalFetch = window.fetch;

const fetchWithInterceptor = async (url, options = {}, navigate, setLatestTermsVersion, setHasAcceptedTerms) => {
    const response = await originalFetch(url, options);

    if (response.status === 403) {
        const data = await response.json();
        if (
            data.message ===
            "Vous devez accepter les dernières CGU pour accéder à cette ressource."
        ) {
            setHasAcceptedTerms(false);
            setLatestTermsVersion("reload")
            navigate(ROUTES.TERMS);
        }
    }

    return response;
};

export default fetchWithInterceptor;
