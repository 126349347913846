import React, { useState } from "react";
import {Modal, Button, Tooltip} from "antd";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";

// Supprimer un dossier
export const DeleteFolder = ({ name, id, token, forceUpdate, title = "" }) => {
    const { DeleteRepertoire } = require("../DocumentsApi.js");
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Gestion de l'ouverture du modal
    const handleClick = () => {
        setIsModalVisible(true);
    };

    // Confirmer la suppression
    const handleConfirmDelete = () => {
        DeleteRepertoire({
            idRepertoire: id,
            token: token,
            forceUpdate: forceUpdate,
        });
        setIsModalVisible(false);
    };

    // Annuler la suppression
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Tooltip title="Supprimer le répertoire et son contenu" placement="top">
                {title !== "" ? (
                    <div onClick={handleClick}>
                        <DeleteOutlined style={{ color: "#FF3232" }} />
                        <span style={{ marginLeft: "8px" }}>{title}</span>
                    </div>
                ) : (
                    <DeleteOutlined style={{ color: "#FF3232" }} onClick={handleClick} />
                )}
            </Tooltip>

            <Modal
                title={
                    <span>
                        <WarningOutlined style={{ color: "#faad14", marginRight: "8px" }} />
                        Supprimer le dossier "{name}"
                    </span>
                }
                open={isModalVisible}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button
                        key="delete"
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleConfirmDelete}
                    >
                        Supprimer
                    </Button>,
                ]}
                onCancel={handleCancel}
            >
                <p>
                    Voulez-vous vraiment supprimer le dossier{" "}
                    <b style={{ color: "#FF4D4F" }}>"{name}"</b> ?
                </p>
            </Modal>
        </>
    );
};

// Supprimer un fichier
export const DeleteFile = ({ name, id, token, forceUpdate, title = "" }) => {
    const { DeleteFichier } = require("../DocumentsApi.js");
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Gestion de l'ouverture du modal
    const handleClick = () => {
        setIsModalVisible(true);
    };

    // Confirmer la suppression
    const handleConfirmDelete = () => {
        DeleteFichier({
            idFichier: id,
            token: token,
            forceUpdate: forceUpdate,
        });
        setIsModalVisible(false);
    };

    // Annuler la suppression
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Tooltip title="Supprimer le fichier" placement="top">
                {title !== "" ? (
                    <div onClick={handleClick}>
                        <DeleteOutlined style={{ color: "#FF3232" }} />
                        <span style={{ marginLeft: "8px" }}>{title}</span>
                    </div>
                ) : (
                    <DeleteOutlined style={{ color: "#FF3232" }} onClick={handleClick} />
                )}
            </Tooltip>

            <Modal
                title={
                    <span>
            <WarningOutlined style={{ color: "#faad14", marginRight: "8px" }} />
            Supprimer le fichier "{name}"
          </span>
                }
                open={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button
                        key="delete"
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleConfirmDelete}
                    >
                        Supprimer
                    </Button>,
                ]}
                onCancel={handleCancel}
            >
                <p>
                    Voulez-vous vraiment supprimer le fichier{" "}
                    <b style={{ color: "#FF4D4F" }}>"{name}"</b> ?
                </p>
            </Modal>
        </>
    );
};
