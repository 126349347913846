import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { useAuth } from '../../context/AuthContext';
import {ROUTES} from "../../Routes/Routes";

const Navigations = () => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const headerLinks = [
    { title: "Accueil", url: ROUTES.HOME, icon: "HomeOutlined" },
    { title: "Planning", url: ROUTES.PLANNING, icon: "CarryOutOutlined" },
    { title: "Heures travaillées", url: ROUTES.HOURS_WORKED, icon: "ClockCircleOutlined" },
    { title: "Impots", url: ROUTES.IMPOTS, icon: "EuroOutlined" },
    { title: "Documents", url: ROUTES.DOCUMENTS, icon: "FolderOpenOutlined" },
    { title: "Contrats", url: ROUTES.CONTRATS, icon: "FormOutlined" },
  ];

  const headerLinksParents = [
    { title: "Accueil", url: ROUTES.HOME, icon: "HomeOutlined" },
    { title: "Planning", url: ROUTES.PLANNING, icon: "CarryOutOutlined" },
    { title: "Documents", url: ROUTES.DOCUMENTS, icon: "FolderOpenOutlined" },
    { title: "Contrats", url: ROUTES.CONTRATS, icon: "FormOutlined" },
  ];

  const headerLinksAdmins = [
    { title: "Accueil", url: ROUTES.HOME, icon: "HomeOutlined" },
    { title: "Utilisateurs", url: ROUTES.UTILISATEURS, icon: "TeamOutlined" },
  ];

  const { user } = useAuth();

  let linksToShow = [];
  if (user.Roles.includes("ROLE_ADMIN")) {
    linksToShow = headerLinksAdmins;
  } else if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
    linksToShow = headerLinks;
  } else {
    linksToShow = headerLinksParents;
  }

  const itemIds = linksToShow.map((_, index) => index);

  return (
      <motion.ul variants={variants}>
        {itemIds.map((i) => (
            <MenuItem i={i} key={i} headerLink={linksToShow[i]} />
        ))}
      </motion.ul>
  );
};

export default Navigations;
