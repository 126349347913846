export const fetchCitiesFromGeoAPI = async (query) => {
    const apiUrl = `https://geo.api.gouv.fr/communes?nom=${query}&fields=nom,codesPostaux&format=json&boost=population`;

    try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Erreur lors de la récupération des villes.");
        const data = await response.json();

        // Formatage des données pour inclure nom et codes postaux
        return data.flatMap((commune) =>
            commune.codesPostaux.map((codePostal) => ({
                value: `${commune.nom}, ${codePostal}`,
                label: `${commune.nom} (${codePostal})`,
                ville: commune.nom,
                codePostal,
            }))
        );
    } catch (error) {
        console.error("Erreur API GeoGouv :", error);
        return [];
    }
};

export const fetchZipCodesFromGeoAPI = async (query) => {
    const apiUrl = `https://geo.api.gouv.fr/communes?codePostal=${query}&fields=nom,codesPostaux&format=json&boost=population`;

    try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Erreur lors de la récupération des codes postaux.");
        const data = await response.json();

        // Formatage des données pour inclure nom et codes postaux
        return data.flatMap((commune) =>
            commune.codesPostaux.map((codePostal) => ({
                value: `${commune.nom}, ${codePostal}`,
                label: `${commune.nom} (${codePostal})`,
                ville: commune.nom,
                codePostal,
            }))
        );
    } catch (error) {
        console.error("Erreur API GeoGouv :", error);
        return [];
    }
};
