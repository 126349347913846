import React, { useState, useEffect } from 'react';
import {Row, Col, Progress, Tooltip, Typography, Card} from 'antd';
import { useDocuments } from '../../../context/DocumentsContext';
import FileExplorer from "../../../components/Documents/FileExplorer";
import '../DocumentsGlobal.css';
import Loading from '../../../components/Loading/Loading';
import { useAuth } from "../../../context/AuthContext";
import NoDocuments from "../NoDocuments";

const { Text } = Typography;

const DocumentsGlobal = () => {
  const { user } = useAuth();
  const { documents, forceUpdate, quota, error } = useDocuments();
  const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");

  let combinedData = [];

  if (error) {
    return (
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} md={18} lg={14} xl={10}>
            <NoDocuments
                isAssistantMaternel={isAssistantMaternel}
                context="global"
                message={error}
                forceUpdate={forceUpdate}
                subMessage={"Essayez de recharger ou contactez le support si le problème persiste."}
            />
          </Col>
        </Row>
    );
  }

  if (Array.isArray(documents) && documents.some(entry => entry.assistante_maternelle)) {
    // Cas Parent : Combiner les dossiers "Global" par assistante maternelle
    const globalFoldersByAssistante = documents.reduce((acc, entry) => {
      const assistanteName = entry.assistante_maternelle;
      if (!acc[assistanteName]) {
        const repertoireGlobal = entry.arborescence.contient.find(
            (item) => item["nom du répertoire"] === "Global"
        );

        if (repertoireGlobal) {
          acc[assistanteName] = {
            id: repertoireGlobal.id,
            "nom du répertoire": `${assistanteName} - Global`,
            taille: repertoireGlobal.taille,
            modifyAt: repertoireGlobal.modifyAt,
            contient: repertoireGlobal.contient || [],
            fichiers: repertoireGlobal.fichiers || [],
          };
        }
      }
      return acc;
    }, {});

    combinedData = Object.values(globalFoldersByAssistante);

  } else {
    // Cas Assistante Maternelle : Pas de regroupement par assistante
    const repertoireGlobal = documents?.contient?.find(
        (item) => item["nom du répertoire"] === "Global"
    );

    combinedData = repertoireGlobal ? [repertoireGlobal] : [];
  }

  if(!documents) {
    return <Loading />;
  }
  else if (combinedData.length === 0) {
    return (
      <Row justify="center" gutter={[16, 16]}>
        <Col xs={24} md={18} lg={14} xl={10}>
          <NoDocuments isAssistantMaternel={isAssistantMaternel} context={"global"} forceUpdate={forceUpdate}/>;
        </Col>
      </Row>
    )
  }

  return (
      <div className="documentContent">
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={22} xl={22} className="documentsCol">
            <FileExplorer
                data={isAssistantMaternel ? [...(combinedData[0].contient || []), ...(combinedData[0].fichiers || [])] : combinedData}
                rootFolderName={isAssistantMaternel ? combinedData[0]["nom du répertoire"] : "Tous les documents globaux"}
                rootFolderId={isAssistantMaternel ? combinedData[0].id : -1}
                forceUpdate={forceUpdate}
                rootSize={isAssistantMaternel ? combinedData[0].taille : 0}
                quota={quota}
            />
          </Col>
        </Row>
      </div>
  );
};

export default DocumentsGlobal;
