import React, { useState } from 'react';
import { Form, DatePicker, Button, message } from 'antd';
import { useAuth } from "../../../context/AuthContext.js";
import { SaveOutlined } from "@ant-design/icons";

const PlanningModalArretMaladie = ({ closeEventModalMaladie, refreshEvents }) => {
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const startDate = values.dateRange[0].format("YYYY-MM-DD");
            const endDate = values.dateRange[1].format("YYYY-MM-DD");
            const payload = {
                dateDebut: startDate,
                dateFin: endDate
            };

            const response = await fetch("https://api.uama.fr/v1.0/planning/arret-maladie/saisie", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                closeEventModalMaladie();
                message.success(data.message);
                refreshEvents();
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    return (
        <Form
            name="sickLeaveForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <Form.Item
                name="dateRange"
                label="Période de l'arrêt maladie"
                rules={[{ required: true, message: 'Veuillez sélectionner la période de l\'arrêt maladie' }]}
            >
                <DatePicker.RangePicker format={dateFormats} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    loading={loading}
                >
                    Enregistrer
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PlanningModalArretMaladie;
