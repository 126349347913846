import React, { useState } from 'react';
import { Card, Modal, Tag } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './Contrat.css';
import { ContratInfo } from './ContratInfo/ContratInfo';
import ContratEdit from './ContratEdit/ContratEdit';
import { useAuth } from '../../context/AuthContext';

const { Meta } = Card;

const Contrat = ({ contrat, onRefresh = null }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const { user } = useAuth();

    const handleInfoClick = () => {
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const handleEditClick = () => {
        setModalEditVisible(true);
    };

    const handleModalEditCancel = () => {
        setModalEditVisible(false);
    };

    const canEditContrat = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

    // Couleurs de tag pour chaque statut
    const statusColor = {
        'Actif': 'green',
        'En préparation': 'blue',
        'Terminé': 'red'
    };

    return (
        <>
            <Card
                className="contrat-card"
                cover={
                    <div className="cover-container">
                        <img src={contrat.Photo} className="enfant-photo" alt={contrat.Enfant['Nom complet']} />
                        <Tag color={statusColor[contrat.Statut]} className="status-tag">
                            {contrat.Statut}
                        </Tag>
                    </div>
                }
                actions={[
                    canEditContrat && (
                        <EditOutlined
                            key="edit"
                            style={{ color: "#333" }}
                            onClick={handleEditClick}
                        />
                    ),
                    <InfoCircleOutlined
                        key="info"
                        style={{ color: "#0054ed" }}
                        onClick={handleInfoClick}
                    />,
                ].filter(Boolean)}
            >
                <Meta
                    title={<div className="title">{contrat.Enfant['Nom complet']}</div>}
                    description={
                        <div className="info-section">
                            <p><strong>Âge :</strong> {contrat.Enfant.Age}</p>
                            <p><strong>Date de début :</strong> {contrat['Date de début']}</p>
                            <p><strong>Date de fin :</strong> {contrat['Date de fin']}</p>
                        </div>
                    }
                />
            </Card>

            <Modal
                title="Information du contrat"
                open={modalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width={800}
            >
                <ContratInfo contrat={contrat} />
            </Modal>

            <Modal
                className='AjoutAddEditContrat'
                title="Edition d'un contrat"
                open={modalEditVisible}
                onCancel={handleModalEditCancel}
                footer={null}
            >
                <ContratEdit
                    contrat={contrat}
                    onClose={handleModalEditCancel}
                    refreshContracts={onRefresh}
                />
            </Modal>
        </>
    );
};

export default Contrat;
