import React, {useState} from 'react';
import { Empty, Typography, Button } from 'antd';
import {CloudOutlined, LeftOutlined, ReloadOutlined} from '@ant-design/icons';
import './NoDocuments.css';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../Routes/Routes";

const { Text } = Typography;

const NoDocuments = ({ isAssistantMaternel = false, context = 'global', message = null, subMessage = null, forceUpdate}) => {
    const navigate = useNavigate();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefresh = () => {
        setIsRefreshing(true);
        forceUpdate();
        setTimeout(() => setIsRefreshing(false), 1000);
    };

    const getEmptyMessage = () => {
        if (message) {
            return message;
        }

        if (context === 'global') {
            return isAssistantMaternel
                ? "Tous vos documents globaux sont actuellement vides."
                : "Vous n'avez pas accès à des documents globaux en l'absence de contrat actif.";
        }

        if (context === 'personnel') {
            return isAssistantMaternel
                ? "Votre espace personnel est vide pour le moment."
                : "Vous n'avez pas d'espace personnel attribué.";
        }

        if (context === 'contrat') {
            return isAssistantMaternel
                ? "Aucun document n'est disponible pour ce contrat spécifique."
                : "Vous n'avez pas accès aux documents pour ce contrat spécifique.";
        }

        return "Aucun document disponible.";
    }

    const getSubMessage = () => {
        if(subMessage) {
            return subMessage;
        }

        if (context === 'global' && !isAssistantMaternel) {
            return "Vérifiez si vous avez des contrats actifs pour accéder aux documents globaux.";
        }

        if (context === 'contrat' && !isAssistantMaternel) {
            return "Assurez-vous que le contrat est actif pour consulter les documents associés.";
        }

        return null
    };

    const iconColor = message ? '#ff4d4f' : '#1890ff';

    return (
        <div className="no-documents-container">
            <Empty
                image={
                    <CloudOutlined
                        style={{
                            fontSize: '64px',
                            color: iconColor,
                            marginBottom: '16px',
                        }}
                    />
                }
                description={
                    <>
                        <Text style={{ fontSize: '18px', fontWeight: 500, color: '#555' }}>
                            {getEmptyMessage()}
                        </Text>
                        {!isAssistantMaternel && (
                            <Text style={{ display: 'block', marginTop: '8px', fontSize: '14px', color: '#888' }}>
                                {getSubMessage()}
                            </Text>
                        )}
                    </>
                }
            />
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: '16px', flexWrap: 'wrap', gap: '16px'}}>
                <Button type="primary" size="large" onClick={() => navigate(ROUTES.DOCUMENTS)}>
                    <LeftOutlined style={{ marginRight : "5px"}}/> Retour à l'accueil
                </Button>
                <Button type="primary" size="large" onClick={handleRefresh} loading={isRefreshing}>
                    <ReloadOutlined style={{ marginRight : "5px"}}/> Rafraîchir
                </Button>
            </div>
        </div>
    );
};

export default NoDocuments;
