import React, { useState } from "react";
import {Modal, TreeSelect, Button, Typography, Tooltip, message, Alert} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MdOutlineDriveFileMove } from "react-icons/md";
import { MoveItem } from "../DocumentsApi";

const { Text } = Typography;

const MoveItemIcon = ({ itemId, currentFolderId, token, treeData, forceUpdate, nomItem= "", type = "", title = "" }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [targetFolderId, setTargetFolderId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getMsgTooltip = type.toLowerCase() === 'dossier'
        ? "Déplacer le répertoire et son contenu"
        : type.toLowerCase() === 'fichier'
            ? "Déplacer le fichier"
            : "Déplacer l'élément"


    // Fonction pour valider et effectuer le déplacement
    const handleMove = async () => {
        if (!targetFolderId) {
            message.error("Veuillez sélectionner un répertoire cible.");
            return;
        }
        if (targetFolderId === currentFolderId) {
            message.warning("L'élément est déjà dans ce répertoire.");
            return;
        }

        setIsLoading(true);
        try {
            await MoveItem({
                itemId: itemId,
                newParentId: targetFolderId,
                token: token,
            });
            message.success("L'élément a été déplacé avec succès !", 5);
            setIsModalVisible(false);
            setTargetFolderId(null);
            forceUpdate(); // Mettre à jour l'explorateur
        } catch (error) {
            message.error("Erreur lors du déplacement : " + (error?.message || "inconnue"), 5);
        } finally {
            setIsLoading(false);
        }
    };

    // Annuler l'action et réinitialiser l'état
    const handleCancel = () => {
        setIsModalVisible(false);
        setTargetFolderId(null);
    };

    // Afficher le modal
    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    return (
        <>
            <Tooltip title={getMsgTooltip} placement="top">
                {title !== "" ? (
                    <div onClick={handleShowModal}>
                        <MdOutlineDriveFileMove style={{ color: "#4E89FF" }} className="moveItemIcon" />
                        <span style={{ marginLeft: "8px" }}>{title}</span>
                    </div>
                ) : (
                    <MdOutlineDriveFileMove style={{ color: "#4E89FF" }} onClick={handleShowModal} className="moveItemIcon" />
                )}
            </Tooltip>

            <Modal
                title={
                    <span>
                        <ArrowRightOutlined style={{ marginRight: "8px", color: "#4E89FF" }} />
                        Déplacer l'élément
                    </span>
                }
                open={isModalVisible}
                onOk={handleMove}
                onCancel={handleCancel}
                confirmLoading={isLoading}
                okText="Déplacer"
                cancelText="Annuler"
                maskClosable={false}
            >
                <Alert
                    description={
                        <Text>Vous allez déplacer le <Text strong>{ type.toLowerCase() }</Text> appelé "<Text strong>{ nomItem }</Text>" dans un autre répertoire </Text>
                    }
                    type="info"
                    showIcon
                />

                <p style={{ margin: '15px 0' }}>
                    Sélectionnez un répertoire cible pour déplacer cet élément. Si le répertoire actuel est sélectionné, aucun déplacement ne sera effectué.
                </p>
                <TreeSelect
                    style={{ width: "100%" }}
                    value={targetFolderId}
                    onChange={(value) => setTargetFolderId(value)}
                    treeData={treeData}
                    placeholder="Sélectionnez un répertoire cible"
                    treeDefaultExpandAll
                    allowClear
                />
            </Modal>
        </>
    );
};

export default MoveItemIcon;
