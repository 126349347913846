import React, { useEffect, useState } from 'react';
import { CoffeeOutlined, SunOutlined, MoonOutlined, UserOutlined } from '@ant-design/icons';
import { MdFamilyRestroom, MdManageAccounts, MdSupervisorAccount } from "react-icons/md";
import { FaBabyCarriage } from "react-icons/fa6";
import './CardWelcome.css';
import {Space} from "antd";

const CardWelcome = (props) => {
    const { nomComplet, role, derniereConnexion } = props;

    const [currentDate, setCurrentDate] = useState(new Date());
    const [greeting, setGreeting] = useState("Bonjour");
    const [timeIcon, setTimeIcon] = useState(<SunOutlined style={{ color: "#FFA500" }} className="time-icon" />);
    const [animateGreeting, setAnimateGreeting] = useState(false); // Nouvel état pour l'animation de la salutation
    const isAdmin = role.includes("ROLE_ADMIN");

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 60000); // Mettre à jour toutes les minutes
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const hour = currentDate.getHours();
        let newGreeting;
        let newTimeIcon;

        if (hour >= 5 && hour < 12) {
            newGreeting = "Bonjour";
            newTimeIcon = <SunOutlined style={{ color: "#FFA500" }} className="time-icon" />;
        } else if (hour >= 12 && hour < 18) {
            newGreeting = "Bon après-midi";
            newTimeIcon = <CoffeeOutlined style={{ color: "#8B4513" }} className="time-icon" />;
        } else {
            newGreeting = "Bonsoir";
            newTimeIcon = <MoonOutlined style={{ color: "#1E90FF" }} className="time-icon" />;
        }

        // Si le message de salutation change, déclencher l'animation
        if (newGreeting !== greeting) {
            setAnimateGreeting(true); // Activer l'animation
            setTimeout(() => setAnimateGreeting(false), 1000); // Désactiver après 1 seconde
        }

        setGreeting(newGreeting);
        setTimeIcon(newTimeIcon);
    }, [currentDate]);

    let roleIcon;
    if (role.includes("ROLE_PARENT")) {
        roleIcon = <MdFamilyRestroom className="icon" />;
    } else if (role.includes("ROLE_ASSISTANTE_MATERNELLE")) {
        roleIcon = <FaBabyCarriage className="icon" />;
    } else if (role.includes("ROLE_ADMIN")) {
        roleIcon = <MdManageAccounts className="icon" />;
    } else if (role.includes("ROLE_GESTIONNAIRE")) {
        roleIcon = <MdSupervisorAccount className="icon" />;
    } else {
        roleIcon = <UserOutlined className="icon" />;
    }

    // Message pour la fin de mois, prioritaire et constant en fin de mois
    const endOfMonthMessage = role.includes("ROLE_PARENT")
        ? "N'oubliez pas de vérifier et de préparer les paiements de fin de mois."
        : role.includes("ROLE_ASSISTANTE_MATERNELLE")
            ? "Fin de mois ! Vérifiez vos contrats et paiements pour une gestion optimale."
            : role.includes("ROLE_ADMIN")
                ? "Fin de mois ! Assurez-vous que toutes les statistiques et rapports sont à jour."
                : role.includes("ROLE_GESTIONNAIRE")
                    ? "Fin de mois ! Pensez à accompagner les utilisateurs dans leurs démarches administratives."
                    : "Fin de mois ! Assurez-vous que tous les rapports et paiements sont à jour.";

    // Message basé sur la saison actuelle
    const generateSeasonMessage = () => {
        if ([2, 3, 4].includes(currentDate.getMonth())) {
            return "Le printemps arrive ! Profitez des beaux jours pour organiser des activités extérieures.";
        } else if ([5, 6, 7].includes(currentDate.getMonth())) {
            return "L'été est là ! Pensez aux activités en plein air et à bien rester hydraté.";
        } else if ([8, 9, 10].includes(currentDate.getMonth())) {
            return "L'automne est arrivé, avec ses journées fraîches. Parfait pour des activités créatives à l'intérieur !";
        } else {
            return "L'hiver est là. Profitez des moments cocooning et préparez des activités au chaud.";
        }
    };

    // Message basé sur le jour de la semaine
    const generateDayMessage = () => {
        const dayOfWeek = currentDate.toLocaleDateString('fr-FR', { weekday: 'long' });
        if (dayOfWeek === 'samedi' || dayOfWeek === 'dimanche') {
            return role.includes("ROLE_ADMIN")
                ? "Bon week-end ! Profitez-en pour analyser les performances et planifier les prochaines améliorations de la plateforme."
                : role.includes("ROLE_GESTIONNAIRE")
                    ? "Bon week-end ! Prenez un moment pour réfléchir à de nouvelles idées pour soutenir les utilisateurs."
                    : "Bon week-end ! Profitez de ce moment pour vous reposer et vous ressourcer.";
        } else if (dayOfWeek === 'lundi') {
            return role.includes("ROLE_ADMIN")
                ? "Un bon début de semaine pour vérifier l'état des systèmes et prioriser les tâches importantes."
                : role.includes("ROLE_GESTIONNAIRE")
                    ? "Bon début de semaine ! Assurez-vous que tout est prêt pour accompagner les assistantes maternelles et les parents."
                    : "Bon début de semaine ! Prêt à organiser la semaine de garde ?";
        } else if (dayOfWeek === 'vendredi') {
            return role.includes("ROLE_ADMIN")
                ? "Bon vendredi ! Vérifiez les tâches en cours et assurez-vous que la plateforme est prête pour le week-end."
                : role.includes("ROLE_GESTIONNAIRE")
                    ? "Bon vendredi ! Prenez le temps de résoudre les dernières questions avant la fin de la semaine."
                    : "Bon vendredi ! La semaine se termine, vérifiez les plannings pour la semaine prochaine.";
        }
        return role.includes("ROLE_ADMIN")
            ? "Assurez-vous que tous les systèmes fonctionnent et planifiez les prochaines étapes pour la plateforme."
            : role.includes("ROLE_GESTIONNAIRE")
                ? "Pensez à vérifier les besoins des utilisateurs et à planifier vos priorités pour la semaine."
                : "Pensez à consulter les créneaux de garde de la semaine pour une organisation sereine.";
    };

    // État pour le message personnalisé, initialisé à la fin de mois si applicable
    const [currentMessage, setCurrentMessage] = useState(
        currentDate.getDate() > 25 ? endOfMonthMessage : generateSeasonMessage()
    );
    const [animateMessage, setAnimateMessage] = useState(false);

    // Effet pour changer le message toutes les 10 secondes, sauf fin de mois
    useEffect(() => {
        if (currentDate.getDate() > 25) {
            setCurrentMessage(endOfMonthMessage); // Fin de mois prioritaire
        } else {
            const timer = setTimeout(() => {
                setAnimateMessage(true);
                setCurrentMessage((prevMessage) =>
                    prevMessage === generateSeasonMessage() ? generateDayMessage() : generateSeasonMessage()
                );

                setTimeout(() => setAnimateMessage(false), 1000); // Arrête l'animation après 1 seconde
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [currentMessage, currentDate.getDate()]);

    return (
        <div className={`card-welcome ${isAdmin ? "card-welcome-admin" : ""}`}>
            {roleIcon}
            <div className="welcome-message">
                <p className={`title ${animateGreeting ? 'animate-greeting' : ''}`}>
                    {timeIcon} {greeting} {nomComplet}
                </p>
                <p className="date">
                    {currentDate.toLocaleDateString('fr-FR', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </p>
                <p className="last-login">
                    Dernière connexion : {derniereConnexion}
                </p>
                <p className={`personalized-message ${animateMessage ? 'animate-message' : ''}`}>
                    {currentMessage}
                </p>
            </div>
        </div>
    );
};

export default CardWelcome;
