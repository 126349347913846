import React, { useEffect, useState } from "react";
import { Layout, Menu, Badge, Button } from "antd";
import { UserOutlined, BellOutlined, MenuOutlined, FileTextOutlined } from "@ant-design/icons";
import { useSidebar } from "../../context/SideBarContext";
import { useNotification } from "../../context/NotificationContext";
import { useAuth } from "../../context/AuthContext";
import "./Sidebar.css";
import useIsMobile from "../../hooks/useIsMobile";
import { ROUTES } from "../../Routes/Routes";

const { Sider } = Layout;

const Sidebar = () => {
    const { siderOpen, headerOpen, setSiderOpen, setHeaderOpen } = useSidebar();
    const { notifications, markNotificationsAsRead } = useNotification();
    const { user } = useAuth();
    const isMobile = useIsMobile(1024);

    const [sidebarKey, setSidebarKey] = useState(0);
    useEffect(() => {
        setSidebarKey((prevKey) => prevKey + 1);
    }, [isMobile]);

    const toggleSidebar = () => {
        setSiderOpen(!siderOpen);
    };

    const items = [
        {
            label: user["Nom complet"],
            key: "user",
            icon: <UserOutlined className="iconSidebar" />,
            children: [
                { label: "Modifier le profil", key: "edit", onClick: () => (window.location.href = ROUTES.EDIT_PROFILE) },
                { label: "Se déconnecter", key: "logout", onClick: () => (window.location.href = ROUTES.LOGOUT) },
            ],
        },
        {
            label: "Notifications",
            key: "notif",
            onMouseEnter: markNotificationsAsRead,
            className: "notification",
            icon: (
                <Badge count={notifications.nbr_notification} size="small">
                    <BellOutlined className="iconSidebar" />
                </Badge>
            ),
            children: (notifications.notifications || []).map((notif, index) => ({
                label: notif.Message,
                key: `notification_${index}`,
            })),
        },
    ];

    return (
        <div className={`sidebar-container ${siderOpen ? "hidden" : ""} ${isMobile ? "sidebar-mobile" : ""}`}>
            {isMobile && (
                <Button className="menuButton" onClick={toggleSidebar}>
                    <Badge count={notifications.nbr_notification} size="small" offset={[15, 0]}>
                        <UserOutlined className="iconSideBarBtn" />
                    </Badge>
                </Button>
            )}
            <Sider
                key={sidebarKey}
                collapsible
                collapsed={siderOpen}
                onMouseEnter={() => setSiderOpen(false)}
                onMouseLeave={() => setSiderOpen(true)}
                onCollapse={(value) => {
                    if (headerOpen) {
                        setHeaderOpen(false);
                        setTimeout(() => {
                            setSiderOpen(value);
                        }, "600");
                    } else {
                        setSiderOpen(value);
                    }
                }}
                theme="light"
                reverseArrow="true"
                collapsedWidth={isMobile ? 0 : 50}
                width={isMobile ? 250 : 300}
            >
                <Menu theme="light" mode="inline" items={items} selectable={false} />

                <Menu theme="light" mode="inline" selectable={false} className="menu-footer">
                    <Menu.Item
                        key="terms"
                        icon={<FileTextOutlined className="iconSidebar" />}
                        onClick={() => (window.location.href = ROUTES.TERMS)}
                    >
                        {!siderOpen && "C.G.U"}
                    </Menu.Item>
                </Menu>
            </Sider>
        </div>
    );
};

export default Sidebar;
