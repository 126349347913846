import React, { useState, useEffect } from "react";
import {Form, Input, DatePicker, Button, Steps, Divider, message, Alert, AutoComplete, Checkbox} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import dayjs from 'dayjs';

import axios from "axios";

import moment from "moment";
import "moment/locale/fr";
import logo from "../../../assets/logo.png";
import {FileTextOutlined, HomeOutlined, InfoCircleOutlined, UserOutlined} from "@ant-design/icons";
import { fetchCitiesFromGeoAPI, fetchZipCodesFromGeoAPI} from "../../../utils/cityAndZipCodeUtils";
import {MdFamilyRestroom, MdManageAccounts, MdSupervisorAccount} from "react-icons/md";
import {FaBabyCarriage} from "react-icons/fa6";

import './EditProfil.css'
import useAddClassOnPath from "../../../hooks/useAddClassOnPath";
import {ROUTES} from "../../../Routes/Routes";

moment.locale("fr");

const { Item } = Form;
const { Step } = Steps;

const EditProfil = () => {

    const { user, token, setUser } = useAuth();
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false); // Ajouter l'état loading
    const navigate = useNavigate();
    const isParent = !user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");
    const [options, setOptions] = useState([]);
    const [greeting, setGreeting] = useState("Bonjour");
    const [currentDate, setCurrentDate] = useState(new Date());

    useAddClassOnPath("bodyContent", "file-explorer-active", ROUTES.EDIT_PROFILE);

    const roleIcons = {
        ROLE_PARENT: <MdFamilyRestroom className="icon" />,
        ROLE_ASSISTANTE_MATERNELLE: <FaBabyCarriage className="icon" />,
        ROLE_ADMIN: <MdManageAccounts className="icon" />,
        ROLE_GESTIONNAIRE: <MdSupervisorAccount className="icon" />,
        default: <UserOutlined className="icon" />,
    };

    const roleMessages = {
        ROLE_PARENT: "Profitez de cette page pour simplifier vos échanges avec votre assistante maternelle et gérer les informations liées à la garde de vos enfants.",
        ROLE_ASSISTANTE_MATERNELLE: "Mettez à jour vos informations pour simplifier vos démarches administratives et assurer une gestion efficace des contrats et plannings.",
        ROLE_ADMIN: "En tant qu'administrateur du site, utilisez cette page pour gérer vos informations personnelles et administratives afin de maintenir une plateforme fonctionnelle et à jour.",
        ROLE_GESTIONNAIRE: "En tant que gestionnaire, utilisez cette page pour gérer vos informations et répondre aux besoins des assistantes maternelles, des parents et des questions administratives ou liées à la petite enfance.",
        default: "Utilisez cette page pour mettre à jour vos informations personnelles.",
    };

    const role = user.Roles.find((r) => roleMessages[r]) || "default";
    const roleIcon = roleIcons[role] || roleIcons.default;
    const roleMessage = roleMessages[role];

    const dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY'];

    //FormData pour stocker les valeur des form pour les différentes pages.
    const handleNext = () => {
        form
            .validateFields()
            .then((values) => {
                setFormData({ ...formData, ...values }); // Merge values into formData
                setCurrentStep(currentStep + 1);
            })
            .catch((error) => {
                console.log("Validation Error:", error);
                message.error("Veuillez remplir correctement tous les champs.");
            });
    };

    // Gestion des salutations dynamiques
    useEffect(() => {
        const hour = currentDate.getHours();
        if (hour >= 5 && hour < 12) {
            setGreeting("Bonjour");
        } else if (hour >= 12 && hour < 18) {
            setGreeting("Bon après-midi");
        } else {
            setGreeting("Bonsoir");
        }
    }, [currentDate]);

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    //values pour les 3 valeurs de la dernières parties
    const onFinish = async (values) => {
        try {
            const response = await axios.put(
                `https://api.uama.fr/v1.0/user/update/${user.UUID}`,
                {
                    dateFinAgrement: values.dateFinAgrement?.format("YYYY-MM-DD"),
                    dateFinAssuranceHabitation: values.dateFinAssuranceHabitation?.format("YYYY-MM-DD"),
                    dateFinAssuranceVoiture: values.dateFinAssuranceVoiture?.format("YYYY-MM-DD"),
                    email: formData.email,
                    numeroTelephone: formData.numeroTelephone,
                    dateNaissance: formData.dateNaissance.format("YYYY-MM-DD"),
                    prenom: formData.prenom,
                    nom: formData.nom,
                    adresse: formData.adresse,
                    codePostal: formData.codePostal,
                    ville: formData.ville,
                    pays: "FRANCE",
                    joursTravailles: values.joursTravailles,
                    receiveNotification: `${formData.receiveNotification}`,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }

            );

            setUser(response.data.user);
            message.success("Votre profil a été mis à jour avec succès !");
            navigate("/");
        } catch (error) {
            console.error("API Error:", error.response.data.message);
            message.error(error.response.data.message);
        }
    };


    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await onFinish(values);
        } catch (error) {
            message.error("Erreur lors de la soumission du formulaire.");
        }
    };

    // Fonction de recherche pour les villes et les codes postaux
    const handleSearch = async (value) => {
        if (!value) {
            setOptions([]);
            return;
        }

        const isZipCode = /^\d+$/.test(value); // Vérifie si c'est un code postal
        let results = [];

        if (isZipCode) {
            results = await fetchZipCodesFromGeoAPI(value); // Recherche par code postal
        } else {
            results = await fetchCitiesFromGeoAPI(value); // Recherche par ville
        }

        setOptions(
            results.map((item) => ({
                value: `${item.ville}, ${item.codePostal}`,
                label: `${item.ville} (${item.codePostal})`,
                ville: item.ville,
                codePostal: item.codePostal,
            }))
        );
    };

    // Fonction pour remplir automatiquement les champs après sélection d'une ville
    const handleSelect = (value, option) => {
        form.setFieldsValue({
            ville: option.ville,
            codePostal: option.codePostal,
        });
    };

    const mapJoursToNumbers = (jours) => {
        const joursMapping = {
            "Lundi": "1",
            "Mardi": "2",
            "Mercredi": "3",
            "Jeudi": "4",
            "Vendredi": "5",
            "Samedi": "6",
            "Dimanche": "7",
            "Jour férié": "0",
        };

        return jours.map(jour => joursMapping[jour]);
    };

    const mapNumbersToJours = (numbers) => {
        const joursMapping = {
            "1": "Lundi",
            "2": "Mardi",
            "3": "Mercredi",
            "4": "Jeudi",
            "5": "Vendredi",
            "6": "Samedi",
            "7": "Dimanche",
            "0": "Jour férié",
        };

        return numbers.map(number => joursMapping[number]);
    };

    return (
        <div className="signIn-container">
            <div className="signIn-wrapper">
                <div className="signIn-header">
                    <img src={logo} alt="Logo UAMA" className="signIn-logo"/>
                    <h1>UAMA</h1>
                    <p className="login-fullname" style={{fontSize: "0.9rem", color: "#888"}}>
                        Univers des Assistantes Maternelles Agrées
                    </p>
                    <div className="signIn-description">
                        {roleIcon}
                        <div>
                            <p>{greeting} <b>{user["Prénom"]} {user["Nom"]}</b> !</p>
                            <p>{roleMessage}</p>
                        </div>
                    </div>
                </div>

                <Divider/>

                <Form
                    form={form}
                    className="signIn-form"
                    onFinish={onFinish}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 24}}
                >
                    {/* Etape 1: Identifiants */}
                    {currentStep === 0 && (
                        <>
                            <Form.Item
                                name="email"
                                label="Email"
                                initialValue={user.Email}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                    {
                                        type: "email",
                                        message: "Merci de saisir une adresse email valide",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item className="checkboxNotif" name="receiveNotification" valuePropName="checked" initialValue={user["Recevoir des notifications"]}>
                                <Checkbox>Recevoir des notifications</Checkbox>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 2: Informations */}
                    {currentStep === 1 && (
                        <>
                            <Form.Item
                                name="nom"
                                label="Nom"
                                initialValue={user.Nom}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un nom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="family-name" />
                            </Form.Item>
                            <Form.Item
                                name="prenom"
                                label="Prénom"
                                initialValue={user["Prénom"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un prénom",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input type="text" autoComplete="given-name" />
                            </Form.Item>
                            <Form.Item
                                name="numeroTelephone"
                                label="Numéro de téléphone"
                                initialValue={user["Téléphone"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un numéro de téléphone",
                                    },
                                    {
                                        pattern: /^(0\d{9}|(\+?[1-9]\d{0,2}[-.\s]?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}))$/,
                                        message: "Merci de saisir un numéro de téléphone valide. Exemple : +33 6 12 34 56 78 ou 06 12 34 56 78.",
                                    },
                                ]}
                            >
                                <Input type="tel" autoComplete="tel" />
                            </Form.Item>

                            <Form.Item
                                name="dateNaissance"
                                label="Date de naissance"
                                initialValue={dayjs(user["Date de naissance"], 'DD-MM-YYYY')}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une date de naissance",
                                    },
                                ]}
                            >
                                <DatePicker style={{width: "100%"}} format={dateFormats}/>
                            </Form.Item>
                        </>
                    )}

                    {/* Etape 3: Adresse */}
                    {currentStep === 2 && (
                        <>
                            <Form.Item
                                name="adresse"
                                label="Adresse"
                                initialValue={user.Adresse["Adresse"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une adresse",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="codePostal"
                                label="Code postal"
                                initialValue={user.Adresse["Code postal"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir un code postal",
                                        whitespace: true,
                                    },
                                    {
                                        pattern: /^\d{5}$/, // Une expression régulière pour valider le format du code postal (5 chiffres)
                                        message: "Le code postal doit être composé de 5 chiffres",
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez un code postal"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ codePostal: value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="ville"
                                label="Ville"
                                initialValue={user.Adresse["Ville"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une ville",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    placeholder="Saisissez une ville"
                                    allowClear
                                    onChange={(value) => {
                                        form.setFieldsValue({ ville: value });
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}
                    {/* Etape 4: Administratif */}
                    { !isParent && currentStep === 3 && (
                        <>
                            <Form.Item
                                name="dateFinAgrement"
                                label="Date de fin d'agrement"
                                initialValue={dayjs(user["Date de fin d'agrément"], 'DD-MM-YYYY')}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de saisir une date de fin d'agrement",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>
                            <Form.Item
                                name="dateFinAssuranceHabitation"
                                label="Date de fin d'assurance habitation"
                                initialValue={dayjs(user["Date de fin assurance habitation"], 'DD-MM-YYYY')}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Merci de saisir une date de fin d'assurance habitation",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>
                            <Form.Item
                                name="dateFinAssuranceVoiture"
                                label="Date de fin d'assurance voiture"
                                initialValue={dayjs(user["Date de fin assurance voiture"], 'DD-MM-YYYY')}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Merci de saisir une date de fin d'assurance voiture",
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormats}/>
                            </Form.Item>
                            <Form.Item
                                name="joursTravailles"
                                label="Jours travaillés"
                                initialValue={mapJoursToNumbers(user["Jours travaillés"])}
                                rules={[
                                    {
                                        required: true,
                                        message: "Merci de sélectionner au moins un jour travaillé",
                                    },
                                ]}
                            >
                                <Checkbox.Group>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Checkbox value="1">Lundi</Checkbox>
                                        <Checkbox value="2">Mardi</Checkbox>
                                        <Checkbox value="3">Mercredi</Checkbox>
                                        <Checkbox value="4">Jeudi</Checkbox>
                                        <Checkbox value="5">Vendredi</Checkbox>
                                        <Checkbox value="6">Samedi</Checkbox>
                                        <Checkbox value="7">Dimanche</Checkbox>
                                        <Checkbox value="0">Jours fériés</Checkbox>
                                    </div>
                                </Checkbox.Group>
                            </Form.Item>
                        </>
                    )}

                    <Item wrapperCol={{offset: 8, span: 16}}>
                        {currentStep === 0 && (
                            <Link to="/">
                                <Button type={"default"} style={{margin: "0 8px"}}>Retour</Button>
                            </Link>
                        )}
                        {currentStep > 0 && (
                            <Button style={{margin: "0 8px"}} onClick={handlePrev}>
                                Précédent
                            </Button>
                        )}
                        {currentStep < (isParent ? 3 : 4) - 1 && (
                            <Button type="primary" onClick={handleNext}>
                                Suivant
                            </Button>
                        )}
                        {currentStep === (isParent ? 2 : 3) && (
                            <Button type="primary" onClick={handleSubmit} loading={loading}>
                                Modifier
                            </Button>
                        )}
                    </Item>

                    <Divider/>

                    <Steps current={currentStep}>
                        <Step title="Identifiants" icon={<UserOutlined />} />
                        <Step title="Informations" icon={<InfoCircleOutlined />} />
                        <Step title="Adresse" icon={<HomeOutlined />} />
                        {!isParent && <Step title="Administratif" icon={<FileTextOutlined />} />}
                    </Steps>
                </Form>
            </div>
        </div>
    );
};

export default EditProfil;
