import React, { useState } from "react";
import {Button, notification, Progress, Typography} from "antd";
import { UploadOutlined, ThunderboltOutlined, HourglassOutlined, StopOutlined } from "@ant-design/icons";
import axios from "axios";
import { AddFichier } from "./DocumentsApi";
import './DragAndDropZone.css'

const { Text } = Typography;

const DragAndDropZone = ({ idFolder, folderName = '', forceUpdate, token, children, canAdd  = true }) => {
    const [dragOver, setDragOver] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [dragCounter, setDragCounter] = useState(0); // Compteur pour suivre les événements de drag

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const formatTimeRemaining = (seconds) => {
        if (seconds <= 0) return "Moins d'une seconde";
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return minutes > 0
            ? `${minutes} min ${remainingSeconds} sec`
            : `${remainingSeconds} sec`;
    };

    const handleDrop = async (event) => {
        if (!canAdd) return;
        event.preventDefault();
        setDragOver(false);
        setDragCounter(0);

        const file = event.dataTransfer.files[0]; // Récupère le fichier glissé
        if (!file || !idFolder) return;

        const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf("."));
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const notificationKey = `upload-${Date.now()}`;
        const source = axios.CancelToken.source(); // Token pour annuler le transfert

        let lastLoaded = 0;
        let timeRemaining = 0;
        let lastTimestamp = Date.now();

        // Notification initiale
        notification.info({
            key: notificationKey,
            message: "Transfert du fichier en cours...",
            description: (
                <>
                    <Text strong>{fileNameWithoutExtension}.{extension}</Text>
                    <Progress percent={0} status="active" />
                </>
            ),
            duration: 0,
            btn: (
                <button
                    onClick={() => {
                        source.cancel("Le transfert a été annulé par l'utilisateur.");
                        notification.destroy(notificationKey);
                        setIsUploading(false);
                    }}
                >
                    <StopOutlined /> Annuler
                </button>
            ),
        });

        try {
            setIsUploading(true);

            await AddFichier({
                nom: fileNameWithoutExtension,
                idFolder,
                file,
                token,
                cancelToken: source.token,
                forceUpdate,
                onUploadProgress: (progress, loaded, total) => {
                    const now = Date.now();
                    const elapsed = now - lastTimestamp; // Temps écoulé depuis la dernière mise à jour
                    const speed = ((loaded - lastLoaded) / (elapsed / 1000)).toFixed(2); // Débit en bytes par seconde
                    lastLoaded = loaded;
                    lastTimestamp = now;

                    timeRemaining = speed > 0 ? (total - loaded) / speed : 0;

                    notification.info({
                        key: notificationKey,
                        message: "Transfert du fichier en cours...",
                        description: (
                            <>
                                <Text strong>{fileNameWithoutExtension}.{extension}</Text>
                                <Progress percent={progress} status={progress < 100 ? "active" : "success"}/>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "10px"
                                }}>
                                    <div>
                                        <Typography.Text strong style={{color: "#4caf50"}}>
                                            {formatBytes(loaded)}
                                        </Typography.Text>
                                        <span> / </span>
                                        <Typography.Text strong>{formatBytes(total)}</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                        <ThunderboltOutlined style={{color: "#faad14"}}/>
                                        <Typography.Text style={{color: "#1890ff", fontWeight: "bold"}}>
                                            {formatBytes(speed)}/s
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div style={{marginTop: "10px", display: "flex", alignItems: "center", gap: "5px"}}>
                                    <HourglassOutlined style={{color: "#faad14"}}/>
                                    <Text style={{fontStyle: "italic"}}>Temps restant
                                        : {formatTimeRemaining(timeRemaining)}</Text>
                                </div>
                            </>
                        ),
                        duration: 0,
                        btn: (
                            <Button
                                danger
                                icon={<StopOutlined/>}
                                onClick={() => {
                                    source.cancel("Le transfert a été annulé par l'utilisateur.");
                                    notification.destroy(notificationKey);
                                    setIsUploading(false);
                                }}
                            >
                                Annuler
                            </Button>
                        ),
                    });
                },
            });

            notification.success({
                key: notificationKey,
                message: "Transfert réussi",
                description: (
                    <span>
                        Le fichier <Text strong>{file.name}</Text> a été envoyé avec succès.
                    </span>
                ),
                duration: 10,
                showProgress: true,
                pauseOnHover: true,
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                notification.warning({
                    key: notificationKey,
                    message: "Transfert annulé",
                    description: (
                        <span>
                          Le transfert du fichier <Text strong>{file.name}</Text> a été annulé.
                        </span>
                    ),
                    duration: 10,
                    showProgress: true,
                    pauseOnHover: true,
                });
            } else {
                console.error(error);
                notification.error({
                    key: notificationKey,
                    message: "Échec du transfert",
                    description: (
                        <span>
                          {error.response?.data?.message || `Une erreur est survenue lors de l'envoi du fichier`}<br/><Text strong>{file.name}</Text>.
                        </span>
                    ),
                    duration: 10,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } finally {
            setIsUploading(false);
        }
    };

    const handleDragOver = (event) => {
        if (!canAdd) return;
        event.preventDefault();
    };

    const handleDragEnter = (event) => {
        if (!canAdd) return;
        event.preventDefault();
        setDragCounter((prev) => prev + 1);
        setDragOver(true); // Active l'effet visuel
    };

    const handleDragLeave = (event) => {
        if (!canAdd) return;
        event.preventDefault();
        setDragCounter((prev) => {
            const newCount = prev - 1;
            if (newCount <= 0) {
                setDragOver(false); // Désactive l'effet visuel uniquement si tous les événements sont traités
            }
            return newCount;
        });
    };

    return (
        <div
            className={`drag-and-drop-zone ${dragOver ? "drag-over" : ""}`}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
                border: dragOver ? "2px dashed #0a2b4a" : "2px dashed transparent",
                borderRadius: "5px",
                position: "relative",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                animation: dragOver ? "borderPulse 1s infinite" : "none",
            }}
        >
            <div
                style={{
                    filter: dragOver ? "blur(3px)" : "none",
                    transition: "filter 0.3s ease-in-out",
                    flexGrow: 1
                }}
            >
                {children}
            </div>
            {dragOver && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(24, 144, 255, 0.3)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 100,
                        animation: !dragOver ? "fadeOut 0.3s forwards" : "fadeInScale 0.3s ease-out",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            padding: "2vh 3vw",
                        }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <UploadOutlined style={{ fontSize: "48px", color: "#1890ff", animation: "bounce 1s infinite" }} />
                            <Text strong style={{ marginLeft: "10px", fontSize: "24px" }}>
                                Déposez votre fichier ici
                            </Text>
                        </div>

                        {folderName && (
                            <Text style={{ margin: "2vh 0 0 0", fontSize: "16px" }}>
                                Le fichier se trouvera dans le répertoire <b>{folderName}</b>
                            </Text>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DragAndDropZone;
