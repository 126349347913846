import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Assurez-vous de fournir le bon chemin

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { token } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('https://api.uama.fr/v1.0/notification/index', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNotifications(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications', error);
        setError(error.message || 'Une erreur s\'est produite lors de la récupération des notifications.');
      }
    };

    if (token) {
      fetchNotifications();
    } else {
      // Gérer le cas où le token n'est pas disponible (non authentifié)
      setNotifications([]);
    }
  }, [token]);


  const markNotificationsAsRead = async () => {
    try {
      await axios.put(
        'https://api.uama.fr/v1.0/notification/mark-as-read',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <NotificationContext.Provider value={{ notifications, error, markNotificationsAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
