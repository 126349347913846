import React, { useEffect, useState } from 'react';
import {Modal, Image, Alert, Row, Spin, Tooltip} from 'antd';
import DownloadFile from "./IconComponents/DownloadFile";
import {DownloadOutlined} from "@ant-design/icons";
import {Download} from "./DocumentsApi";
import FilePreview from "./FilePreview";

const FilePreviewModal = ({ isVisible, onClose, fileType, filePath, fileName, canDownload = true }) => {
    const [textContent, setTextContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (fileType === 'text') {
            setLoading(true);
            fetch(filePath)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erreur de chargement du fichier texte');
                    }
                    return response.text();
                })
                .then(text => setTextContent(text))
                .catch(err => setError(err.message))
                .finally(() => setLoading(false));
        }
    }, [filePath, fileType]);

    return (
        <Modal visible={isVisible} onCancel={onClose} footer={null} width="80%" centered>
            <Row justify="center" align="middle" style={{ borderBottom: "1px solid #cdcdcd", marginBottom: "10px", paddingBottom: "10px" }}>
                <h3>{fileName}{ fileType !== "other" && (
                    <span style={{ marginLeft: "20px", backgroundColor: "#f0f0f0", borderRadius: "5px", padding: "10px" }}>
                        <DownloadFile url={filePath} filename={fileName}/>
                    </span>
                    )}
                </h3>
            </Row>
            <FilePreview
                fileType={fileType}
                fileName={fileName}
                filePath={filePath}
                canDownload={canDownload}
            />
        </Modal>
    );
};

export default FilePreviewModal;
