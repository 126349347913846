import React from "react";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { Download } from "../DocumentsApi";
import {Tooltip} from "antd";

const DownloadFile = ({ url, title = '', filename = '' }) => {
    const handleClick = () => {
        Download(url, filename);
    };

    // custom Style
    return (
        <Tooltip title="Télécharger le fichier" placement="top">
            {title !== '' ? (
                <span onClick={handleClick}>
                    <DownloadOutlined style={{ color: "#4E89FF" }} />
                    <span style={{marginLeft: "8px"}}>{title}</span>
                </span>
            ) : (
                <DownloadOutlined style={{ color: "#4E89FF" }} onClick={handleClick}/>
            )}
        </Tooltip>
    );
};

export default DownloadFile;
