import React, { useEffect, useState } from "react";
import Planning from "../../components/Planning/Planning";
import "./HomePage.css";
import ResumeAbscence from "../../components/ResumeAbscence/ResumeAbscence";
import {Col, Card, Row, Button, Empty, Typography} from "antd";
import CardWelcome from "../../components/CardWelcome/CardWelcome";
import { useAuth } from "../../context/AuthContext";
import {
    CarryOutOutlined,
    ReconciliationOutlined,
    CloudServerOutlined, CloudOutlined
} from "@ant-design/icons";

import { GiPalmTree } from "react-icons/gi";
import ContratsActif from "../../components/ContratsActif/ContratsActif";
import useIsMobile from "../../hooks/useIsMobile";
import { ROUTES } from "../../Routes/Routes";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../components/Admin/Dashboad/Dashboard";
import Loading from "../../components/Loading/Loading";

const { Text } = Typography;

const HomePage = () => {
    const { user, token } = useAuth();
    const [modalType, setModalType] = useState(null);
    const [stats, setStats] = useState(null); // Assure un état initial null
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useIsMobile(1300);
    const isAdmin = user?.Roles?.includes("ROLE_ADMIN");
    const navigate = useNavigate();

    // Actualiser la clé du planning pour forcer un re-render
    const [planningKey, setPlanningKey] = useState(0);

    useEffect(() => {
        setPlanningKey((prevKey) => prevKey + 1);
    }, [isMobile]);

    const openModal = (type) => setModalType(type);
    const closeModal = () => setModalType(null);

    // Récupération des statistiques depuis l'API
    const fetchStats = async () => {
        try {
            const response = await fetch("https://api.uama.fr/v1.0/admin/stats", {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error("Erreur lors du chargement des statistiques");
            const data = await response.json();
            setStats(data);
        } catch (error) {
            console.error(error);
            setStats(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isAdmin) fetchStats();
    }, [token, isAdmin]);

    if (isAdmin && isLoading) {
        return <Loading />;
    }

    return (
        <div className="homeContent">
            {!isAdmin ? (
                <Row justify="space-around" className="row">
                    <Col className="col-left" xs={24} sm={24} md={15} lg={15} xl={15}>
                        <CardWelcome
                            nomComplet={user?.["Nom complet"]}
                            role={user?.Roles}
                            derniereConnexion={user?.["Dernière connexion"]}
                        />
                        <Card
                            className="planningCard mt-3"
                            headStyle={{ backgroundColor: "#FDF0F0" }}
                            extra={<a href={ROUTES.PLANNING}>Ajouter</a>}
                            title={
                                <span
                                    className="title"
                                    onClick={() => navigate(ROUTES.PLANNING)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <CarryOutOutlined className="icon" /> Planning de la semaine
                                </span>
                            }
                            size="small"
                        >
                            <Planning
                                key={planningKey}
                                modalType={modalType}
                                closeModal={closeModal}
                                openModal={openModal}
                                initialView={isMobile ? "listWeek" : "timeGridWeek"}
                                height="55vh"
                                showBtnFC={true}
                                showCustomBtn={false}
                            />
                        </Card>
                    </Col>
                    <Col className="col-right" xs={24} sm={24} md={9} lg={9} xl={9}>
                        <Card
                            className="card-contrat-actif"
                            headStyle={{ backgroundColor: "#FDF0F0" }}
                            extra={<a href={ROUTES.CONTRATS}>Accéder aux contrats</a>}
                            title={
                                <span
                                    className="title"
                                    onClick={() => navigate(ROUTES.CONTRATS)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <ReconciliationOutlined className="icon" /> Contrats actifs
                                </span>
                            }
                        >
                            <ContratsActif />
                        </Card>

                        <Card
                            className="mt-3"
                            headStyle={{ backgroundColor: "#FDF0F0" }}
                            title={
                                <span className="title">
                                    <GiPalmTree className="icon" /> Les prochaines absences prévues au contrat
                                </span>
                            }
                        >
                            <ResumeAbscence />
                        </Card>
                    </Col>
                </Row>
            ) : stats ? (
                <Dashboard
                    stats={stats}
                    onRefresh={fetchStats}
                    user={{
                        nomComplet: user?.["Nom complet"] || "Admin",
                        derniereConnexion: user?.["Dernière connexion"],
                    }}
                />
            ) : (
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col xs={24} lg={8}>
                        <Card className="no-stats-container">
                            <Empty
                                image={
                                    <CloudOutlined
                                        style={{
                                            fontSize: '64px',
                                            color: '#1890ff',
                                            marginBottom: '16px',
                                        }}
                                    />
                                }
                                description={
                                    <>
                                        <Text style={{ fontSize: '18px', fontWeight: 500, color: '#555' }}>
                                            Les statistiques ne sont pas disponibles pour le moment !
                                        </Text>
                                        <Text style={{display: 'block', marginTop: '8px', fontSize: '14px', color: '#888'}}>
                                            Vérifiez si vous avez accès à internet ou l'état du serveur.
                                        </Text>
                                    </>
                                }
                            />

                            <Button type="primary" size="large" onClick={fetchStats}>Réessayer</Button>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default HomePage;
