import React from 'react'
import './Page404.css'
import './Page404Phone.css'
import { Button, Col } from 'antd';
import { useAuth } from "../../context/AuthContext";


const Page404 = () => {

    const { isAuthenticated } = useAuth();

    const isMobile = window.innerWidth <= 1850;

    

    return (
        <div className='page404'>
            {isMobile ? (
                <div className="box404">
                    <div className="box__ghost">
                        <div className="symbol" />
                        <div className="symbol" />
                        <div className="symbol" />
                        <div className="symbol" />
                        <div className="symbol" />
                        <div className="symbol" />
                        <div className="box__ghost-container">
                            <div className="box__ghost-eyes">
                                <div className="box__eye-left" />
                                <div className="box__eye-right" />
                            </div>
                            <div className="box__ghost-bottom">
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>
                        <div className="box__ghost-shadow" />
                    </div>
                    <div className="box__description">
                        <div className="box__description-container">
                            <div className="box__description-title">La page est introuvable</div>
                            <div className="box__description-text">
                                <a a className='acceuilBtn' href='/'>
                                    <Button type="primary">Accueil</Button>
                                </a>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
                

            ) : (
                <div className="conatiner">
                    <span className="numer">4</span>
                        <div className="circleNumber">
                        <div className="drops" />
                        <div className="drops" />
                        <div className="hand" />
                        <div className="hand rgt" />
                        <div className="holder">
                            <div className="bob">
                                <div className="nose" />
                                <div className="face">
                                    <div className="mouth">
                                        <div className="tongue" />
                                    </div>
                                </div>
                                <div className="ear" />
                                <div className="ear rgt" />
                                <div className="neck" />
                            </div>
                        </div>
                    </div>
                    <span className="numer">4</span>

                        <a a className='acceuilBtn' href='/'>
                            <Button type="primary">Accueil</Button>
                        </a>

                </div>

            )


            }







        </div>
    )
}

export default Page404