import React, {useState, useEffect} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Select, Card, message, Button, Row, Col} from 'antd';
import {useDocuments} from '../../context/DocumentsContext';
import {useAuth} from '../../context/AuthContext';
import {ROUTES} from '../../Routes/Routes';
import './DocumentsPage.css';
import './DocumentsBtn.css';
import {CloudOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {TbMoodKid} from "react-icons/tb";
import {MdFamilyRestroom} from "react-icons/md";
import {FaBabyCarriage} from "react-icons/fa6";

const {Option, OptGroup} = Select;

const DocumentsPage = () => {
    const [idSelectedEnfant, setIdSelectedEnfant] = useState(null);
    const [contrats, setContrats] = useState([]);
    const {documents} = useDocuments();
    const {user, token} = useAuth();
    const navigate = useNavigate();
    const isAssistantMaternel = user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE");

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.uama.fr/v1.0/contrat/index?limit=inf', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setContrats(data.contrats);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const renderEnfantOptions = () => {
        if (!documents || !contrats) return [];

        // Regroupez les contrats par statut
        const statusOrder = ["Actif", "En préparation", "Terminé", "Statut inconnu"];
        const groupedContrats = contrats.reduce((groups, contrat) => {
            const status = contrat.Statut || "Statut inconnu";
            if (!groups[status]) groups[status] = [];
            groups[status].push(contrat);
            return groups;
        }, {});

        const normalizeStatus = (status) => {
            return status
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
                .replace(/\s+/g, "_") // Remplace les espaces par des underscores
                .toLowerCase(); // Convertit en minuscule
        };

        // Vérifiez si documents est un tableau ou un objet
        let documentOptions = [];
        if (Array.isArray(documents)) {
            documentOptions = documents.flatMap((doc) =>
                doc.arborescence?.contient
                    .filter(item => item["nom du répertoire"] !== "Global" && item["nom du répertoire"] !== "Personnel")
                    .map(item => {
                        const matchingContrat = contrats.find(contrat => contrat.Enfant.UUID === item["uuid contrat enfant"]);
                        return {
                            id: item.id,
                            contrat: matchingContrat,
                        };
                    })
                    .filter(option => option.contrat) // Filtrer uniquement les répertoires avec un contrat correspondant
            );
        } else if (typeof documents === "object" && documents.contient) {
            documentOptions = documents.contient
                .filter(item => item["nom du répertoire"] !== "Global" && item["nom du répertoire"] !== "Personnel")
                .map(item => {
                    const matchingContrat = contrats.find(contrat => contrat.Enfant.UUID === item["uuid contrat enfant"]);
                    return {
                        id: item.id,
                        contrat: matchingContrat,
                    };
                })
                .filter(option => option.contrat);
        }

        // Supprimez les doublons en utilisant un Map
        const uniqueOptions = Array.from(
            new Map(
                documentOptions.map(option => [`${option.id}-${option.contrat.UUID}`, option])
            ).values()
        );

        // Générer les options en groupes
        return statusOrder.map(status => (
            groupedContrats[status] ? (
                <OptGroup key={status} label={status} className={`custom-opt-group ${normalizeStatus(status)}`}>
                    {uniqueOptions
                        .filter(option => option.contrat.Statut === status)
                        .map(option => (
                            <Option key={option.id} value={option.id}>
                                <TbMoodKid style={{ color: "#4e89ff" }} /> {option.contrat.Enfant["Nom complet"]} (
                                {isAssistantMaternel ? (
                                    <>
                                        <MdFamilyRestroom style={{ color: "#ff7f50" }} /> {option.contrat.Enfant.Parent}
                                    </>
                                ) : (
                                    <>
                                        <FaBabyCarriage style={{ color: "#ff7f50" }} /> {option.contrat["Assistante maternelle"]["Nom complet"]}
                                    </>
                                )}
                                )
                            </Option>
                        ))}
                </OptGroup>
            ) : null
        ));
    };

    const handleEnfantChange = (value) => {
        setIdSelectedEnfant(value); // Met à jour l'état
        handleButtonClick(value);  // Passe directement la valeur sélectionnée
    };

    const handleButtonClick = (selectedId) => {
        const id = selectedId || idSelectedEnfant; // Utilise soit la valeur passée, soit l'état
        if (id) {
            const allItems = Array.isArray(documents)
                ? documents.flatMap((doc) => doc.arborescence?.contient || [])
                : documents?.contient || []; // Si `documents` est un objet

            const selectedContract = contrats.find(
                (contrat) =>
                    contrat.Enfant.UUID === allItems.find((item) => item.id === id)?.["uuid contrat enfant"]
            );

            if (selectedContract) {
                const color = selectedContract.Couleur || "#4e89ff"; // Couleur par défaut
                const path = generatePath(ROUTES.DOCUMENTS_PARENTS, {
                    idRepertoire: id,
                });
                // Passez la couleur via `state`
                navigate(path, { state: { color } });
            } else {
                message.error("Impossible de trouver le contrat associé.");
            }
        } else {
            message.error("Merci de sélectionner un répertoire.");
        }
    };

    return (
        <Row justify="center">
            <Col xs={24} md={18} lg={14} xl={10}>
                <Card style={{margin: '0 auto 50px auto'}}>
                    <h1 className="pageTitle">
                        <CloudOutlined style={{color: '#1890ff', marginRight: '1rem'}}/>
                        Gestion des documents
                    </h1>
                    <p className="pageDescription">Accédez rapidement aux documents selon vos besoins.</p>
                </Card>
            </Col>

            <Col xs={24} className="DocumentsPage">
                <div className="btnDocumentContainer">
                    {/* Bouton pour les documents globaux */}
                    <div className="button button--piyo" onClick={() => navigate(ROUTES.DOCUMENTS_ALL)}>
                        <div className="button__wrapper">
                            <span className="button__text">Documents globaux</span>
                        </div>
                        <div className="characterBox">
                            <div className="character wakeup">
                                <div className="character__face"></div>
                            </div>
                            <div className="character wakeup">
                                <div className="character__face"></div>
                            </div>
                            <div className="character">
                                <div className="character__face"></div>
                            </div>
                        </div>
                    </div>

                    {user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE') && (
                        <div className="button button--hoo" onClick={() => navigate(ROUTES.DOCUMENTS_PERSO)}>
                            <div className="button__wrapper">
                                <span className="button__text">Documents personnels</span>
                            </div>
                            <div className="characterBox">
                                <div className="character wakeup">
                                    <div className="character__face"></div>
                                    <div className="charactor__face2"></div>
                                    <div className="charactor__body"></div>
                                </div>
                                <div className="character wakeup">
                                    <div className="character__face"></div>
                                    <div className="charactor__face2"></div>
                                    <div className="charactor__body"></div>
                                </div>
                                <div className="character">
                                    <div className="character__face"></div>
                                    <div className="charactor__face2"></div>
                                    <div className="charactor__body"></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Bouton avec Select pour répertoires enfants */}
                    <div className="button button--pen">
                        <div className="button__wrapper">
                            <Select
                                className="button__text"
                                placeholder="Sélectionnez un répertoire enfant"
                                onChange={handleEnfantChange}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    background: 'transparent',
                                    fontWeight: 'bold',
                                }}
                            >
                                {renderEnfantOptions()}
                            </Select>
                        </div>
                        <div className="characterBox">
                            <div className="character wakeup">
                                <div className="character__face"></div>
                            </div>
                            <div className="character wakeup">
                                <div className="character__face"></div>
                            </div>
                            <div className="character">
                                <div className="character__face"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default DocumentsPage;
