import React, { useEffect, useState, useRef } from "react";
import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css"; // Import the default styles

import {Button, Switch} from "antd";
import { motion } from "framer-motion";

import { useAuth } from "../../context/AuthContext.js";
import { DeleteFile, DeleteFolder } from "./IconComponents/DeleteFileFolder.js";
import { EditFolder, EditFile } from "./IconComponents/EditFileFolder.js";
import AddFile from "./IconComponents/AddFile.js";
import AddFolder from "./IconComponents/AddFolder.js";
import DownloadFile from "./IconComponents/DownloadFile.js";

import "./ArborescenceDocumentsDark.css";
import {Link} from "react-router-dom";

const ArborescenceDocuments = ({ documents, forceUpdate }) => {
  const { token } = useAuth();
  const { user } = useAuth();


  //Minimiser la page :
  const [isMinimized, setIsMinimized] = useState(false);

  const [isMinimizedWaitFinish, setIsMinimizedWaitFinish] = useState(false);

  const handleMinimize = () => {
    setIsMinimized((prevIsMinimized) => !prevIsMinimized);

    if(!isMinimized){

    setTimeout(() => {
      setIsMinimizedWaitFinish(
        (previsMinimizedWaitFinish) => !previsMinimizedWaitFinish
      );
    }, 400);

  }else{
    setIsMinimizedWaitFinish(
      (previsMinimizedWaitFinish) => !previsMinimizedWaitFinish
    );
  };
  }
  const constraintsRef = useRef(null);


  const createTree = (folder) => {
    return {
      name: folder["nom du répertoire"],
      id: folder.id,
      isOpen: false,
      children: [
        ...(folder.fichiers || []).map((file) => ({
          name: file.nom,
          id: file.id,
          url: file.chemin,
        })),

        ...(folder.contient || []).map(createTree),
      ],
    };
  };
  const treeState = createTree(documents);

  //Icone custom pour les fichier + logo download
  const FileIcon = ({ nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    return <DownloadFile url={restData.url} />;
  };

// Icone custom pour ajouter un dossier
const AddFolderIcon = ({ nodeData }) => {
  const { path, name, checked, isOpen, ...restData } = nodeData;

  if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
    return (
      <AddFolder
        nom={name}
        parentId={restData.id}
        token={token}
        forceUpdate={forceUpdate}
      />
    );
  }
  return null; // Retourne null si l'utilisateur n'a pas le rôle
};

  //Icone custom pour supprimer un dossier
  const DeleteIcon = ({ nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {

      if (!nodeData.url) {
        return (
          <DeleteFolder
            name={name}
            id={restData.id}
            token={token}
            forceUpdate={forceUpdate}
          />
        );
      } else {
        return (
          <DeleteFile
            name={name}
            id={restData.id}
            token={token}
            forceUpdate={forceUpdate}
          />
        );
      }

    }
    else{
      return null; // Retourne null si l'utilisateur n'a pas le rôle
    }
  };

  const EditIcon = ({ nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
      if (!nodeData.url) {
        return (
          <EditFolder
            nodeData={nodeData}
            token={token}
            forceUpdate={forceUpdate}
          />
        );
      } else {
        return (
          <EditFile
            nom={name}
            id={restData.id}
            token={token}
            forceUpdate={forceUpdate}
          />
        );
      }
    }else {
      return null; // Retourne null si l'utilisateur n'a pas le rôle
    }


    
  };

  //Icone custom pour supprimer un dossier
  const AddFileIcon = ({ nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    if (user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE")) {
      return (
        <AddFile
          folderName={name}
          idFolder={restData.id}
          token={token}
          forceUpdate={forceUpdate}
        />
      );
    }else{
      return null; // Retourne null si l'utilisateur n'a pas de rôle
    }


    
  };

  if (isMinimizedWaitFinish) {
    return (
      <>
        <div className="card minimized">
          <div className="tools" onClick={handleMinimize}>
            <div className="circle">
              <span className="red box"></span>
            </div>
            <div className="circle">
              <span className="yellow box"></span>
            </div>
            <div className="circle">
              <span className="green box"></span>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <motion.div
          animate={
            isMinimized
              ? { x: 0, y: 700, rotate: 100, scale: 0.3 }
              : { x: 0, y: 0, rotate: 0, scale: 1 }
          }
          transition={{ duration: 0.4 }}
          initial={!isMinimized ? {x:0, y:700, rotate:100, scale:0.3} : ""}
        >
          <motion.div ref={constraintsRef}>
            <motion.div className="card" drag dragConstraints={constraintsRef}>
              <div className="tools" onClick={handleMinimize}>
                <div className="circle">
                  <span className="red box"></span>
                </div>
                <div className="circle">
                  <span className="yellow box"></span>
                </div>
                <div className="circle">
                  <span className="green box"></span>
                </div>
                <div className="circle">
                </div>
              </div>
              <Link to="/documents">
                <Button type="primary" style={{ margin: "0 8px" }}>
                  Retour
                </Button>
              </Link>
              <div className="card__content">
                <div className="arborescence">
                  <h2>Arborescence des fichiers</h2>
                  <FolderTree
                    data={treeState}
                    showCheckbox={false}
                    iconComponents={{
                      FileIcon,
                      AddFolderIcon,
                      DeleteIcon,
                      EditIcon,
                      AddFileIcon,
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </>
    );
  }
};

export default ArborescenceDocuments;
