import React from "react";
import { ConfigProvider } from "antd";
import frFR from "antd/locale/fr_FR";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { SidebarProvider } from "./context/SideBarContext";
import { NotificationProvider } from "./context/NotificationContext";
import { DocumentsProvider } from "./context/DocumentsContext";
import Routes from "./Routes/Routes";

import "./App.css";

const router = createBrowserRouter(Routes);

function App() {
  return (
      <div className="App">
        <ConfigProvider locale={frFR}>
          <AuthProvider>
            <NotificationProvider>
              <SidebarProvider>
                <DocumentsProvider>
                  <RouterProvider router={router} />
                </DocumentsProvider>
              </SidebarProvider>
            </NotificationProvider>
          </AuthProvider>
        </ConfigProvider>
      </div>
  );
}

export default App;
