import React, {useEffect, useState} from 'react';
import {Modal, Image, Alert, Row, Spin, Tooltip} from 'antd';
import DownloadFile from "./IconComponents/DownloadFile";
import {DownloadOutlined} from "@ant-design/icons";
import {Download} from "./DocumentsApi";

const FilePreview = ({fileType, filePath, fileName, canDownload = false}) => {
    const [textContent, setTextContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (fileType === 'text') {
            setLoading(true);
            fetch(filePath)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erreur de chargement du fichier texte');
                    }
                    return response.text();
                })
                .then(text => setTextContent(text))
                .catch(err => setError(err.message))
                .finally(() => setLoading(false));
        }
    }, [filePath, fileType]);

    const handleDownload = () => {
        if (canDownload) {
            Download(filePath, fileName)
        }
    }

    return (
        <div style={{
            maxHeight: "60vh",
            overflowY: "auto",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: "5px"
        }}>
            {fileType === 'image' && <Image src={filePath} alt={`Aperçu de ${fileName}`}/>}

            {fileType === 'pdf' && (
                <iframe
                    src={filePath}
                    title={`Aperçu de ${fileName}`}
                    style={{width: '100%', height: '60vh', border: 'none'}}
                />
            )}

            {fileType === 'text' && (
                <div style={{whiteSpace: 'pre-wrap', padding: '10px', maxHeight: '400px', overflowY: 'auto'}}>
                    {loading && <Spin tip="Chargement du fichier texte..."/>}
                    {error && <Alert message={error} type="error"/>}
                    {!loading && !error && textContent}
                </div>
            )}

            {fileType === 'video' && (
                <video controls style={{width: '100%', maxHeight: '60vh'}}>
                    <source src={filePath} type="video/mp4"/>
                    Votre navigateur ne supporte pas la lecture de vidéo.
                </video>
            )}

            {fileType === 'audio' && (
                <audio controls style={{width: '100%'}}>
                    <source src={filePath} type="audio/mpeg"/>
                    Votre navigateur ne supporte pas la lecture audio.
                </audio>
            )}

            {fileType === 'other' && (
                <>
                    <Alert message="Aperçu non disponible pour ce type de fichier" type="warning" style={{fontSize: '1.2em'}}/>
                    <div
                        onClick={handleDownload}
                        style={
                            canDownload ? {
                                height: '20vh',
                                width: '20vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '10px',
                                margin: '10px 0 0 0',
                                cursor: 'pointer',
                            } : {
                                display: 'none'
                            }
                        }>

                        <DownloadOutlined style={{fontSize: '7vh', margin: 'auto 0'}}/>
                        <p style={{fontSize: '1.2em', margin: '10px 0 auto 0', textAlign: 'center'}}>Cliquez pour télécharger</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default FilePreview;
